import { makeStyles } from '@material-ui/core/styles';
import { Container, CssBaseline, Paper } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { IFilteringViewModel, ITableData } from '../_common/interface';
import { PaperHeader } from '../_components/paperComponents/header.paperComponent';
import { CommonTable } from '../_components/table/commonTable';
import { toastr } from '../_components/toastr/toastr';
import { getTableOptions } from '../_helpers';
import { getStringDateNormalized } from '../_helpers/date.helper';
import { buildingUpdateService } from '../_services/buildingUpdate.service';
import { TOOLBAR_STORE } from '../_stores/toolbar.store';
import { BuildingUpdateManageDrawer } from './components/buildingUpdate-manageDrawer';

const useStyles = makeStyles(theme => ({
    pageHeader: {
        borderBottom: "1px solid #eee"
    }
}));

export const BuildingUpdateIndex = observer(() => {

    const [data, setData] = useState<ITableData>({ items: [], count: 0 });
    const [isLoading, setLoading] = useState(false);
    const [filter, setFilter] = useState<IFilteringViewModel>({ search: '', limit: 100, page: 0, order: 'desc', orderBy: '' });
    const [selectedBuildingUpdateId, setSelectedBuildingUpdateId] = useState(0);

    const classes = useStyles();

    useEffect(() => {
        fetchData();
        TOOLBAR_STORE.setShowClientDropdown(true);

        return () => {
            TOOLBAR_STORE.setShowClientDropdown(false);
        };
    }, [filter, TOOLBAR_STORE.clientId]);

    function fetchData() {
        setLoading(true);
        return buildingUpdateService
            .getAll(filter, TOOLBAR_STORE.clientId)
            .then(
                (json) => {
                    setData(json);
                },
                (e) => {
                    toastr.error("Error getting building update data");
                }
            )
            .finally(() => setLoading(false));
    }

    function edit(event: any, rowData: any) {
        setSelectedBuildingUpdateId(rowData.id);
    }

    function closeModal() {
        setSelectedBuildingUpdateId(0);
        fetchData();
    }

    const columns = [
        { title: '#', field: 'id' },
        { title: 'Client Name', field: 'clientName' },
        { title: 'Building Name', field: 'name' },
        { title: 'Created By', field: 'createdByName' },
        { title: 'Created Date', field: 'CreatedDate', render: (data: any) => <>{getStringDateNormalized(data.createdDate)}</> },
    ];

    return (
        <>
            <Container component="main">
                <Paper>
                    <CssBaseline />

                    <div className={classes.pageHeader}>
                        <PaperHeader title='Building Updates Awaiting Approval' icon='maps_home_work'></PaperHeader>
                    </div>

                    <CommonTable
                        title={null}
                        columns={columns}
                        data={data}
                        isLoading={isLoading}
                        setFilter={setFilter}
                        onRowClick={edit}
                        options={getTableOptions(filter.limit)}
                    />

                </Paper>
            </Container>

            <BuildingUpdateManageDrawer
                buildingUpdateId={selectedBuildingUpdateId}
                isOpen={selectedBuildingUpdateId > 0}
                emitOnClose={closeModal} />
        </>
    );
})