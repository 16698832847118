import { Grid, Icon } from '@material-ui/core';
import { useEffect, useState } from "react";
import { ValidatorForm } from 'react-material-ui-form-validator';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import { PrimaryButton } from '../../_components/buttons/primary.button';
import { toastr } from '../../_components/toastr/toastr';
import { escalationService } from '../../_services/escalation.service';

export function EscalationPolicyEdit(params: { 
    policyId: number, 
    buildingId: number, 
    policy: string, 
    isActive: boolean,
    isUpdateApprovalRequired: boolean,
    emitOnSave: () => void, 
    emitOnClose: () => void }) {

    const [policy, setPolicy] = useState('');
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        setPolicy(params.policy);
    }, [params.policyId]);

    function handleChange(newPolicy: string) {
        setPolicy(newPolicy);
    }

    function onError() {

    }

    function submit(e: any) {
        e.preventDefault();
       
        let _data =({
            clientBuildingId: params.buildingId,
            escalationPolicy: policy,
            id: params.policyId,
            isActive: true,
            isUpdateApprovalRequired: params.isUpdateApprovalRequired
        });
        return escalationService
            .saveEscalationPolicy(_data)
            .then(
                (json: any) => {
                    toastr.success("Saved successfully");
                    params.emitOnSave();
                    // fetchData();
                },
                (e) => {
                    toastr.error("Error saving escalation data");
                }
            )
            .finally(() => setLoading(false));
    }

    function disable() {
        return escalationService
            .disableEscalationPolicy(params.policyId)
            .then(
                (json: any) => {
                    //fetchData();
                    params.emitOnSave();
                },
                (e) => {
                    toastr.error("Error disabling escalation policy");
                }
            )
            .finally(() => setLoading(false));
    }


    return (
        <>
            <ValidatorForm onError={onError} onSubmit={submit} noValidate>
                <Grid container>

                    <SunEditor
                        height="250px"
                        setContents={policy}
                        onChange={handleChange}
                        placeholder="Please Enter Escalation Policy For Building Here..."
                    />

                <Grid item xs={params.isActive === false || params.policyId == 0 ? 12 : 6}>
                    <PrimaryButton
                        onClick={submit}
                        variant="primary"
                        disabled={isLoading}
                    >
                        <Icon>check</Icon> {params.isActive === false ? <>Save and Reactivate</> : <>Save</>}
                    </PrimaryButton>
                </Grid>
                {(params.policyId > 0 && params.isActive === true) &&
                    <Grid item xs={6}>
                        <PrimaryButton
                            type="button"
                            variant="danger"
                            disabled={isLoading}
                            onClick={disable}
                        >
                            <Icon>remove</Icon> Disable
                        </PrimaryButton>
                    </Grid>
                }
                </Grid>
            </ValidatorForm>
        </>
    );
}