import { makeStyles } from '@material-ui/core/styles';
import { BottomNavigation, BottomNavigationAction, Box, CssBaseline, Hidden, Icon, Menu, MenuItem } from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Switch } from 'react-router-dom';
import { AccountUpdateIndex } from '../accountUpdate/accountUpdate-index';
import { BuildingIndex } from '../building/building-index';
import { BuildingUpdateIndex } from '../buildingUpdate/buildingUpdate-index';
import { ClientIndex } from '../client/client-index';
import { ClientUpdateIndex } from '../clientUpdate/clientUpdate-index';
import { DashboardIndex } from '../dashboard/dashboard-index';
import { EscalationPolicyUpdateIndex } from '../escalationPolicyUpdate/escalationPolicyUpdate-index';
import { EscalationRuleUpdateIndex } from '../escalationRuleUpdate/escalationRuleUpdate-index';
import { ClientUpdateNotification } from '../notification/clientUpdateNotification';
import { ZoneUpdateIndex } from '../zoneUpdate/zoneUpdate-index';
import { ADMIN_NAV, USER_NAV } from '../_common/const';
import { RoleEnum } from '../_common/enum';
import { AuthRoute } from '../_components/routing/auth.routing';
import AppSidebar from '../_components/sidebar/app.sidebar';
import { AppToolbar } from '../_components/toolbar/app.toolbar';
import { historyHelper } from '../_helpers';
import { accountService } from '../_services';
import { TOOLBAR_STORE } from '../_stores/toolbar.store';
import { AccountIndex } from './account/account-index';
import { HomeIndex } from './home/home-index';
import { ReportIndex } from './report';

const drawerWidth = 240;
const useStyles = makeStyles(theme => ({

    appScreen: {
        display: 'flex',
        height: "100%",
        backgroundColor: "#eee"
    },
    main: {
        marginTop: "40px"
    },
    root: {

    },
    appLogo: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    mainBackground: {},
    stickToBottom: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        borderTop: '1px solid #eee'
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: "#eee",
        paddingTop: theme.spacing(5),
        width: 'calc(100% - 650px)',
        maxWidth: "calc(100%)",
        [theme.breakpoints.up('md')]: {
        },
        [theme.breakpoints.down('md')]: {
            paddingTop: theme.spacing(6),
        },
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0
    }
}));


export function AppScreen() {

    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [isOpen, setIsOpen] = useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (e: any, nav: any) => {
        setAnchorEl(null);
        if (nav && nav.url)
            goTo(nav.url);
    };

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    }


    function goTo(link: string) {
        historyHelper.push('/' + link);
    }

    if (accountService.isInRole(RoleEnum.Client))
        TOOLBAR_STORE.setClientId(accountService.getClientId());

    const columns = [
        { title: 'Email', field: 'email' },
        { title: 'Name', field: 'name' },
        { title: 'Created Date', field: 'createdDate' },
        { title: 'Status', field: 'isActive', render: (rowData: any) => <>{rowData.isActive ? "Active" : "Inactive"}</> },
        { title: 'Update Requested', field: 'isUpdateApprovalRequired', render: (rowData: any) => <>{rowData.isUpdateApprovalRequired ? "Yes" : "No"}</> },
    ];

    return (
        <div className={classes.appScreen} id="app-screen">
            <CssBaseline />

            <AppToolbar toggleSidebar={toggleSidebar} isOpen={isOpen}></AppToolbar>

            <Box display={{ xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' }}>
                <AppSidebar isOpen={isOpen}></AppSidebar>
            </Box>
            <ClientUpdateNotification />
            <main className={clsx(classes.content, {
                [classes.contentShift]: isOpen,
            })}>
                <div className={classes.toolbar} />
                {/* 
                <MaterialTable
                    columns={columns}
                    data={[]}
                    title={null}
                    page={0}

                />

                <CommonTable2
                    columns={columns}
                    data={null}
                    title={null}
                    isLoading={false}

                /> */}

                <Switch>
                    <AuthRoute path="/Accounts" component={AccountIndex} />
                    <AuthRoute path="/Clients" component={ClientIndex} />
                    <AuthRoute path="/Buildings" component={BuildingIndex} />
                    <AuthRoute path="/Reports" component={ReportIndex} />
                    <AuthRoute path="/Dashboard" component={DashboardIndex} />

                    <AuthRoute path="/ClientUpdates" component={ClientUpdateIndex} />
                    <AuthRoute path="/BuildingUpdates" component={BuildingUpdateIndex} />
                    <AuthRoute path="/ZoneUpdates" component={ZoneUpdateIndex} />
                    <AuthRoute path="/EscalationPolicyUpdates" component={EscalationPolicyUpdateIndex} />
                    <AuthRoute path="/EscalationRuleUpdates" component={EscalationRuleUpdateIndex} />
                    <AuthRoute path="/AccountUpdates" component={AccountUpdateIndex} />

                    {accountService.isAdmin && <AuthRoute component={DashboardIndex} />}
                    {accountService.isClient && <AuthRoute component={ClientIndex} />}
                    {accountService.isEngineer && <AuthRoute component={BuildingIndex} />}
                    {(!accountService.isClient
                        && !accountService.isAdmin
                        && !accountService.isEngineer) && <AuthRoute component={HomeIndex} />}
                </Switch>

            </main>

            <Hidden mdUp>
                <BottomNavigation
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                    showLabels={true}
                    className={classes.stickToBottom}
                >

                    {/* {PRIMARY_NAV && PRIMARY_NAV.map(nav =>
                            <BottomNavigationAction key={"bottomNav_" + nav.name} onClick={() => goTo(nav.url)} label={nav.mobileName || nav.name} icon={
                                <>
                                    {nav.png == null &&
                                        <Icon>{nav.icon}</Icon>
                                    }
                                </>
                            } />
                        )} */}

                    {accountService.isAdmin &&
                        <BottomNavigationAction label={
                            'Admin'} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} icon={
                                <Icon>apps</Icon>
                            } />
                    }
                    {accountService.isAdmin &&
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >

                            {ADMIN_NAV && ADMIN_NAV.map(nav =>
                                <MenuItem key={"sideNav_" + nav.name} onClick={(e: any) => handleClose(e, nav)}>{nav.name}</MenuItem>
                            )}

                        </Menu>
                    }

                    {USER_NAV && USER_NAV.map((nav: any) =>
                        <BottomNavigationAction key={"bottomNav_" + nav.name} onClick={nav.onClick} label={nav.mobileName || nav.name} icon={
                            <>
                                {nav.png == null &&
                                    <Icon>{nav.icon}</Icon>
                                }
                            </>
                        } />
                    )}

                </BottomNavigation>
            </Hidden>

        </div >
    );
}