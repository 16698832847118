import { CircularProgress } from '@material-ui/core';

import * as React from 'react';
import { useEffect, useState } from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import { IDropdownData } from '../../_common/interface/iDropdownData.interface';
import { IStandardAutocomplete } from '../../_common/interface/iStandardAutocomplete.interface';
import { Autocomplete } from '@material-ui/lab';

interface IStandardExtAutocomplete extends IStandardAutocomplete {
    itemsLoader: (parentId?: number) => Promise<any>,
    label: string
}

export const StandardAutoComplete = (params: IStandardExtAutocomplete) => {

    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const [isLoading, setLoading] = useState(false);
    const [items, setItems] = useState<Array<IDropdownData>>([]);
    const [excludedIds, setExcludedIds] = useState<Array<number | string>>(params.excludedIds || []);

    useEffect(() => {
        if (params.excludedIds != excludedIds) {
            setExcludedIds(params.excludedIds);

        }
    }, [params.excludedIds]);

    useEffect(() => {
        if (params.hasParent === true) {
            if (params.parentId > 0) {
                fetchItems(params.parentId);
            }
            else if (items.length > 0) {
                setItems([]);
                changeInput(null);
            }
        }
        else {
            fetchItems();
        }
    }, [params.parentId]);

    useEffect(() => {
        if (items && items.length > 0) {
            changeInput(items.find((_item: any) => _item.id === params.itemId));
        }
        else if (((!items || items.length === 0) && value) || ((!params.itemId || params.itemId === 0) && value)) {
            changeInput(null);
        }

    }, [items, params.itemId]);

    function fetchItems(parentId?: number) {
        setLoading(true);
        return params.itemsLoader(parentId)
            .then(
                (json) => {
                    if (!json || json.length < 1) {
                        setItems([]);
                    }
                    else {
                        setItems(json);
                    }
                },
                (err) => {
                    console.error("asd", err);
                    setItems([]);
                }
            )
            .finally(() => setLoading(false));
    }

    function changeInput(newValue: IDropdownData) {
        newValue = (newValue === undefined) ? null : newValue;
        if (newValue === value) return;
        setValue(newValue);
        params.onItemChange(newValue, params.name);
    }

    return (

        params.isHiddenIfEmpty && (!items || items.length == 0) ?
            null :
            <Autocomplete
                getOptionLabel={(option) => option.name}
                value={value}
                onChange={(event: any, newValue: any) => {
                    changeInput(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                options={excludedIds && excludedIds.length ? items.filter(m => m.id == value || !excludedIds.some(r => r == m.id.toString())) : items}
                loading={isLoading}
                disabled={params.disabled || (params.isDisabledIfEmpty && (!items || items.length == 0))}
                renderInput={(_params) => (
                    <TextValidator
                        value={inputValue}
                        {..._params}
                        label={`${params.label} ${params.isRequired === true ? "*" : ""}`}
                        autoComplete="off"
                        name={`${params.name}`}
                        validators={params.isRequired === true ? ['required'] : []}
                        errorMessages={params.isRequired === true ? ['This field is required'] : []}
                        variant="filled"
                        InputProps={{
                            ..._params.InputProps,
                            endAdornment: (
                                <div>
                                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {_params.InputProps.endAdornment}
                                </div>
                            ),
                        }}
                    />)}
            />
    );
}