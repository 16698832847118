import { Checkbox, Divider, Drawer, FormControlLabel, Grid, Icon, Radio, RadioGroup } from '@material-ui/core';
import { useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { API_URL } from '../../../_common/const';
import { IDropdownData } from '../../../_common/interface';
import { BuildingAutoComplete, ClientEmployeeAutoComplete, ZoneAutoComplete } from '../../../_components/autoComplete';
import { PrimaryButton } from '../../../_components/buttons/primary.button';
import { SectionHeadingDrawer } from '../../../_components/drawer/sectionHeading.drawer';
import { toastr } from '../../../_components/toastr/toastr';
import { tokenService } from '../../../_services';
import { useDrawerStyles } from '../../../_styles';
import { useEditFormStyles } from '../../../_styles/editForm-styles';

export function ReportManageDrawer(params: { clientId: number, data?: any, emitOnSave: () => void, emitOnClose: () => void }) {

    const [isSaving, setSaving] = useState<any>(false);
    const [isLoading, setLoading] = useState(false);
    const classes = useDrawerStyles();
    const [data, setData] = useState<any>();
    const [clientId, setClientId] = useState(params.clientId);
    const [buildingId, setBuildingId] = useState(0);
    const [zoneId, setZoneId] = useState(0);
    const [employeeId, setEmployeeId] = useState(0);
    const [employeeName, setEmployeeName] = useState('');
    const [selectedReport, setSelectedReport] = useState(0);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [exportAll, setExportAll] = useState(false);
    const [exportToExcel, setExportToExcel] = useState(false);
    const [showInRadius, setShowInRadius] = useState(false);
    const [showOutOfRadius, setShowOutOfRadius] = useState(false);
    
    const editFormClasses = useEditFormStyles();

    function generate() {

        setLoading(true);

        tokenService.GetReportToken()
            .then((data) => {
                if (selectedReport === 1)
                    return window.open(API_URL + "/reports/EmployeeLoginsReport/?token=" + data + "&clientId=" + clientId + "&employeeId=" + employeeId
                        + "&buildingId=" + buildingId + "&zoneId=" + zoneId + "&startDate=" + startDate + "&endDate=" + endDate
                        + "&exportAll=" + exportAll + "&exportToExcel=" + exportToExcel, "_blank");
                else if(selectedReport === 2)
                    return window.open(API_URL + "/reports/CallOutsForPanicPolicyReport/?token=" + data + "&clientId=" + clientId + "&startDate=" + startDate + "&endDate=" + endDate + "&exportToExcel=" + exportToExcel, "_blank");
                else 
                    return window.open(API_URL + "/reports/EmployeeLocationReport/?token=" + data 
                                    + "&employeeId=" + employeeId 
                                    + "&employeeName=" + employeeName 
                                    + "&startDate=" + startDate 
                                    + "&endDate=" + endDate 
                                    + "&showOutOfRadius=" + showOutOfRadius
                                    + "&showInRadius=" + showInRadius, "_blank");
            })
            .finally(() => setLoading(false));
    }

    function handleBuildingDropdownChange(item: IDropdownData, name: string) {
        setBuildingId(item.id);
    }

    function handleZoneDropdownChange(item: IDropdownData, name: string) {
        setZoneId(item.id);
    }

    function handleEmployeeDropdownChange(item: IDropdownData, name: string) {
        setEmployeeId(item.id);
        setEmployeeName(item.name);
    }

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        var _selected = parseInt((event.target as HTMLInputElement).value);
        setSelectedReport(_selected);
    };

    function handleStartDateChange(event: any) {
        setStartDate(event.target.value);
    };

    function handleEndDateChange(event: any) {
        setEndDate(event.target.value);
    };

    function closeModal() {
        params.emitOnClose();
    };

    function onError(event: any) {
        toastr.error("Please verify that all fields are correct");
    }

    return (
        <>
            <Drawer anchor={"right"} open={true} className={classes.root}>
                <SectionHeadingDrawer heading={"Report"} emitOnClose={closeModal} />



                <ValidatorForm onSubmit={generate} onError={onError} noValidate>
                    <Grid container style={{ padding: "5px" }}>

                        <Grid item xs={12}>
                            <RadioGroup
                                name="selectedReport"
                                value={selectedReport}
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel value="1" control={<Radio />} label="Employee Log in's Report" />
                                <FormControlLabel value="2" control={<Radio />} label="Call outs for panic policy Report" />
                                <FormControlLabel value="3" control={<Radio />} label="Employee Location Report" />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid container>
                        {selectedReport === 1 &&
                            < Grid item xs={12}>
                                <BuildingAutoComplete
                                    isRequired={true}
                                    hasParent={true}
                                    parentId={clientId}
                                    itemId={buildingId}
                                    name={"buildingId"}
                                    onItemChange={handleBuildingDropdownChange}
                                />
                            </Grid>
                        }
                        {(selectedReport === 1 && exportAll === false) && 
                            <Grid item xs={12}>
                                <ZoneAutoComplete
                                    isRequired={true}
                                    hasParent={true}
                                    parentId={buildingId}
                                    itemId={zoneId}
                                    name={"zoneId"}
                                    onItemChange={handleZoneDropdownChange}
                                />
                            </Grid>
                        }
                        {((selectedReport === 1 && exportAll === false) || selectedReport === 3) && <>
                            <Grid item xs={12}>
                                <ClientEmployeeAutoComplete
                                    isRequired={true}
                                    hasParent={true}
                                    parentId={clientId}
                                    itemId={employeeId}
                                    name={"buildingId"}
                                    onItemChange={handleEmployeeDropdownChange}
                                />
                            </Grid>
                        </>
                        }

                        {selectedReport !== 0 &&
                            <>
                                <Grid item xs={12} className={editFormClasses.paddingLeft}>
                                    {selectedReport === 1 &&
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={exportAll} onChange={() => setExportAll(!exportAll)} />
                                            }
                                            label="Export All Zones And Employees"
                                        />
                                    }
                                    {selectedReport === 3 && <>
                                        <FormControlLabel
                                        control={
                                            <Checkbox checked={showInRadius} onChange={() => setShowInRadius(!showInRadius)} />
                                        }
                                        label="Show Inside Building Radius"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={showOutOfRadius} onChange={() => setShowOutOfRadius(!showOutOfRadius)} />
                                            }
                                            label="Show Outside Building Radius"
                                        />
                                    </>
                                    }
                                    {selectedReport !== 3 &&
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={exportToExcel} onChange={() => setExportToExcel(!exportToExcel)} />
                                            }
                                            label="Export To Excel"
                                        />
                                    }
                                </Grid>

                                <Grid item xs={6}>
                                    <TextValidator
                                        fullWidth
                                        label="Starts On"
                                        type="date"
                                        name="startDate"
                                        required
                                        value={startDate}
                                        onChange={handleStartDateChange}
                                        variant="filled"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                    />
                                </Grid>

                                <Grid item xs={6} className={editFormClasses.paddingLeft}>
                                    <TextValidator
                                        fullWidth
                                        label="Ends On"
                                        type="date"
                                        name="endDate"
                                        required
                                        value={endDate}
                                        onChange={handleEndDateChange}
                                        variant="filled"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <PrimaryButton
                                        type="submit"
                                        variant="primary"
                                        disabled={isSaving || (selectedReport === 3 && showInRadius === false && showOutOfRadius === false)}>
                                        <Icon>print</Icon> Generate
                                    </PrimaryButton>
                                </Grid>
                            </>
                        }
                    </Grid>
                </ValidatorForm>
            </Drawer>
        </>
    );
}