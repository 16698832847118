import { Grid, Icon } from "@material-ui/core";
import { useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator"
import SunEditor from "suneditor-react";
import { IEscalationPolicyUpdateManage } from "../../_common/models/escalationPolicyUpdate/escalationPolicyUpdate.model";
import { PrimaryButton } from "../../_components/buttons/primary.button";
import { SectionHeadingDrawer } from "../../_components/drawer/sectionHeading.drawer";
import { toastr } from "../../_components/toastr/toastr";
import { escalationPolicyUpdateService } from "../../_services/escalationPolicyUpdate.service";
import { useEditFormStyles } from "../../_styles/editForm-styles";

export function EscalationPolicyUpdateEdit(params: {
    escalationPolicyUpdateId: number,
    emitOnSave: () => void,
    emitOnClose: () => void
}) {
    const editFormClasses = useEditFormStyles();

    const [data, setData] = useState<IEscalationPolicyUpdateManage>(getResetData);
    const [isLoading, setLoading] = useState(false);
    
    useEffect(() => {
        fetchData();
    }, [params.escalationPolicyUpdateId]);

    function getResetData(): IEscalationPolicyUpdateManage {
        return {
            id: params.escalationPolicyUpdateId,
            escalationPolicyId: 0,
            buildingName: '',
            clientName: '',
            escalationPolicy: '',
            previousEscalationPolicy: '',
            isApproved: false
        };
    }
    
    function fetchData() {
        setLoading(true);
        return escalationPolicyUpdateService
            .get(params.escalationPolicyUpdateId)
            .then(
                (json: any) => {
                    setData(json)
                },
                (e) => {
                    toastr.error("Error getting escalation policy update data");
                }
            )
            .finally(() => setLoading(false));
    }

    function onError(event: any) {
        toastr.error("Please verify that all fields have correct input");
    }
    
    function approve(e: any) {
        e.preventDefault();
        setLoading(true);

        return escalationPolicyUpdateService
            .approve(data)
            .then(
                () => {
                    toastr.success("Approved successfully");
                    params.emitOnClose();
                },
                (e) => {
                    toastr.error("Error saving escalation policy update details");
                }
            )
            .finally(() => setLoading(false));
    }

return(
    <ValidatorForm onError={onError} onSubmit={approve} noValidate>
    <Grid container>
        <Grid item xs={12} className={[editFormClasses.paddingRight, editFormClasses.boldText].join(' ')}>Previous Value</Grid>
        <Grid item xs={12}>
            <SunEditor
                height="250px"
                setContents={data.previousEscalationPolicy}
                disable
                placeholder="Previous Escalation Policy For Building Here..."
            />

        </Grid>

        <Grid item xs={12} className={[editFormClasses.paddingRight, editFormClasses.boldText].join(' ')}>Updated Value</Grid>
        <Grid item xs={12}>
            <SunEditor
                height="250px"
                setContents={data.escalationPolicy}
                disable
                placeholder="Updated Escalation Policy For Building Here..."
            />

        </Grid>

        <Grid item xs={6}>
            <PrimaryButton
                type="submit"
                variant="primary"
                disabled={isLoading}
            >
                <Icon>check</Icon> <>Approve Changes</>
            </PrimaryButton>
        </Grid>
        <Grid item xs={6}>
            <PrimaryButton
                type="button"
                variant="danger"
                disabled={isLoading}
                onClick={params.emitOnClose}
            >
                <Icon>remove</Icon> Cancel
            </PrimaryButton>
        </Grid>
    </Grid>
</ValidatorForm>
    )
}