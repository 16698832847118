import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper } from '@material-ui/core';
import { useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { DANGER_COLOR, WARNING_COLOR } from "../../_common/const";
import { UserLoginStatusEnum } from '../../_common/enum/userLoginStatus.enum';
import { IDropdownData, IFilteringViewModel, ITableData } from "../../_common/interface";
import { BuildingAutoComplete } from "../../_components/autocompletes/building.autocomplete";
import { ClientAutoComplete } from "../../_components/autocompletes/client.autocomplete";
import { ZoneAutoComplete } from "../../_components/autocompletes/zone.autocomplete";
import { CommonTable } from "../../_components/table/commonTable";
import { toastr } from "../../_components/toastr/toastr";
import { getTableOptions } from '../../_helpers';
import { formatedTimeStringFromSeconds, getCurrentTime } from '../../_helpers/time.helper';
import { dashboardService } from "../../_services/dashboard.service";
import { escalationAlertService } from '../../_services/escalationAlert.service';
import { userLoginService } from '../../_services/userLogin.service';



const useStyles = makeStyles(theme => ({
    pageHeader: {
        borderBottom: "1px solid #eee"
    },
    tabBorderBottom: {
        borderBottom: 1,
        borderColor: 'divider'
    },
    escalationPadding: {
        marginLeft: "5px",
        marginTop: "-35px"
    },
    redFlashingText: {
        color: DANGER_COLOR[0],
        animation: 'flashing 750ms linear infinite'
    }
}));


export function UsersDashboardList() {

    const [data, setData] = useState<ITableData>({ items: [], count: 0 });
    const [isLoading, setLoading] = useState(false);
    const [filter, setFilter] = useState<IFilteringViewModel>({ search: '', limit: 20, page: 0, order: 'desc', orderBy: '', clientId: null, buildingId: null, zoneId: null });
    const [refreshError, setRefreshError] = useState(false);

    const classes = useStyles();

    useEffect(() => {
        fetchDashBoardData();

        let timer = setInterval(() => { fetchDashBoardData() }, 30000)
        return () => clearInterval(timer);
    }, [filter]);

    function fetchDashBoardData() {
        setLoading(true);
        return dashboardService
            .getAll(filter)
            .then(
                (json) => {
                    console.log('json: ', json);
                    setData(json);
                },
                (e) => {
                    toastr.error("Error refreshing data for dashboard data");
                    setRefreshError(true);
                }
            )
            .finally(() => {
                setLoading(false);
            });
    }

    function checkForUserTimeout() {
        setLoading(true);
        return escalationAlertService
            .checkForUserTimeout()
            .then(
                (json) => {
                },
                (e) => {
                    toastr.error("Error refreshing data for dashboard data");
                    setRefreshError(true);
                }
            )
            .finally(() => {
                setLoading(false);
            });
    }

    function handleDropdownChange(item: IDropdownData, name: string) {

        setFilter(_data => {
            _data[name] = item?.id || null;

            return { ..._data }
        });
    };

    function forceLogoutUser(e: any, rowData: any) {
        console.log('logging out user: ', rowData.clientUserId)
        return userLoginService
            .ForceLogoutUser(rowData.clientUserId)
            .then(
                (json: any) => {
                    switch (json) {
                        case UserLoginStatusEnum.LoggedOut: toastr.success("User has been logged out"); break;
                        case UserLoginStatusEnum.NotLoggedIn: toastr.success("User has already logged out"); break;
                        default: toastr.error("User has not been logged out"); break;
                    }
                },
                (e: any) => { console.log(e); toastr.error("Error logging user out"); }
            )
            .finally(() => {
                fetchDashBoardData();
                checkForUserTimeout();
            })
    }

    const columns = [
        { title: 'User', field: 'clientUserName' },
        { title: 'Client', field: 'clientName' },
        { title: 'Building', field: 'buildingName' },
        { title: 'Zone', field: 'zoneName' },
        { title: 'Is In Radius', field: 'isUserOutsideZoneRadius', render: (rowData: any) => <>{rowData.isUserOutsideZoneRadius ? "No" : "Yes"}</> },
        { title: 'Last Update', field: 'timeUntilResponseRequiredSecondsStr', render: (data: any) => <>{formatedTimeStringFromSeconds(getCurrentTime() - getCurrentTime(data.lastUpdate))}</> },
    ];

    return (

        <Paper className={classes.escalationPadding}>

            <ValidatorForm onSubmit={() => { }} noValidate>

                <Grid container>
                    <Grid item xs={12} md={4}>
                        <ClientAutoComplete
                            itemId={filter.clientId}
                            onItemChange={handleDropdownChange}
                            name={"clientId"} />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <BuildingAutoComplete
                            parentId={filter.clientId}
                            hasParent
                            disabled={!filter.clientId}
                            itemId={filter.buildingId}
                            onItemChange={handleDropdownChange}
                            name={"buildingId"} />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <ZoneAutoComplete
                            parentId={filter.buildingId}
                            hasParent
                            disabled={!filter.buildingId}
                            itemId={filter.zoneId}
                            onItemChange={handleDropdownChange}
                            name={"zoneId"} />
                    </Grid>
                </Grid>
            </ValidatorForm>

            <CommonTable
                title={`${data.count} Workers`}
                columns={columns}
                data={data}
                isLoading={isLoading}
                setFilter={setFilter}
                options={{
                    ...getTableOptions(filter.limit),
                    rowStyle: (rowData) => {
                        if (rowData.isUserOutsideZoneRadius === true) {
                            return {
                                backgroundColor: WARNING_COLOR[3],
                                borderBottom: '2px solid white',
                            };
                        }
                    },
                    minBodyHeight: "calc(100vh - 250px)",
                    actionsColumnIndex: -1
                }}
                actions={[
                    {
                        icon: 'refresh',
                        tooltip: 'Refresh',
                        isFreeAction: true,
                        onClick: () => {

                            fetchDashBoardData();
                            checkForUserTimeout();
                        }
                    },
                    {
                        icon: 'logout',
                        tooltip: 'Force Logout User',
                        isFreeAction: false,
                        onClick: forceLogoutUser
                    }
                ]} />
        </Paper>

    );
}