import { API_URL } from '../_common/const/config.const';
import { getHeader, getQuery } from '../_helpers';
import { handleFailResponse, handleResponse } from '../_helpers/handle-response';

const API_NAME = "user";

export const userService = {
    get,
    getAll,
};

function get(itemId: string) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/get/?userId=${itemId}`, requestOptions).then(handleResponse, handleFailResponse);
}

async function getAll(query?: any, clientId?: number) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return await fetch(`${API_URL}/${API_NAME}/getAll/?clientId=${clientId}&${getQuery(query)}`, requestOptions).then(handleResponse, handleFailResponse);
}
