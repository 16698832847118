import { API_URL } from '../_common/const/config.const';
import { getHeader } from '../_helpers';
import { handleResponse } from '../_helpers/handle-response';


const API_NAME = "token";

export const tokenService = {
    GetReportToken
};


function GetReportToken() {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/GetReportToken/`, requestOptions).then(handleResponse);
}

