import { makeStyles } from '@material-ui/core/styles';
import { Collapse, Divider, Drawer, Icon, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { useEffect, useState } from 'react';

import { ADMIN_NAV, CLIENT_NAV, ENGINEER_NAV, UPDATE_NAV, USER_NAV } from '../../_common/const';
import { RoleEnum } from '../../_common/enum';
import { historyHelper } from '../../_helpers';
import { accountService } from '../../_services/account.service';

const logoImg = require('../../_assets/images/logo.png');


const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },

    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    logo: {
        padding: "16px",
        color: theme.palette.primary.main
    },
    appLogo: {
        width: "calc(240px * 0.50)",
        marginTop: "0px",
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    nested: {
        paddingLeft: theme.spacing(4),
    }
}));

export default function AppSidebar(params: { isOpen: boolean }) {
    const classes = useStyles();

    const [isOpen, setIsOpen] = useState(params.isOpen);
    const [anchorEl, setAnchorEl] = useState(null);
    const [updateClick, setUpdateClick] = useState(false);
    const [openItem, setOpenItem] = useState<number>(0);

    function goTo(link: string) {
        historyHelper.push('/' + link);
    }

    useEffect(() => {
        setIsOpen(params.isOpen);
    }, [params.isOpen]);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (e: any, nav: any) => {
        setAnchorEl(null);
        if (nav && nav.url)
            goTo(nav.url);
    };

    return (
        <Drawer
            className={classes.drawer}
            variant="persistent"
            open={isOpen}
            id={"sidebarNavDrawer"}

            classes={{
                paper: classes.drawerPaper,
            }}
            anchor="left"
        >
            <div className={classes.toolbar} >
                <img className={classes.appLogo} id="sidebar_logo" src={logoImg} alt={"test"}></img>
            </div>

            <Divider />

            {/* {(PRIMARY_NAV.length > 0) &&
                <List>
                    {PRIMARY_NAV.map(nav =>
                        <ListItem button key={`sidebar_${nav.name}`} onClick={e => goTo(nav.url)}>
                            <ListItemIcon>
                                <Icon>home</Icon>
                            </ListItemIcon>
                            < ListItemText primary={nav.name} />
                        </ListItem>
                    )}
                    <Divider />
                </List>
            } */}

            {(ADMIN_NAV.length > 0 && accountService.isAdmin === true) &&
                <List>
                    {ADMIN_NAV.map(nav =>
                        <ListItem button key={nav.name} onClick={e => goTo(nav.url)}>
                            <ListItemIcon><Icon>{nav.icon}</Icon></ListItemIcon>
                            <ListItemText primary={nav.name} />
                        </ListItem>
                    )}

                    <Divider />
                </List>
            }

            {accountService.isAdmin === true &&
                <>
                    <List>
                        <ListItem onClick={(e: any) => setUpdateClick(!updateClick)}>
                            <ListItemIcon><Icon>manage_accounts</Icon></ListItemIcon>
                            <ListItemText primary={"Updates"} />
                            {!updateClick && <ListItemIcon><Icon>arrow_drop_down</Icon></ListItemIcon>}
                            {updateClick && <ListItemIcon><Icon>arrow_drop_up</Icon></ListItemIcon>}
                        </ListItem>
                        <Collapse in={updateClick} timeout="auto" unmountOnExit>
                            <List>
                                {UPDATE_NAV.map(nav =>
                                    <ListItem button key={nav.name} onClick={e => goTo(nav.url)}>
                                        <ListItemIcon><Icon>{nav.icon}</Icon></ListItemIcon>
                                        <ListItemText primary={nav.name} />
                                    </ListItem>
                                )}
                            </List>
                        </Collapse>
                    </List>
                    <Divider />
                </>}

            {(ENGINEER_NAV.length > 0 && accountService.isEngineer === true) &&
                <List>
                    {ENGINEER_NAV.map(nav =>
                        <ListItem button key={nav.name} onClick={e => goTo(nav.url)}>
                            <ListItemIcon><Icon>{nav.icon}</Icon></ListItemIcon>
                            <ListItemText primary={nav.name} />
                        </ListItem>
                    )}

                    <Divider />
                </List>
            }

            {(CLIENT_NAV.length > 0 && accountService.isInRole(RoleEnum.Client) === true) &&
                <List>
                    {CLIENT_NAV.map(nav =>
                        <ListItem button key={nav.name} onClick={e => goTo(nav.url)}>
                            <ListItemIcon><Icon>{nav.icon}</Icon></ListItemIcon>
                            <ListItemText primary={nav.name} />
                        </ListItem>
                    )}

                    <Divider />
                </List>
            }

            {(USER_NAV.length > 0) &&
                <List>
                    {USER_NAV.map(nav =>
                        <ListItem button key={nav.name} onClick={nav.onClick}>
                            <ListItemIcon><Icon>{nav.icon}</Icon></ListItemIcon>
                            <ListItemText primary={nav.name} />
                        </ListItem>
                    )}
                    <Divider />
                </List>
            }
        </Drawer>
    );
}