import { IStandardAutocomplete } from '../../_common/interface/iStandardAutocomplete.interface';
import { dropdownService } from '../../_services/dropdown.service';
import { StandardAutoComplete } from './standard.autocomplete';


interface IBuildingAutoComplete extends IStandardAutocomplete {

}

export const BuildingAutoComplete = (params: IBuildingAutoComplete) => {


    function fetchItems() {
        return dropdownService
            .getBuildings(params.parentId);
    }

    return (
        <StandardAutoComplete
            {...params}
            itemsLoader={fetchItems}
            label={"Select Building"} />
    );
}