import { TablePagination } from '@material-ui/core';
import MaterialTable, { Options } from 'material-table';
import { useEffect, useState } from 'react';
import { TableFilterEnum } from '../../_common/enum/tableFilter.enum';
import { IFilteringViewModel, ITableData } from '../../_common/interface';
import { getTableOptions, setTableFilter } from '../../_helpers/table-helper';
import { CommonTableHead } from './tableHead';

export function CommonTable(params: {
    title?: string,
    isLoading: boolean,
    columns: Array<any>,
    anyActions?: boolean,
    data: ITableData,
    actions?: any[],
    options?: Options<any>,
    onRowClick?: (e: any, data: any) => void,
    fetchData?: (obj: IFilteringViewModel) => void,
    setFilter?: (filter: IFilteringViewModel) => void
}) {

    const [filter, setFilter] = useState<IFilteringViewModel>({ search: '', limit: 20, page: 0, order: 'desc', orderBy: '' });

    useEffect(() => {
        if (params.fetchData)
            params.fetchData(filter);

        if (params.setFilter)
            params.setFilter(filter);
    }, [filter]);

    return (
        <MaterialTable
            columns={params.columns}
            data={params.data.items}
            title={params.title}
            page={filter.page}
            isLoading={params.isLoading}
            actions={params.actions}
            onRowClick={params.onRowClick}

            onChangeRowsPerPage={(pageSize: number) => {
                setTableFilter(filter, TableFilterEnum.limit, pageSize, setFilter)
            }}
            onSearchChange={(propValue) => {
                setTableFilter(filter, TableFilterEnum.search, propValue, setFilter)
            }}
            components={{
                Pagination: (componentProps: any) =>
                    <TablePagination
                        {...componentProps}
                        onChangePage={(e, page) => {
                            setTableFilter(filter, TableFilterEnum.page, page, setFilter)
                        }}
                        count={params.data.count}
                        page={filter.page}
                        rowsPerPage={filter.limit}
                    />,

                Header: (componentProps) => <CommonTableHead componentProps={componentProps} columns={params.columns} filter={filter} setFilter={setFilter} />,
            }}
            options={{
                ...getTableOptions(filter.limit), ...params.options
            }}
        />
    );
}