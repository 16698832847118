import { Drawer, Grid, MenuItem } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { useEffect, useState } from 'react';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { IDropdownData } from '../../_common/interface';
import { AccountManage } from '../../_common/models/account/accountManage.model';
import { PrimaryButton } from '../../_components/buttons/primary.button';
import { SectionHeadingDrawer } from '../../_components/drawer/sectionHeading.drawer';
import { toastr } from '../../_components/toastr/toastr';
import { historyHelper } from '../../_helpers';
import { accountService, userService } from '../../_services';
import { dropdownService } from '../../_services/dropdown.service';
import { useDrawerStyles } from '../../_styles/drawer.styles';
import React from 'react';
import { useParams } from 'react-router-dom';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { RESET_PASSWORD_VALIDATORS, RESET_PASSWORD_ERROR_MESSAGES, RESET_PASSWORD_HELPER_TEXT } from '../../_common/const';
import { ClientBuildingMultipleAutoComplete } from '../../_components/autoComplete/clientBuilding.multipleAutoComplete';

export function AccountCreate(params: { successCallback: () => any }) {

    const [isLoading, setLoading] = useState(false);
    const [account, updateAccount] = useState<Partial<AccountManage>>({
        name: "",
        password: "",
        confirmPassword: "",
        email: "",
        role: "",
        clientId: 0,
        buildingId: 0,
        roles: [],
        requestPriority: null,
        specialConditionNote: "",
        clientBuildingIds: []
    });

    const [passwordChange, setPasswordChange] = useState(false);
    const classes = useDrawerStyles();
    const [clients, setClients] = useState<Array<IDropdownData>>([]);
    const [buildings, setBuildings] = useState<Array<IDropdownData>>([]);
    const [employeeLimitReached, setEmployeeLimitReached] = useState(false);

    function navigateBack() {
        historyHelper.goBack();
    };

    useEffect(() => {
        fetchClients();
    }, []);

    function fetchClients() {
        dropdownService.getClients().then((json) => { setClients(json); });
    }


    function submit(e: any) {
        e.preventDefault();
        setLoading(true);

        var _account = { ...account };
        _account.roles = [];
        _account.roles.push(_account.role || "");

        accountService
            .post(_account)
            .then(
                (d) => { console.log(d); params.successCallback(); navigateBack(); },
                (e) => { console.log(e); setLoading(false); toastr.error("Something went wrong. Please try again later") }
            );
    }

    function handleChange(event: any) {
        var _account = account;
        _account[event.target.name] = event.target.value;

        updateAccount({ ..._account });
    };

    function closeModal() {
        historyHelper.goBack();
    }
    return (
        <Drawer anchor="right" open={true} onClose={navigateBack} variant="temporary" className={classes.root} disableEnforceFocus>

            <SectionHeadingDrawer heading={"Account"} emitOnClose={closeModal} />

            <ValidatorForm onSubmit={submit} noValidate>

                <Grid container>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <TextValidator
                                    autoComplete="off"
                                    variant="filled"
                                    margin="normal"
                                    fullWidth
                                    label="First Name"
                                    name="firstName"
                                    value={account.firstName}
                                    disabled={isLoading}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextValidator
                                    autoComplete="off"
                                    variant="filled"
                                    margin="normal"
                                    fullWidth
                                    label="Last Name"
                                    name="lastName"
                                    value={account.lastName}
                                    disabled={isLoading}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <TextValidator
                            variant="filled"
                            margin="normal"
                            autoComplete="off"
                            required
                            fullWidth
                            label="Initial Password"
                            name="password"
                            helperText="Password must be at least 6 chars, contain at least one uppercase and lowercase A-Z, at least one non alpha numeric and at least one number"
                            value={account.password}
                            disabled={isLoading}
                            onChange={handleChange}
                            type="password"
                            validators={['required', 'minStringLength:6', 'maxStringLength:100',]}
                            errorMessages={['This field is required', 'Password must be at least 6 characters long', 'Password must be no more than 100 characters long']}
                        />
                        <TextValidator
                            autoComplete="off"
                            variant="filled"
                            margin="normal"
                            required
                            fullWidth
                            label="Email Address"
                            name="email"
                            onChange={handleChange}
                            value={account?.email}
                        />

                        {accountService.isAdmin && <SelectValidator
                            id="demo-simple-select"
                            value={account?.role}
                            onChange={handleChange}
                            required
                            label="Select Role"
                            name="role"
                            fullWidth
                            margin="normal"
                            validators={['required']}
                            errorMessages={['This field is required']}
                            variant="filled">
                            <MenuItem value='Admin'>Admin</MenuItem>
                            <MenuItem value='Client'>Client</MenuItem>
                            <MenuItem value='Employee'>Employee</MenuItem>
                            <MenuItem value='Engineer'>Engineer</MenuItem>
                        </SelectValidator>
                        }

                        {((clients && clients.length > 0) && (accountService.isAdmin) && account.role !== 'Admin') &&
                            <SelectValidator
                                value={account?.clientId || ""}
                                onChange={handleChange}
                                required
                                margin="normal"
                                label="Select Client"
                                name="clientId"
                                fullWidth
                                validators={['required']}
                                errorMessages={['This field is required']}
                                variant="filled">
                                {(clients && clients.length) && clients.map((item) => <MenuItem value={item.id}>{item.name}</MenuItem>)}
                            </SelectValidator>
                        }

                        {(clients && clients.length && (clients.find(m => m.id == account.clientId) as any)?.numberOfLicenses < 0) &&
                            <Grid container><Grid item className={classes.redText}>
                                Cannot add employee to client as number of licensed employees will be exceeded
                            </Grid></Grid>
                        }

                        {account.role === 'Employee' &&
                            <>
                                {/* {(buildings && buildings.length > 0) && (accountService.isAdmin || accountService.isClient) &&
                                        <SelectValidator
                                            value={account?.buildingId || ""}
                                            onChange={handleChange}
                                            margin="normal"
                                            label="Select Building"
                                            name="buildingId"
                                            fullWidth
                                            variant="filled">
                                            {(buildings && buildings.length) && buildings.map((item) => <MenuItem value={item.id}>{item.name}</MenuItem>)}
                                        </SelectValidator>
                                    } */}

                                <ClientBuildingMultipleAutoComplete
                                    hasParent={true}
                                    parentId={account.clientId}
                                    name={"clientBuildingIds"}
                                    onItemChange={handleChange}
                                    itemIds={account.clientBuildingIds} />

                                <TextValidator
                                    autoComplete="off"
                                    variant="filled"
                                    margin="normal"
                                    fullWidth
                                    label="Department"
                                    name="department"
                                    value={account.department}
                                    disabled={isLoading}
                                    onChange={handleChange}
                                />
                                <TextValidator
                                    autoComplete="off"
                                    variant="filled"
                                    margin="normal"
                                    fullWidth
                                    label="Address"
                                    name="address"
                                    value={account.address}
                                    disabled={isLoading}
                                    onChange={handleChange}
                                />
                                <TextValidator
                                    autoComplete="off"
                                    variant="filled"
                                    margin="normal"
                                    fullWidth
                                    label="Eircode"
                                    name="eircode"
                                    value={account.eircode}
                                    disabled={isLoading}
                                    onChange={handleChange}
                                />
                                <TextValidator
                                    autoComplete="off"
                                    variant="filled"
                                    margin="normal"
                                    fullWidth
                                    label="Phone Number"
                                    name="phoneNumber"
                                    value={account.phoneNumber}
                                    disabled={isLoading}
                                    onChange={handleChange}
                                />
                                <TextValidator
                                    autoComplete="off"
                                    variant="filled"
                                    margin="normal"
                                    fullWidth
                                    label="Special Conditions Note"
                                    name="specialConditionNote"
                                    value={account.specialConditionNote}
                                    disabled={isLoading}
                                    onChange={handleChange}
                                /></>}
                    </Grid>
                    <Grid item xs={12}>
                        <PrimaryButton
                            type="submit"
                            variant="primary"
                            disabled={isLoading || employeeLimitReached}>
                            <Icon>check</Icon>  <>Save</>
                        </PrimaryButton>
                    </Grid>

                </Grid>
            </ValidatorForm>
        </Drawer >
    );
}