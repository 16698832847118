import axios from 'axios';
import { getHeader, getQuery, getUploadHeader } from '../_helpers';
import { handleResponse } from '../_helpers/handle-response';
import { API_URL } from '../_common/const';

const API_NAME = "client";

export const clientService = {
    get,
    getAll,
    post,
    put,
    disable,
    submit(model?: FormData) { return model?.get('id')?.valueOf()! > 0 ? put(model) : post(model) }
};


function get(id: number) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/get/?id=${id}`, requestOptions).then(handleResponse);
}
function getAll(query?: any, clientId?: number) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/getAll/?clientId=${clientId}&${getQuery(query)}`, requestOptions).then(handleResponse);
}

function post(model?: FormData) {
    const config = {
        headers: getUploadHeader()
    }

    return axios.post(`${API_URL}/${API_NAME}/`, model, config);
}

function put(model?: FormData) {
    const config = {
        headers: getUploadHeader()
    }

    return axios.put(`${API_URL}/${API_NAME}/`, model, config);
}

function disable(id: number) {
    const requestOptions = { method: 'DELETE', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/${id}`, requestOptions).then(handleResponse);
}