import { makeStyles } from '@material-ui/core/styles';
import { Container, CssBaseline, Paper } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { IFilteringViewModel, ITableData } from '../_common/interface';
import { PaperHeader } from '../_components/paperComponents/header.paperComponent';
import { CommonTable } from '../_components/table/commonTable';
import { toastr } from '../_components/toastr/toastr';
import { getStringDateNormalized, getTableOptions } from '../_helpers';
import { userUpdateService } from '../_services/userUpdate.service';
import { TOOLBAR_STORE } from '../_stores/toolbar.store';
import { AccountUpdateManageDrawer } from './components/accountUpdate-manageDrawer';


const useStyles = makeStyles(theme => ({
    pageHeader: {
        borderBottom: "1px solid #eee"
    }
}));

export const AccountUpdateIndex = observer(() => {

    const [data, setData] = useState<ITableData>({ items: [], count: 0 });
    const [selectedUserUpdateId, setSelectedUserUpdateId] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [filter, setFilter] = useState<IFilteringViewModel>({ search: '', limit: 100, page: 0, order: 'desc', orderBy: '' });
    const classes = useStyles();

    const columns = [
        { title: '#', field: 'id' },
        { title: 'Email', field: 'email' },
        { title: 'Name', field: 'name' },
        { title: 'Created By', field: 'createdByName' },
        { title: 'Created Date', field: 'createdDate', render: (data: any) => <>{getStringDateNormalized(data.createdDate)}</> }
    ];

    useEffect(() => {
        fetchData();
        TOOLBAR_STORE.setShowClientDropdown(true);

        return () => {
            TOOLBAR_STORE.setShowClientDropdown(false);
        };
    }, [filter, TOOLBAR_STORE.clientId]);

    function fetchData() {

        setLoading(true);
        return userUpdateService.getAll(filter, TOOLBAR_STORE.clientId)
            .then(data => {
                data.items = data.items || [];
                setData(data);
            },
                (e) => {
                    toastr.error("Error getting user data");
                })
            .finally(() => setLoading(false));
    }


    function edit(event: any, rowData: any) {
        setSelectedUserUpdateId(rowData.id);
    }

    function closeModal() {
        setSelectedUserUpdateId(0);
        fetchData();
    }

    return (
        <>

            <Container component="main">
                <Paper>
                    <CssBaseline />


                    <div className={classes.pageHeader}>
                        <PaperHeader title='User Updates Awaiting Approval' icon='account_box'></PaperHeader>
                    </div>

                    <CommonTable
                        title={null}
                        columns={columns}
                        data={data}
                        isLoading={isLoading}
                        setFilter={setFilter}
                        onRowClick={edit}
                        options={{...getTableOptions(filter.limit)}}
                    />


                </Paper>

            </Container>

            <AccountUpdateManageDrawer
                userUpdateId={selectedUserUpdateId}
                isOpen={selectedUserUpdateId > 0}
                emitOnClose={closeModal} />
        </>
    );
})