import { makeStyles } from '@material-ui/core/styles';

export const useEditFormStyles = makeStyles(theme => ({
    spacerBottom: {
       marginBottom: theme.spacing(.5)
    },
    alignRight: {
        marginLeft: 'auto'
    },
    redText: {
        color: "red",
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5)
    },
    zoneIndexTitle: {
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(2)
    },
    paddingLeft: {
        paddingLeft: "6px"
    },
    paddingRight: {
        paddingRight: "6px"
    },
    boldText: {
        fontWeight: "bold"
    }
}));