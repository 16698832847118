import { makeStyles } from '@material-ui/core/styles';
import { Backdrop, Box, CircularProgress, Collapse, Grid, Paper, Step, StepContent, StepLabel, Stepper, TextField, Typography } from "@material-ui/core";
import { useEffect, useMemo, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import { WARNING_COLOR } from '../_common/const';
import { IEscalationAlertManage } from '../_common/models/escalationAlert/escalationAlertManage.model';
import { IEscalationLogManage } from "../_common/models/escalationLog/escalationLogManage.model";
import { IEscalationRuleView } from '../_common/models/escalationRule/escalationRuleView.model';
import { PrimaryButton } from '../_components/buttons/primary.button';
import { toastr } from '../_components/toastr/toastr';
import { formatedTimeStringFromSeconds, getCurrentTime } from '../_helpers/time.helper';
import { escalationService } from "../_services/escalation.service";
import { escalationAlertService } from '../_services/escalationAlert.service';
import { escalationLogService } from "../_services/escalationLog.service";
import { Alert } from '@material-ui/lab';


export const useStyles = makeStyles(theme => ({
    paddingBottom: {
        paddingBottom: "10px"
    },
    borderBottom: {
        borderBottom: "2px solid lightgray",
        paddingBottom: "10px",
        width: "100%"
    },
    title: {
        backgroundColor: "#ff828d"
    },
    escalationBorder: {
        border: "2px solid #ed1c23",
        borderRadius: "5px"
    },
    escalationDetails: {
        padding: "8px!important"
    },
    escalationButtonTopPadding: {
        paddingTop: "8px!important"
    },
    buttonMarginRight: {
        marginRight: "8px!important"
    },
    noteMarginTop: {
        marginTop: "10px!Important"
    },
    paper: {
        width: "450px",
        float: "left",
        marginRight: "10px"
    },
    backgroundWarning: {
        backgroundColor: WARNING_COLOR[2]
    }
}));

const resetActiveStep: { step: number, notes: string, wasContactMade: boolean | null } = {
    step: 0,
    notes: "",
    wasContactMade: null
}


export function EscalationPanel(params: {
    alertId: number,
    buildingId: number,
    emitCompleted: (alertId: number) => void;
}) {
    const [isLoading, setLoading] = useState(false);

    const [activeEscalationStep, setActiveEscalationStep] = useState(resetActiveStep);

    const [escalationPolicy, setEscalationPolicy] = useState('');
    const [escalationRules, setEscalationRules] = useState<IEscalationRuleView[]>([]);
    const [escalationAlert, setEscalationAlert] = useState<IEscalationAlertManage>({
        id: 0,
        clientUserId: "",
        clientUserName: "",
        clientUserPhoneNumber: "",
        escalationAlertTime: null,
        clientBuildingZoneId: 0,
        clientBuildingId: 0,
        escalationStep: 0,
        repeatEscalationStep: false,
        zoneName: "",
        buildingName: "",
        clientName: "",
        lastKnownLatitude: '',
        lastKnownLongitude: '',
        isActive: true,
        escalationLogs: [],
        specialConditionNote: ''
    } as IEscalationAlertManage);

    const [isRepeatStepDialogOpen, setRepeatStepDialogOpen] = useState(false);
    const [hasUserResetTimer, setHasUserResetTimer] = useState(false);

    const [currentTime, setCurrentTime] = useState<number>(getCurrentTime);

    const classes = useStyles();

    const isCompleted = useMemo(() => {
        return escalationAlert?.escalationLogs.some(m => m.escalationContactSuccessful)
    }, [escalationAlert.escalationLogs])

    useEffect(() => {
        if (isCompleted) {
            params.emitCompleted(params.alertId);
        }
    }, [isCompleted]);

    useEffect(() => {
        fetchEscalationData();
        fetchEscalationBuildingRules();
    }, []);

    useEffect(() => {

        if (!escalationRules ||
            !escalationRules.length ||
            !escalationAlert ||
            !escalationAlert.escalationLogs ||
            !escalationAlert.escalationLogs.length) {
            return;
        }

        let _id = Math.max(...escalationAlert.escalationLogs.map(m => m.id));
        let _alertLog = escalationAlert.escalationLogs.find(m => m.id == _id);
        let _ruleIndex = (escalationRules.map(m => m.id).indexOf((_alertLog?.escalationRuleId || 0)) + 1);

        if (escalationRules.length < _ruleIndex) return;

        setActiveEscalationStep(__escalationStep => { return { ...resetActiveStep, step: _ruleIndex } });
    }, [escalationAlert?.escalationLogs, escalationRules]);

    useEffect(() => {
        // if (hasUserResetTimer) return;

        var _timeout = setInterval(() => { tick() }, 1000);
        return () => clearInterval(_timeout);
    }, [hasUserResetTimer]);

    useEffect(() => {
        fetchEscalationData();
        fetchEscalationBuildingRules();
    }, []);

    useEffect(() => {
        if (currentTime % 10 == 0) {
            getHasUserResetTimer();
        }
    }, [currentTime]);


    function tick() {
        setCurrentTime(getCurrentTime());
    }

    function getHasUserResetTimer() {
        setLoading(true);
        return escalationAlertService.hasUserResetTimer(params.alertId)
            .then(
                (json) => {
                    setHasUserResetTimer(json);
                },
                (e) => {
                    toastr.error("Error refreshing data for dashboard data");
                }
            )
            .finally(() => {
                setLoading(false);
            });
    }

    function fetchEscalationData() {
        setLoading(true);
        return escalationAlertService.get(params.alertId)
            .then(
                (json) => {
                    setEscalationAlert(json);
                },
                (e) => {
                    toastr.error("Error refreshing data for dashboard data");
                }
            )
            .finally(() => {
                setLoading(false);
            });
    }

    function fetchEscalationBuildingRules() {
        setLoading(true);

        return escalationService
            .getEscalationDetailsForBuilding(params.buildingId)
            .then(
                (json: any) => {
                    console.log(json);
                    setEscalationPolicy(json.escalationPolicy);
                    setEscalationRules(json.escalationRules);
                    return;
                },
                (e) => {
                    toastr.error("Error getting escalation data");
                }
            )
            .finally(() => {
                setLoading(false);
            });
    }



    function handleNoteChange(event: any) {
        setActiveEscalationStep(__escalationStep => { return { ...__escalationStep, notes: event.target.value } });
    }

    const handleWasContactMadeChange = (wasContactMade: boolean) => (event: any) => {
        setActiveEscalationStep(__escalationStep => { return { ...__escalationStep, wasContactMade: wasContactMade } });
    }

    function onError() {
        toastr.error("Something went wrong. Please try again later");
    }

    function submit(e: any) {
        //setLoading(true);
        //params.emitOnClose();
        saveLog();
    }

    function saveLog() {

        setLoading(true);

        let escalationRuleId = escalationRules[activeEscalationStep.step].id;

        let _newEscalationLog = {
            id: 0,
            escalationNotes: activeEscalationStep.notes,
            escalationRuleId: escalationRuleId,
            escalationContactSuccessful: activeEscalationStep.wasContactMade,
            escalationAlertId: params.alertId,
            escalationTime: new Date()
        };

        return escalationLogService
            .submit(_newEscalationLog)
            .then(
                (logId) => {
                    _newEscalationLog.id = logId;
                    setEscalationAlert((_escalationAlert) => {
                        return {
                            ..._escalationAlert,
                            escalationLogs: [..._escalationAlert.escalationLogs, _newEscalationLog as IEscalationLogManage]
                        }
                    });
                },
                (e) => {
                    toastr.error("Error saving escalation log details");
                }
            )
            .finally(() => setLoading(false));
    }

    function dialogOK() {
        setRepeatStepDialogOpen(false);
    }

    function getEscalationAlertField(ruleId: number, fieldName: string) {
        if (escalationAlert?.escalationLogs?.length) {
            var log = (escalationAlert.escalationLogs.find((m) => m.escalationRuleId == ruleId) as any);
            return log ? log[fieldName] : null;
        }
        return null;
    }

    function getEscalationAlertNotes(ruleId: number) {
        return getEscalationAlertField(ruleId, "escalationNotes");
    }

    function getEscalationTime(ruleId: number) {
        return getEscalationAlertField(ruleId, "escalationTime");
    }

    const hasRepeatTimeExpired = (rule: IEscalationRuleView, index: number) => {
        if (index === activeEscalationStep.step && rule.repeatStep === true && index > 0) {
            return getCurrentTime(getEscalationTime(escalationRules[index - 1].id)) + (rule.repeatStepMinutes * 60) - getCurrentTime() <= 0;
        }
        return true;
    }

    const getStepLabelOptional = (rule: IEscalationRuleView, index: number) => {
        if (index === activeEscalationStep.step) {
            if (rule.repeatStep === true && index > 0) {
                return formatedTimeStringFromSeconds(getCurrentTime(getEscalationTime(escalationRules[index - 1].id)) + (rule.repeatStepMinutes * 60) - getCurrentTime());
            }
        }
        else {
            return getEscalationAlertNotes(rule.id);
        }
        return null;
    }

    return (
        <Collapse in={!isCompleted}>
            <Paper className={classes.paper}>
                <ValidatorForm onError={onError} onSubmit={submit} noValidate>

                    <Grid container className={classes.escalationDetails}>
                        <Typography variant="h5">Employee Details</Typography>
                        <Grid item xs={12} md={12} className={classes.escalationDetails}>
                            <Collapse in={hasUserResetTimer}>

                                <Alert severity="success"> User confirmed to be OK  </Alert>

                                <PrimaryButton
                                    variant={"info"}
                                    onClick={() => { params.emitCompleted(params.alertId) }} >
                                    Dismiss Alert
                                </PrimaryButton>

                            </Collapse>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Grid container className={classes.escalationDetails}>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Escalation for user"
                                        value={escalationAlert?.clientUserName}
                                        name="noOfEmployeesLicensed"
                                        onChange={() => { }}
                                        variant="filled"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Phone No. for user"
                                        value={escalationAlert?.clientUserPhoneNumber}
                                        name="noOfEmployeesLicensed"
                                        onChange={() => { }}
                                        variant="filled"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        label="Building"
                                        value={escalationAlert?.buildingName}
                                        name="noOfEmployeesLicensed"
                                        onChange={() => { }}
                                        variant="filled"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        label="Zone"
                                        value={escalationAlert?.zoneName}
                                        name="noOfEmployeesLicensed"
                                        onChange={() => { }}
                                        variant="filled"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>


                                <Grid item xs={6}>
                                    <TextField
                                        label="Latitude"
                                        value={escalationAlert?.lastKnownLatitude}
                                        name="lastKnownLatitude"
                                        onChange={() => { }}
                                        variant="filled"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Longitude"
                                        value={escalationAlert?.lastKnownLongitude}
                                        name="lastKnownLongitude"
                                        onChange={() => { }}
                                        variant="filled"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                {(escalationAlert.specialConditionNote && escalationAlert.specialConditionNote.length > 0) && <Grid item xs={12}>
                                    <TextField
                                        label="Special Condition Note"
                                        value={escalationAlert?.specialConditionNote}
                                        name="specialConditionNote"
                                        onChange={() => { }}
                                        variant="filled"
                                        fullWidth
                                        multiline
                                        InputLabelProps={{ shrink: true }}
                                        className={classes.backgroundWarning}
                                    />
                                </Grid>}
                            </Grid>
                        </Grid>

                        <Typography variant="h5">Action Steps</Typography>

                        <Grid item xs={12} className={classes.paddingBottom}>
                            <Grid container className={classes.escalationDetails}>

                                <Grid item xs={12} md={12} lg={12}>
                                    <Stepper activeStep={activeEscalationStep.step}
                                        orientation="vertical"
                                        style={{ "position": "relative" }}>
                                        {escalationRules.map((rule, index) => (
                                            <Step key={rule.id}>

                                                <StepLabel optional={getStepLabelOptional(rule, index)}>
                                                    {rule.escalationRule}
                                                </StepLabel>

                                                <StepContent>
                                                    <Backdrop
                                                        style={{
                                                            color: '#fff',
                                                            // zIndex: (theme: any) => theme.zIndex.drawer + 1,
                                                            position: "absolute",
                                                            borderRadius: "5px",
                                                            backgroundColor: "rgb(192, 192, 192, 0.5)"
                                                        }}
                                                        open={isLoading}
                                                        onClick={() => { }}>
                                                        <CircularProgress color="inherit" />
                                                    </Backdrop>

                                                    <Typography>{rule.description}</Typography>
                                                    <Box sx={{ mb: 2 }}>
                                                        <Grid item xs={12} justifyContent="center">
                                                            <Grid container >

                                                                <Grid item xs={6} >
                                                                    <PrimaryButton
                                                                        disabled={!hasRepeatTimeExpired(rule, index)}
                                                                        variant={activeEscalationStep.wasContactMade === false ? "disabled" : "success"}
                                                                        onClick={handleWasContactMadeChange(true)} >
                                                                        Yes, I made contact
                                                                    </PrimaryButton>
                                                                </Grid>

                                                                <Grid item xs={6} >
                                                                    <PrimaryButton
                                                                        disabled={!hasRepeatTimeExpired(rule, index)}
                                                                        variant={activeEscalationStep.wasContactMade === true ? "disabled" : "danger"}
                                                                        onClick={handleWasContactMadeChange(false)} >
                                                                        No, I was unable to make contact
                                                                    </PrimaryButton>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Collapse in={activeEscalationStep.wasContactMade !== null}>
                                                            <Grid item xs={12} className={classes.noteMarginTop}>
                                                                <TextValidator
                                                                    value={activeEscalationStep.notes}
                                                                    label="Notes"
                                                                    name="notes"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    onChange={handleNoteChange}
                                                                    required
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <PrimaryButton type="submit" variant="success" disabled={activeEscalationStep.notes.length == 0}>
                                                                    Save {activeEscalationStep.wasContactMade ? " and close" : " and move to next step"}
                                                                </PrimaryButton>
                                                            </Grid>
                                                        </Collapse>
                                                    </Box>
                                                </StepContent>
                                            </Step>
                                        ))}

                                        {(activeEscalationStep.wasContactMade === null) &&
                                            <Grid item xs={12}>
                                                <PrimaryButton type="submit" variant="warning" onClick={() => { params.emitCompleted(activeEscalationStep.step) }} >
                                                    Discard
                                                </PrimaryButton>
                                            </Grid>
                                        }

                                    </Stepper>

                                </Grid>


                            </Grid>
                        </Grid>

                        {escalationPolicy && <>
                            <Typography variant="h5">Escalation Policy</Typography>
                            <Typography variant="caption"><div dangerouslySetInnerHTML={{ __html: escalationPolicy }} /></Typography>
                        </>}


                    </Grid>
                </ValidatorForm>

            </Paper>
        </Collapse >);
}