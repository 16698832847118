import { makeAutoObservable } from 'mobx';

class ToolbarStore {

    private _clientId = 0;
    private _shouldShowClientDropdown = false;

    constructor() {
        makeAutoObservable(this);
    }

    setClientId(clientId: number) {
        this._clientId = clientId;
    }

    setShowClientDropdown(shouldShowClientDropdown: boolean) {
        this._shouldShowClientDropdown = shouldShowClientDropdown;
    }

    get clientId() {
        return this._clientId
    }

    get shouldShowClientDropdown() {
        return this._shouldShowClientDropdown
    }
}

export const TOOLBAR_STORE = new ToolbarStore();