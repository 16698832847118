import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import React from 'react';


export function AuthFooter() {
    return (
        <Typography variant="body2" color="inherit" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://lyonsgroup.ie/">
                Lyons Group
            </Link>{' '}
            {new Date().getFullYear()}
        </Typography>
    );
}