import { AppBar, Box, Grid, Hidden, Icon, Toolbar, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { PRIMARY_COLOR } from '../../_common/const';
import { accountService } from '../../_services';
import { TOOLBAR_STORE } from '../../_stores/toolbar.store';
import { ClientToolbar } from './client.toolbar';

const logoImg = require('../../_assets/images/logo.png');

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,

    },
    appBar: {
        flexGrow: 1,
        backgroundColor: PRIMARY_COLOR[0],
        [theme.breakpoints.down('xs')]: {
            background: `linear-gradient(135deg, white 210px, ${theme.palette.primary.main} 0%)`
        },
        [theme.breakpoints.down('sm')]: {
            background: `linear-gradient(135deg, white 220px, ${theme.palette.primary.main} 0%)`
        }
    },
    appLogo: {
        width: "calc(240px * 0.95)",
        height: "100%",
        marginTop: "0px",
        display: 'block',
        marginLeft: '0px',
        marginRight: 'auto'
    },
    pageHeading: {
        color: "white",
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,
        "& h6": {
            margin: "0px",
            padding: "0px",
            marginTop: "14px",
            fontWeight: "bold"
        },
        "& div": {
            color: "white"
        },
        "& label": {
            color: "white",
        },
        "& svg": {
            color: "white"
        },
        "& input": {
            color: "white"
        },
        '& label.Mui-focused': {
            color: 'white',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
        },
        "&>div>div>div>div": {
            borderBottom: "1px solid white"
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'white',
            },
        },
    },
    pageMinHeading: {
        marginLeft: "0px",
        borderTop: "4px double white",
        backgroundColor: "#1976d2",
        opacity: "1",
        "& h6": {
            fontSize: "18px",
            paddingLeft: "8px",
            marginTop: "14px",
            marginBottom: "14px"
        }
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: drawerWidth,
    },
})
);

export const AppToolbar = observer((params: { toggleSidebar: () => void, isOpen: boolean }) => {

    const classes = useStyles();

    const [isOpen, setIsOpen] = useState(params.isOpen);
    useEffect(() => {
        setIsOpen(params.isOpen);
    }, [params.isOpen]);

    return (
        <AppBar position="fixed" className={classes.appBar}>

            <Toolbar>
                <Typography variant="h6" className={classes.title}>
                    <Box display={{ xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none' }}>
                        <img className={classes.appLogo} src={logoImg}></img>
                    </Box>

                    <Hidden smDown>
                        <Box className={clsx(classes.pageHeading, { [classes.contentShift]: isOpen })}>

                            <Grid container>
                                <Grid item sm md={1}>
                                    <Box display={{ xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' }}>
                                        <IconButton
                                            color="inherit"
                                            aria-label="open drawer"
                                            onClick={(e) => { params.toggleSidebar() }}
                                            edge="start"
                                            className={classes.menuButton}
                                        >
                                            <Icon >menu</Icon>
                                        </IconButton>
                                    </Box>
                                </Grid>
                                <Grid item sm={11} md={4}>
                                    {(accountService.isAdmin && TOOLBAR_STORE.shouldShowClientDropdown === true) &&
                                        <ClientToolbar />
                                    }
                                </Grid>
                                <Grid item md={7}></Grid>
                            </Grid>
                        </Box>

                    </Hidden>

                </Typography>
            </Toolbar>
        </AppBar >
    );
});