import { Chip, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { IMultipleAutocomplete } from '../../_common/interface/autocomplete/multipleAutoComplete.interface';
import { IDropdownData } from '../../_common/interface';
import React from 'react';

interface IMultipleExtAutocomplete extends IMultipleAutocomplete {
    itemsLoader: (parentId?: number) => Promise<any>,
    label: string,
}


export const StandardMultipleAutoComplete = (params: IMultipleExtAutocomplete) => {

    const [isLoading, setLoading] = useState(false);
    const [items, setItems] = useState<Array<IDropdownData>>([]);

    useEffect(() => {
        if (params.hasParent === true) {
            if (params.parentId ? params.parentId > 0 : null) {
                fetchItems(params.parentId);
            }
            else if (items.length > 0) {
                setItems([]);
            }
        }
        else {
            fetchItems();
        }
    }, [params.parentId]);

    useEffect(() => {
        if (params.hasParent === true) {
            if (params.parentId ? params.parentId > 0 : null) {
                fetchItems(params.parentId);
            }
            else if (items.length > 0) {
                setItems([]);
            }
        }
        else {
            fetchItems();
        }
    }, [params.parentId]);

    function fetchItems(parentId?: number) {
        setLoading(true);
        return params.itemsLoader(parentId)
            .then(
                (json) => {
                    if (!json || json.length < 1) {
                        setItems([]);
                    }
                    else {
                        setItems(json);
                    }
                },
                (err) => {
                    setItems([]);
                }
            )
            .finally(() => setLoading(false));
    }


    return (
        <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={items}
            value={items.filter(m => params.itemIds?.some(r => r == m.id))}
            getOptionLabel={(item) => item.name}
            disableCloseOnSelect
            filterSelectedOptions
            // getOptionLabel={(option) => option.id}
            onChange={(e, v: Array<any>) => {
                params.onItemChange({
                    target: {
                        name: params.name,
                        value: v.map(r => r.id)
                    }
                })
            }}
            //renderOption={(option, { selected }) => (
            //    <>
            //        <span>
            //            {option.name}
            //        </span>
            //    </>
            //)}

            renderTags={(value, getTagProps) =>
                value.map((option: any, index: number) => (
                    <Chip
                        variant="outlined"
                        label={option.name}
                        {...getTagProps({ index })}
                    />
                ))
            }
            renderInput={(props) =>
            (<TextField {...props}

                variant="filled"
                label={params.label}
                placeholder="Search... &#x1F50D; "
                fullWidth
            />)}
        />
    );
}