

import { makeStyles } from '@material-ui/core/styles';
import { FormControl, Grid, InputLabel, ListItemText, MenuItem, Select } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { IDropdownData } from '../../_common/interface';
import { dropdownService } from '../../_services/dropdown.service';
import { TOOLBAR_STORE } from '../../_stores/toolbar.store';

const useStyles = makeStyles(theme => ({
    root: {
        "& >div": {
            paddingBottom: "0px",
            paddingTop: "20px"
        }
    },
})
);
export const ClientToolbar = observer(() => {

    const [clients, setClients] = useState<Array<IDropdownData>>([]);
    const [isLoading, setIsLoading] = useState(false);

    const classes = useStyles();

    const fetchData = () => {
        setIsLoading(true);
        return dropdownService
            .getClients()
            .then(
                (json) => {
                    setClients(json);
                }
            )
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        fetchData();
    }, []);

    function handleChange(event: any) {
        console.log('changing client to : ', event.target.value);
        TOOLBAR_STORE.setClientId(+(event.target.value || 0));
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                {isLoading === true && <>Loading..</>}
                {(isLoading === false && clients.length > 0) &&

                    <FormControl fullWidth>
                        <InputLabel id="_client_label">Select Client</InputLabel>
                        <Select
                            fullWidth
                            name="clientId"
                            variant="filled"
                            labelId="clientId"
                            id="clientId"
                            className={classes.root}
                            value={TOOLBAR_STORE.clientId}
                            onChange={handleChange}>

                            <MenuItem key={"_client_select_" + 0} value={0} disabled>
                                <ListItemText primary={"Select Client"} />
                            </MenuItem >
                            <MenuItem key={"_client_select_" + 0} value={0}>
                                <ListItemText primary={"All"} />
                            </MenuItem >

                            {(clients && clients.length > 0) && clients.map((client, i) =>
                                <MenuItem key={"_client_select_" + i} value={client.id}>
                                    <ListItemText primary={client.name} />
                                </MenuItem >
                            )}
                        </Select>
                    </FormControl>
                }

            </Grid>
        </Grid>
    );
});
