import { IStandardAutocomplete } from '../../_common/interface/iStandardAutocomplete.interface';
import { dropdownService } from '../../_services/dropdown.service';
import { StandardAutoComplete } from './standard.autocomplete';


interface IClientEmployeeAutoComplete extends IStandardAutocomplete {

}

export const ClientEmployeeAutoComplete = (params: IClientEmployeeAutoComplete) => {

    function fetchItems() {
        return dropdownService
            .getClientEmployees(params.parentId);
    }

    return (
        <StandardAutoComplete
            {...params}
            itemsLoader={fetchItems}
            label={"Select Employee"}
        />
    );
}