import { createTheme, ThemeProvider } from '@material-ui/core';
import { Route, Router, Switch } from 'react-router-dom';
import './App.css';
import { AppScreen } from './app/app-screen';
import { AuthScreen } from './auth/auth-screen';
import { PRIMARY_COLOR, SECONDARY_COLOR } from './_common/const';
import { historyHelper } from './_helpers';

// it could be your App.tsx file or theme file that is included in your tsconfig.json

export function App() {

	const theme = createTheme({
		palette: {
			primary: {
				main: PRIMARY_COLOR[0],
			},
			secondary: {
				main: SECONDARY_COLOR[0]
			}
		},
		typography: {
			fontFamily: "Montserrat",
			h3: {
				fontFamily: "Montserrat",
				color: "#444",
				letterSpacing: "0.0075em",
				verticalAlign: "middle",
			},
			h5: {
				width: "100%",
				margin: "10px",
				marginTop: "20px",
				borderBottom: "1px solid silver"
			},
			h6: {
				fontFamily: "Montserrat",
				color: "#444",
				letterSpacing: "0.0075em",
				verticalAlign: "middle",
				marginTop: "20px"
			}
		}
	});


	return (
		<ThemeProvider theme={theme}>

			<Router history={historyHelper}>
				<Route path='/'>

					<Switch>
						<Route path='/login' component={AuthScreen} />
						<Route path='/' component={AppScreen} />
					</Switch>

				</Route>

			</Router>
		</ThemeProvider >
	);
}

export default App;
