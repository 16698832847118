import { makeStyles } from '@material-ui/core/styles';
import { Container, Paper } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { BuildingUpdateManageDrawer } from '../buildingUpdate/components/buildingUpdate-manageDrawer';
import { WARNING_COLOR } from '../_common/const';
import { IFilteringViewModel, ITableData } from '../_common/interface';
import { PaperHeader } from '../_components/paperComponents/header.paperComponent';
import { CommonTable } from '../_components/table/commonTable';
import { toastr } from '../_components/toastr/toastr';
import { getTableOptions } from '../_helpers';
import { accountService } from '../_services/account.service';
import { buildingService } from '../_services/building.service';
import { TOOLBAR_STORE } from '../_stores/toolbar.store';
import { BuildingManageDrawer } from './components/building-manageDrawer';

const useStyles = makeStyles(theme => ({
    pageHeader: {
        borderBottom: "1px solid #eee"
    }
}));

export const BuildingIndex = observer(() => {

    const [data, setData] = useState<ITableData>({ items: [], count: 0 });
    const [isLoading, setLoading] = useState(false);
    const [filter, setFilter] = useState<IFilteringViewModel>({ search: '', limit: 100, page: 0, order: 'desc', orderBy: '' });
    const [isCreatingNew, setIsCreatingNew] = useState(false);
    const [isSelectedBuildingOnTest, setIsSelectedBuildingOnTest] = useState(false);
    const [selectedClientId, setSelectedClientId] = useState(0);
    const [selectedClientName, setSelectedClientName] = useState('');
    const [selectedBuildingName, setSelectedBuildingName] = useState('');
    const [selectedBuildingId, setSelectedBuildingId] = useState(0);
    const [selectedUpdateId, setSelectedUpdateId] = useState(0);
    const [isUpdateApprovalRequired, setIsUpdateApprovalRequired] = useState(false);

    const classes = useStyles();

    useEffect(() => {
        fetchData();

        TOOLBAR_STORE.setShowClientDropdown(true);

        return () => {
            TOOLBAR_STORE.setShowClientDropdown(false);
        };
    }, [filter, TOOLBAR_STORE.clientId]);

    function fetchData() {
        setLoading(true);
        return buildingService
            .getAll(filter, TOOLBAR_STORE.clientId)
            .then(
                (json) => {
                    setData(json); setLoading(false);
                },
                (err) => { console.log(err); setLoading(false); toastr.error("Something went wrong. Please try again later") }
            );
    }

    function create() {
        setSelectedBuildingId(0);
        setSelectedClientName('');
        setSelectedClientId(0);
        setSelectedBuildingName('');
        setIsCreatingNew(true);
    }

    function edit(event: any, rowData: any) {
        setIsCreatingNew(false);
        setSelectedClientName(rowData.clientName);
        setSelectedClientId(rowData.clientId);
        setSelectedBuildingId(rowData.id);
        setSelectedBuildingName(rowData.name);
        setIsSelectedBuildingOnTest(rowData.isBuildingOnTest);

        if (rowData.isUpdateApprovalRequired && accountService.isAdmin) {
            setIsUpdateApprovalRequired(true);
            setSelectedUpdateId(rowData.updateApprovalId);
        }
    }

    function closeModal() {
        setIsCreatingNew(false);
        setSelectedBuildingId(0);
        setIsUpdateApprovalRequired(false);
        setSelectedUpdateId(0);
        setIsSelectedBuildingOnTest(false);
        fetchData();
    }

    function saveClient() {
        if (selectedBuildingId > 0) {
            closeModal();
        }

        fetchData();
    }

    const columns = [
        { title: '#', field: 'id' },
        { title: 'Name', field: 'name' },
        { title: 'Client', field: 'clientName' },
        { title: 'Address', field: 'address' },
        { title: 'City', field: 'city' },
        { title: 'Eircode', field: 'eircode' },
        { title: 'Phone Number', field: 'phoneNumber' },
        { title: 'Number of Zones', field: 'noOfZones' },
        { title: 'Status', field: 'isActive', render: (rowData: any) => <>{rowData.isActive ? "Active" : "Inactive"}</> },
        { title: 'Test Mode', field: 'isBuildingOnTest', render: (rowData: any) => <>{rowData.isBuildingOnTest ? "Yes" : "No"}</> },
        { title: 'Update Requested', field: 'isUpdateApprovalRequired', render: (rowData: any) => <>{rowData.isUpdateApprovalRequired ? "Yes" : "No"}</> },
    ];

    return (
        <>
            <Container component="main">
                <Paper>

                    <div className={classes.pageHeader}>
                        <PaperHeader title='Buildings' icon='factory'></PaperHeader>
                    </div>

                    <CommonTable
                        title={null}
                        columns={columns}
                        data={data}
                        isLoading={isLoading}
                        setFilter={setFilter}
                        onRowClick={edit}
                        options={{
                            ...getTableOptions(filter.limit),
                            rowStyle: (rowData: any) => {
                                if (rowData.isBuildingOnTest || rowData.isUpdateApprovalRequired) {
                                    return {
                                        backgroundColor: WARNING_COLOR[3],
                                        borderBottom: '2px solid white',
                                    };
                                }
                            },
                        }}
                        actions={[
                            {
                                icon: 'add',
                                tooltip: 'Add Client',
                                isFreeAction: true,
                                onClick: (event: any) => create()
                            }
                        ]}
                    />

                </Paper>
            </Container>

            {(selectedBuildingId > 0 || isCreatingNew === true) && !isUpdateApprovalRequired &&
                <BuildingManageDrawer
                    buildingId={selectedBuildingId}
                    buildingName={selectedBuildingName}
                    isBuildingOnTest={isSelectedBuildingOnTest}
                    isUpdateApprovalRequired={isUpdateApprovalRequired}
                    clientId={selectedClientId}
                    clientName={selectedClientName}
                    emitOnSave={saveClient}
                    emitOnClose={closeModal}></BuildingManageDrawer>
            }

            <BuildingUpdateManageDrawer
                buildingUpdateId={selectedUpdateId}
                isOpen={isUpdateApprovalRequired}
                emitOnClose={closeModal} />
        </>
    );
})