import { Drawer, Grid } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import { IUserUpdateManage } from '../../_common/models/userUpdate/userUpdateManage.model';
import { PrimaryButton } from '../../_components/buttons/primary.button';
import { SectionHeadingDrawer } from '../../_components/drawer/sectionHeading.drawer';
import { toastr } from '../../_components/toastr/toastr';

import { userUpdateService } from '../../_services/userUpdate.service';
import { useDrawerStyles } from '../../_styles';
import { useEditFormStyles } from '../../_styles/editForm-styles';


export function AccountUpdateManageDrawer(params: {
    userUpdateId: number,
    isOpen: boolean,
    emitOnClose: () => void
}) {

    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState<IUserUpdateManage>(getResetData);
    const [isOpen, setIsOpen] = useState(params.isOpen);
    const [title, setTitle] = useState('User Details Update');
    const drawerClasses = useDrawerStyles();
    const editFormClasses = useEditFormStyles();

    useEffect(() => {
        if (params.isOpen !== isOpen) {
            setIsOpen(params.isOpen);
        }

        if (params.userUpdateId > 0) {
            fetchData();
        }
    }, [params.isOpen]);

    function getResetData(): IUserUpdateManage {
        return {
            id: 0 || params.userUpdateId,
            userId: '',
            firstName: '',
            lastName: '',
            department: '',
            address: '',
            eircode: '',
            phoneNumber: '',
            specialConditionNote: '',
            previousFirstName: '',
            previousLastName: '',
            previousDepartment: '',
            previousAddress: '',
            previousEircode: '',
            previousPhoneNumber: '',
            previousSpecialConditionNote: ''
        };
    }

    function fetchData() {
        return userUpdateService
            .get(params.userUpdateId)
            .then(
                (json) => {
                    setData(json);
                },
                (e) => {
                    toastr.error("Error getting user update data");
                }
            );
    }

    function approve(e: any) {
        e.preventDefault();
        setLoading(true);

        return userUpdateService
            .approve(data)
            .then(
                () => {
                    toastr.success("Approved successfully");
                    params.emitOnClose();
                },
                (e) => {
                    toastr.error("Error saving user update details");
                }
            )
            .finally(() => setLoading(false));
    }

    function onError(event: any) {
        toastr.error("Please verify that all fields have correct input");
    }

    function handleChange(event: any) {
        var _data = data;

        _data[event.target.name] = event.target.value;
        setData({ ..._data });
    };

    function closeModal() {
        params.emitOnClose();
    }

    return (
        <>
            <Drawer
                anchor={"right"}
                open={isOpen}
                className={drawerClasses.root}
            >
                <ValidatorForm onError={onError} onSubmit={approve} noValidate>
                    <SectionHeadingDrawer heading={title} emitOnClose={closeModal} />
                    <Grid container>
                        <Grid item xs={6} className={[editFormClasses.paddingRight, editFormClasses.boldText].join(' ')}>Previous Value</Grid>
                        <Grid item xs={6} className={[editFormClasses.paddingRight, editFormClasses.boldText].join(' ')}>Updated Value</Grid>

                        <Grid container>
                            <Grid item xs={6} className={editFormClasses.paddingRight}>
                                <TextValidator
                                    label="First Name"
                                    value={data.previousFirstName}
                                    name="previousFirstName"
                                    fullWidth
                                    variant="filled"
                                    onChange={() => { }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextValidator
                                    label="First Name"
                                    value={data.firstName}
                                    name="firstName"
                                    fullWidth
                                    variant="filled"
                                    onChange={() => { }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={6} className={editFormClasses.paddingRight}>
                                <TextValidator
                                    label="Last Name"
                                    value={data.previousLastName}
                                    name="previousLastName"
                                    fullWidth
                                    variant="filled"
                                    onChange={() => { }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextValidator
                                    label="Last Name"
                                    value={data.lastName}
                                    name="firstName"
                                    fullWidth
                                    variant="filled"
                                    onChange={() => { }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={6} className={editFormClasses.paddingRight}>
                            <TextValidator
                                label="Email Address"
                                value={data.previousEmail}
                                name="previousEmail"
                                fullWidth
                                variant="filled"
                                onChange={() => { }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextValidator
                                label="Email Address"
                                value={data.email}
                                name="email"
                                fullWidth
                                variant="filled"
                                onChange={() => { }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item xs={6} className={editFormClasses.paddingRight}>
                            <TextValidator
                                label="Department"
                                value={data.previousDepartment}
                                name="previousDepartment"
                                fullWidth
                                variant="filled"
                                onChange={() => { }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextValidator
                                label="Department"
                                value={data.department}
                                name="department"
                                fullWidth
                                variant="filled"
                                onChange={() => { }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        
                        <Grid item xs={6} className={editFormClasses.paddingRight}>
                            <TextValidator
                                label="Address"
                                value={data.previousAddress}
                                name="previousAddress"
                                fullWidth
                                variant="filled"
                                onChange={() => { }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextValidator
                                label="Address"
                                value={data.address}
                                name="adrdress"
                                fullWidth
                                variant="filled"
                                onChange={() => { }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item xs={6} className={editFormClasses.paddingRight}>
                            <TextValidator
                                label="Eircode"
                                value={data.previousEircode}
                                name="previousEircode"
                                fullWidth
                                variant="filled"
                                onChange={() => { }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextValidator
                                label="Eircode"
                                value={data.eircode}
                                name="eircode"
                                fullWidth
                                variant="filled"
                                onChange={() => { }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item xs={6} className={editFormClasses.paddingRight}>
                            <TextValidator
                                label="Phone Number"
                                value={data.previousPhoneNumber}
                                name="previousPhoneNumber"
                                fullWidth
                                variant="filled"
                                onChange={() => { }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextValidator
                                label="Phone Number"
                                value={data.phoneNumber}
                                name="phoneNumber"
                                fullWidth
                                variant="filled"
                                onChange={() => { }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={6} className={editFormClasses.paddingRight}>
                            <TextValidator
                                label="Special Condition Note"
                                value={data.previousSpecialConditionNote}
                                name="previousSpecialConditionNote"
                                fullWidth
                                variant="filled"
                                onChange={() => { }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextValidator
                                label="Special Condition Note"
                                value={data.specialConditionNote}
                                name="specialConditionNote"
                                fullWidth
                                variant="filled"
                                onChange={() => { }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <PrimaryButton
                                type="submit"
                                variant="primary"
                                disabled={isLoading}
                            >
                                <Icon>check</Icon> <>Approve Changes</>
                            </PrimaryButton>
                        </Grid>
                        <Grid item xs={6}>
                            <PrimaryButton
                                type="button"
                                variant="danger"
                                disabled={isLoading}
                                onClick={closeModal}
                            >
                                <Icon>remove</Icon> Cancel
                            </PrimaryButton>
                        </Grid>
                    </Grid>
             
            </ValidatorForm>
        </Drawer >
        </>
    );
}
