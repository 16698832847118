import { accountService } from '../../_services/account.service';

export interface IPrimaryNavInterface {
    icon: string;
    name: string;
    mobileName?: string;
    url: string;
    onClick?: any;
    png?: string;
}

const PRIMARY_NAV: IPrimaryNavInterface[] = [
   { icon: 'home', name: 'Home', mobileName: 'Home', url: 'Home' }
];


const ADMIN_NAV: IPrimaryNavInterface[] = [
    { icon: 'dashboard', name: 'Dashboard', url: 'Dashboard' },
    { icon: 'business', name: 'Clients', url: 'Clients' },
    { icon: 'maps_home_work', name: 'Buildings', url: 'Buildings' },
    { icon: 'file_copy', name: 'Reports', url: 'Reports' },
    { icon: 'account_box', name: 'Users', url: 'Accounts' }
];

const ENGINEER_NAV: IPrimaryNavInterface[] = [
    { icon: 'maps_home_work', name: 'Buildings', url: 'Buildings' }
];

const CLIENT_NAV: IPrimaryNavInterface[] = [
    { icon: 'business', name: 'Clients', url: 'Clients' },
    { icon: 'maps_home_work', name: 'Buildings', url: 'Buildings' },
    { icon: 'account_box', name: 'Users', url: 'Accounts' }
];

const USER_NAV: IPrimaryNavInterface[] = [
    { icon: 'logout', name: 'Log Out', url: 'logout', onClick: accountService.logout }
];

const UPDATE_NAV: IPrimaryNavInterface[] = [
    { icon: '', name: 'Clients', url: 'ClientUpdates' },
    { icon: '', name: 'Buildings', url: 'BuildingUpdates' },
    { icon: '', name: 'Zones', url: 'ZoneUpdates' },
    { icon: '', name: 'Escalation Policies', url: 'EscalationPolicyUpdates' },
    { icon: '', name: 'Escalation Rules', url: 'EscalationRuleUpdates' },
    { icon: '', name: 'Users', url: 'AccountUpdates' },
];
    
export { PRIMARY_NAV, ADMIN_NAV, USER_NAV, CLIENT_NAV, ENGINEER_NAV, UPDATE_NAV };

