import { Icon } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useButtonStyles } from '../../_styles/button.styles';

export const PrimaryButton = (params: {
    children: React.ReactNode,
    variant: 'primary' | 'secondary' | 'active' | 'success' | 'danger' | 'info' | 'warning' | 'save' | 'remove' | 'default' | 'disabled'
    type?: 'button' | 'submit',
    compact?: boolean,
    cellButton?: boolean,
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    disabled?: boolean,
    isSaving?: boolean,
    isLoading?: boolean,
}) => {

    const buttonClasses = useButtonStyles();
    let buttonClass = "";

    switch (params.variant) {
        case ("default"):
            buttonClass = buttonClasses.defaultButton; break;
        case ("primary"):
        case ("save"):
            buttonClass = buttonClasses.primaryButton; break;
        case ("secondary"): buttonClass = buttonClasses.secondaryButton; break;
        case ("active"): buttonClass = buttonClasses.activeButton; break;
        case ("success"): buttonClass = buttonClasses.successsButton; break;
        case ("danger"):
        case ("remove"):
            buttonClass = buttonClasses.dangerButton; break;
        case ("info"): buttonClass = buttonClasses.infoButton; break;
        case ("warning"): buttonClass = buttonClasses.warningButton; break;
        case ("disabled"): buttonClass = buttonClasses.disabledButton; break;
    }

    return (
        <button
            type={params.type || 'button'}
            disabled={params.disabled === true || params.isLoading === true || params.isSaving === true}
            className={`btn ${buttonClasses.button} ${buttonClass} ${params.compact ? buttonClasses.compact : ""} ${params.cellButton ? buttonClasses.cellButton : ""}`}
            onClick={params.onClick}>

            {(params.isSaving !== true && params.isLoading !== true) &&
                <>
                    {params.variant === 'save' && <Icon>check</Icon>}
                    {params.variant === 'remove' && <Icon>clear</Icon>}

                    <>{params.children}</>
                </>
            }

            {(params.isSaving === true) &&
                <>Saving<LoadingDots /></>
            }

            {(params.isLoading === true) &&
                <>Loading<LoadingDots /></>
            }

        </button>
    );
}

const LoadingDots = () => {
    const [loadingStatus, setLoadingStatus] = useState("..");

    useEffect(() => {
        const intervalId = setInterval(() => {
            setLoadingStatus(ls => ls.length >= 5 ? ".." : ls + ".");
        }, 400);

        return () => clearInterval(intervalId);
    }, []);

    return <>{`${loadingStatus}`}</>;
};