import { IStandardAutocomplete } from '../../_common/interface/autocomplete/standardAutocomplete.interface';
import { dropdownService } from '../../_services/dropdown.service';
import { StandardAutoComplete } from './standard.autocomplete';

interface IClientAutoComplete extends IStandardAutocomplete {

}

export const ClientAutoComplete = (params: IClientAutoComplete) => {

    return (
        <StandardAutoComplete
            {...params}
            itemsLoader={dropdownService.getClients}
            label={"Client"} />
    );
}