import { Drawer, FormControlLabel, Grid, Icon, MenuItem, Switch } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { IDropdownData } from '../../_common/interface';
import { IBuildingManage } from '../../_common/models/building/buildingManage.model';
import { IBuildingUpdateManage } from '../../_common/models/buildingUpdate/buildingUpdateManage.model';
import { IZoneUpdateManage } from '../../_common/models/zoneUpdate/zoneUpdateManage.model';
import { PrimaryButton } from '../../_components/buttons/primary.button';
import { SectionHeadingDrawer } from '../../_components/drawer/sectionHeading.drawer';
import { toastr } from '../../_components/toastr/toastr';
import { dropdownService } from '../../_services/dropdown.service';
import { zoneUpdateService } from '../../_services/zoneUpdate.service';
import { useDrawerStyles } from '../../_styles';
import { useEditFormStyles } from '../../_styles/editForm-styles';

export function BuildingZoneUpdateManageDrawer(params: {
    buildingZoneUpdateId: number,
    isOpen: boolean,
    emitOnClose: () => void
}) {
    const [data, setData] = useState<IZoneUpdateManage>(getResetData);
    const [isLoading, setLoading] = useState(false);
    const [previousIsZoneMonitored, setPreviousIsZoneMonitored] = useState(false);
    const [isZoneMonitored, setIsZoneMonitored] = useState(false);
    const [previousIsTimeExtendable, setPreviousIsTimeExtendable] = useState(false);
    const [isTimeExtendable, setIsTimeExtendable] = useState(false);

    const [isOpen, setIsOpen] = useState(params.isOpen);
    const [title, setTitle] = useState('');
    const drawerClasses = useDrawerStyles();

    const classes = useEditFormStyles();
    const editFormClasses = useEditFormStyles();

    useEffect(() => {
        if (params.isOpen !== isOpen) {
            setIsOpen(params.isOpen);
        }

        if (params.buildingZoneUpdateId > 0) {
            fetchData();
        }
    }, [params.isOpen]);

    function getResetData(): IZoneUpdateManage {
        return {
            id: 0 || params.buildingZoneUpdateId,
            zoneId: 0,
            name: '',
            buildingName: '',
            clientName: '',
            responseMinutes: null,
            isZoneMonitored: true,
            isTimeExtendable: true,
            previousName: '',
            previousResponseMinutes: 0,
            previousIsZoneMonitored: true,
            previousIsTimeExtendable: true,
            isApproved: false
        };
    }

    function fetchData() {
        setLoading(true);
        return zoneUpdateService
            .get(params.buildingZoneUpdateId)
            .then(
                (json: any) => {
                    setIsZoneMonitored(json.isZoneMonitored);
                    setPreviousIsZoneMonitored(json.previousIsZoneMonitored);
                    setIsTimeExtendable(json.isTimeExtendable);
                    setPreviousIsTimeExtendable(json.previousIsTimeExtendable);
                    setTitle(`Update For Building Zone ${json.previousName}`);
                    setData(json)
                },
                (e) => {
                    toastr.error("Error getting building zone update data");
                }
            )
            .finally(() => setLoading(false));
    }

    function closeModal() {
        params.emitOnClose();
    }

    function approve(e: any) {
        e.preventDefault();
        setLoading(true);

        return zoneUpdateService
            .approve(data)
            .then(
                () => {
                    toastr.success("Approved successfully");
                    params.emitOnClose();
                },
                (e) => {
                    toastr.error("Error saving zone update details");
                }
            )
            .finally(() => setLoading(false));
    }

    function onError(event: any) {
        toastr.error("Please verify that all fields have correct input");
    }

    return (
        <Drawer
            anchor={"right"}
            open={isOpen}
            className={drawerClasses.root}
        >
            <ValidatorForm onError={onError} onSubmit={approve} noValidate>
                <SectionHeadingDrawer heading={title} emitOnClose={closeModal} />
                <Grid container>
                    <Grid item xs={6} className={[editFormClasses.paddingRight, editFormClasses.boldText].join(' ')}>Previous Value</Grid>
                    <Grid item xs={6} className={[editFormClasses.paddingRight, editFormClasses.boldText].join(' ')}>Updated Value</Grid>
                    <Grid item xs={6} className={editFormClasses.paddingRight}>
                        <TextValidator
                            label="Zone Name"
                            value={data.previousName}
                            name="previousName"
                            fullWidth
                            variant="filled"
                            onChange={() => { }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextValidator
                            label="Zone Name"
                            value={data.name}
                            name="name"
                            fullWidth
                            onChange={() => { }}
                            variant="filled"
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>

                    <Grid item xs={6} className={editFormClasses.paddingRight}>
                        <TextValidator
                            label="Response Minutes"
                            value={data.previousResponseMinutes}
                            name="previousResponseMinutes"
                            onChange={() => { }}
                            variant="filled"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextValidator
                            label="Response Minutes"
                            value={data.responseMinutes}
                            name="responseMinutes"
                            onChange={() => { }}
                            variant="filled"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>

                    <Grid item xs={6} className={drawerClasses.centreSwitch}>
                        <FormControlLabel
                            control={<Switch checked={previousIsZoneMonitored} color="primary" />}
                            label="Is Zone Monitored"

                        />
                    </Grid>
                    <Grid item xs={6} className={drawerClasses.centreSwitch}>
                        <FormControlLabel
                            control={<Switch checked={isZoneMonitored} color="primary" />}
                            label="Is Zone Monitored"
                        />
                    </Grid>


                    <Grid item xs={6} className={drawerClasses.centreSwitch}>
                        <FormControlLabel
                            control={<Switch checked={previousIsTimeExtendable} color="primary" />}
                            label="Is Time Extendable"
                        />
                    </Grid>
                    <Grid item xs={6} className={drawerClasses.centreSwitch}>
                        <FormControlLabel
                            control={<Switch checked={isTimeExtendable} color="primary" />}
                            label="Is Time Extendable"
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <PrimaryButton
                            type="submit"
                            variant="primary"
                            disabled={isLoading}
                        >
                            <Icon>check</Icon> <>Approve Changes</>
                        </PrimaryButton>
                    </Grid>
                    <Grid item xs={6}>
                        <PrimaryButton
                            type="button"
                            variant="danger"
                            disabled={isLoading}
                            onClick={closeModal}
                        >
                            <Icon>remove</Icon> Cancel
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </ValidatorForm>
        </Drawer >
    );
}
