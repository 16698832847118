import { makeStyles } from '@material-ui/core/styles';
import { Container, CssBaseline, Paper } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { WARNING_COLOR } from '../_common/const';
import { IFilteringViewModel, ITableData } from '../_common/interface';
import { PaperHeader } from '../_components/paperComponents/header.paperComponent';
import { CommonTable } from '../_components/table/commonTable';
import { toastr } from '../_components/toastr/toastr';
import { getTableOptions } from '../_helpers';
import { getStringDateNormalized } from '../_helpers/date.helper';
import { accountService } from '../_services';
import { clientService } from '../_services/client.service';
import { TOOLBAR_STORE } from '../_stores/toolbar.store';
import { ClientManageDrawer } from './components/client-manageDrawer';
import { ClientUpdateManageDrawer } from '../clientUpdate/components/clientUpdate-manageDrawer';

const useStyles = makeStyles(theme => ({
    pageHeader: {
        borderBottom: "1px solid #eee"
    }
}));

export const ClientIndex = observer(() => {

    const [data, setData] = useState<ITableData>({ items: [], count: 0 });
    const [isLoading, setLoading] = useState(false);
    const [filter, setFilter] = useState<IFilteringViewModel>({ search: '', limit: 100, page: 0, order: 'desc', orderBy: '' });
    const [isEdit, setIsEdit] = useState(false);
    const [selectedClientId, setSelectedClientId] = useState(0);
    const [isUpdateApprovalRequired, setIsUpdateApprovalRequired] = useState(false);
    const [selectedUpdateId, setSelectedUpdateId] = useState(0);

    const classes = useStyles();

    useEffect(() => {
        fetchData();
        TOOLBAR_STORE.setShowClientDropdown(true);

        return () => {
            TOOLBAR_STORE.setShowClientDropdown(false);
        };
    }, [filter, TOOLBAR_STORE.clientId]);

    function fetchData() {
        setLoading(true);
        return clientService
            .getAll(filter, TOOLBAR_STORE.clientId)
            .then(
                (json) => {
                    setData(json);
                },
                (e) => {
                    toastr.error("Error getting client data");
                }
            )
            .finally(() => setLoading(false));
    }

    function create() {
        setIsEdit(true);
        setSelectedClientId(0);
    }

    function edit(event: any, rowData: any) {
        setSelectedClientId(rowData.id);

        if(rowData.isUpdateApprovalRequired && accountService.isAdmin) {
            setIsUpdateApprovalRequired(true);
            setSelectedUpdateId(rowData.updateApprovalId);
        } else {
            setIsEdit(true);
        }
    }

    function closeModal() {
        setIsEdit(false);
        setIsUpdateApprovalRequired(false);
        setSelectedUpdateId(0);
        setSelectedClientId(0);
        fetchData();
    }

    const columns = [
        { title: '#', field: 'id' },
        { title: 'Name', field: 'name', width: '20%' },
        { title: 'No. Of Employees Licensed', field: 'noOfEmployeesLicensed' },
        { title: 'No. Of Employees Actual', field: 'noOfEmployeesActual' },
        { title: 'Contact Number', field: 'contactNumber', width: '20%' },
        { title: 'Address', field: 'addressLine1', width: '20%' },
        { title: 'License Expiry Date', field: 'licenseExpiryDate', render: (data: any) => <>{getStringDateNormalized(data.licenseExpiryDate)}</> },
        { title: 'Status', field: 'isActive', render: (rowData: any) => <>{rowData.isActive ? "Active" : "Inactive"}</> },
        { title: 'Update Requested', field: 'isUpdateApprovalRequired', render: (rowData: any) => <>{rowData.isUpdateApprovalRequired ? "Yes" : "No"}</> },
    ];

    return (
        <>
            <Container component="main">
                <Paper>
                    <CssBaseline />

                    <div className={classes.pageHeader}>
                        <PaperHeader title='Clients' icon='business'></PaperHeader>
                    </div>

                    <CommonTable
                        title={null}
                        columns={columns}
                        data={data}
                        isLoading={isLoading}
                        setFilter={setFilter}
                        onRowClick={edit}
                        options={{
                            ...getTableOptions(filter.limit),
                            rowStyle: (rowData: any) => {
                                if (rowData.isUpdateApprovalRequired) {
                                    return {
                                        backgroundColor: WARNING_COLOR[3],
                                        borderBottom: '2px solid white',
                                    };
                                }
                            },
                        }}
                        actions={[
                            {
                                icon: 'add',
                                tooltip: 'Add Client',
                                isFreeAction: true,
                                disabled: !accountService.isAdmin,
                                onClick: (event: any) => create()
                            }
                        ]}
                    />

                </Paper>
            </Container>

            <ClientManageDrawer clientId={selectedClientId} isOpen={isEdit} emitOnClose={closeModal}></ClientManageDrawer>
            <ClientUpdateManageDrawer clientUpdateId={selectedUpdateId} isOpen={isUpdateApprovalRequired} emitOnClose={closeModal}></ClientUpdateManageDrawer>
        </>
    );
})