import { FormControlLabel, Grid, Icon, Switch } from "@material-ui/core";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { IEscalationRuleUpdateManage } from "../../_common/models/escalationRuleUpdate/escalationRuleManage.model";
import { PrimaryButton } from "../../_components/buttons/primary.button";
import { toastr } from "../../_components/toastr/toastr";
import { escalationRuleUpdateService } from "../../_services/escalationRuleUpdate-service";
import { useDrawerStyles } from "../../_styles";
import { useEditFormStyles } from "../../_styles/editForm-styles";

export function EscalationRuleUpdateEdit(params: {
    escalationRuleUpdateId: number,
    emitOnSave: () => void,
    emitOnClose: () => void
}) {
    const editFormClasses = useEditFormStyles();
    const drawerClasses = useDrawerStyles();
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState<IEscalationRuleUpdateManage>(getResetData());
    const [repeatStep, setRepeatStep] = useState(false);
    const [previousRepeatStep, setPreviousRepeatStep] = useState(false);

    useEffect(() => {
        fetchData();
    }, [params.escalationRuleUpdateId]);

    function getResetData(): IEscalationRuleUpdateManage {
        return {
            id: 0 || params.escalationRuleUpdateId,
            buildingName: '',
            escalationStep: 0,
            escalationRule: '',
            repeatStep: false,
            repeatStepminutes: 0
            ,
            previousEscalationStep: 0,
            previousEscalationRule: '',
            previousRepeatStep: false,
            previousRepeatStepminutes: 0,

            isApproved: false
        };
    }

    function fetchData() {
        setLoading(true);
        return escalationRuleUpdateService
            .get(params.escalationRuleUpdateId)
            .then(
                (json: any) => {
                    setRepeatStep(json.repeatStep);
                    setPreviousRepeatStep(json.previousRepeatStep);
                    // setTitle(`Rule Update For Building ${json.buildingName}`)
                    setData(json);
                },
                (e: any) => {
                    toastr.error("Error getting escalation rule");
                }
            )
            .finally(() => setLoading(false));
    }

    function onError(event: any) {
        toastr.error("Please verify that all fields have correct input");
    }

    function approve(e: any) {
        e.preventDefault();
        setLoading(true);

        return escalationRuleUpdateService
            .approve(data)
            .then(
                () => {
                    toastr.success("Approved successfully");
                    params.emitOnSave();
                },
                (e) => {
                    toastr.error("Error saving escalation rule update details");
                }
            )
            .finally(() => setLoading(false));
    }
    return (
        <ValidatorForm onError={onError} onSubmit={approve} noValidate>

            <Grid container>

                <Grid item xs={12}>
                    <TextValidator
                        label="Escalation Step Number"
                        required
                        value={data.escalationStep}
                        name="escalationStep"
                        onChange={() => { }}
                        fullWidth
                        variant="filled"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={6} className={[editFormClasses.paddingRight, editFormClasses.boldText].join(' ')}>Previous Value</Grid>
                <Grid item xs={6} className={[editFormClasses.paddingRight, editFormClasses.boldText].join(' ')}>Updated Value</Grid>

                <Grid item xs={6} className={editFormClasses.paddingRight}>
                    <TextValidator
                        label="Escalation Rule"
                        value={data.previousEscalationRule}
                        name="previousEscalationRule"
                        fullWidth
                        variant="filled"
                        onChange={() => { }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextValidator
                        label="Escalation Rule"
                        value={data.escalationRule}
                        name="escalationRule"
                        fullWidth
                        onChange={() => { }}
                        variant="filled"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={6} className={drawerClasses.centreSwitch}>
                    <FormControlLabel
                        control={<Switch checked={previousRepeatStep} color="primary" />}
                        label="Repeat Step"
                    />
                </Grid>
                <Grid item xs={6} className={drawerClasses.centreSwitch}>
                    <FormControlLabel
                        control={<Switch checked={repeatStep} color="primary" />}
                        label="Repeat Step"
                    />
                </Grid>

                {repeatStep && <Grid item xs={6} className={editFormClasses.paddingRight}>
                    <TextValidator
                        label="Repeat Step After How Many Minutes"
                        value={data.previousRepeatStepMinutes}
                        name="previousepeatStepMinutes"
                        fullWidth
                        disabled
                        required
                        variant="filled"
                        type="number"
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>}
                {repeatStep && <Grid item xs={6}>
                    <TextValidator
                        label="Repeat Step After How Many Minutes"
                        value={data.repeatStepMinutes}
                        name="repeatStepMinutes"
                        fullWidth
                        required
                        variant="filled"
                        type="number"
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>}
                <Grid item xs={6}>
                    <PrimaryButton
                        type="submit"
                        variant="primary"
                        disabled={isLoading}
                    >
                        <Icon>check</Icon> <>Approve Changes</>
                    </PrimaryButton>
                </Grid>
                <Grid item xs={6}>
                    <PrimaryButton
                        type="button"
                        variant="danger"
                        disabled={isLoading}
                        onClick={params.emitOnClose}
                    >
                        <Icon>remove</Icon> Cancel
                    </PrimaryButton>
                </Grid>
            </Grid>

        </ValidatorForm>
    )
}