import { API_URL } from '../_common/const';
import { IEscalationAlertManage } from '../_common/models/escalationAlert/escalationAlertManage.model';
import { IUserZoneManage } from '../_common/models/userZone/userZoneManage.model';
import { getBody, getHeader, getQuery } from '../_helpers';
import { handleResponse } from '../_helpers/handle-response';

const API_NAME = "escalationAlert";

export const escalationAlertService = {
    get,
    getAll,
    getAlerts,
    createEscalationAlert,
    updateEscalationAlert,
    hasUserResetTimer,
    completeEscalationAlert,
    checkForUserTimeout
};

function hasUserResetTimer(id: number) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/hasUserResetTimer/?id=${id}`, requestOptions).then(handleResponse);
}

function get(id: number) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/get/?id=${id}`, requestOptions).then(handleResponse);
}

function getAlerts() {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/getAlerts/`, requestOptions).then(handleResponse);
}

function getAll(query?: any) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/getAll/?${getQuery(query)}`, requestOptions).then(handleResponse);
}

function createEscalationAlert(userZoneDetails: IUserZoneManage) {
    const requestOptions = { method: 'POST', headers: getHeader(), body: getBody(userZoneDetails) };
    return fetch(`${API_URL}/${API_NAME}/createEscalationAlert`, requestOptions).then(handleResponse);
}

function checkForUserTimeout() {
    const requestOptions = { method: 'POST', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/checkForUserTimeoutApp`, requestOptions).then(handleResponse);
}

function updateEscalationAlert(escalationAlertDetails: IEscalationAlertManage) {
    const requestOptions = { method: 'PUT', headers: getHeader(), body: getBody(escalationAlertDetails) };
    return fetch(`${API_URL}/${API_NAME}/updateEscalationAlert`, requestOptions).then(handleResponse);
}

function completeEscalationAlert(id: number) {
    const requestOptions = { method: 'PUT', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/completeEscalationAlert/?id=${id}`, requestOptions).then(handleResponse);
}