import { Drawer, FormControlLabel, Grid, Icon, IconButton, Switch, TextField } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { PrimaryButton } from '../../_components/buttons/primary.button';
import { SectionHeadingDrawer } from '../../_components/drawer/sectionHeading.drawer';
import { useDrawerStyles } from '../../_styles/drawer.styles';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { clientService } from '../../_services/client.service';
import { toastr } from '../../_components/toastr/toastr';
import { IClientManage } from '../../_common/models/client/clientManage.model';
import { getDateString } from '../../_helpers/date.helper';
import { accountService } from '../../_services';
import { Alert } from '@material-ui/lab';

export function ClientManageDrawer(params: {
    clientId: number,
    isOpen: boolean,
    emitOnClose: () => void
}) {

    const [data, setData] = useState<IClientManage>(getResetData);
    //const [fileName, setFileName] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [isVulnerableAllowed, setIsVulnerableAllowed] = useState(true);

    const drawerClasses = useDrawerStyles();

    useEffect(() => {
        if (params.clientId) fetchData();
        else setData(getResetData);
    }, [params.clientId]);

    const [isOpen, setIsOpen] = useState(params.isOpen);

    useEffect(() => {
        if (params.isOpen !== isOpen)
            setIsOpen(params.isOpen);
    }, [params.isOpen]);

    function getResetData(): IClientManage {
        return {
            id: 0 || params.clientId,
            name: "",
            clientCode: "",
            email: "",
            escalationReportEmail: "",
            addressLine1: "",
            addressLine2: "",
            town: "",
            county: "",
            eircode: "",
            contactNumber: "",
            noOfEmployeesActual: 0,
            noOfEmployeesLicensed: undefined,
            imageUrl: "",
            licenseExpiryDate: "",
            gracePeriod: undefined,
            isActive: true,
            isVulnerableAllowed: true,
            isUpdateApprovalRequired: false
        };
    }

    function fetchData() {
        setLoading(true);
        return clientService
            .get(params.clientId)
            .then(
                (json: any) => {
                    console.log('data: ', json)
                    setIsVulnerableAllowed(json.isVulnerableAllowed);
                    json.licenseExpiryDate = json.licenseExpiryDate ? getDateString(new Date(json.licenseExpiryDate)) : "";
                    setData(json)
                },
                (e) => {
                    toastr.error("Error getting client data");
                }
            )
            .finally(() => setLoading(false));
    }

    function save(e: any) {
        e.preventDefault();
        setLoading(true);

        var fileInputField = document.getElementById("clientLogo");
        const formData = new FormData();

        formData.append("file", (fileInputField as any)?.files[0]);
        formData.append("id", (params.clientId || 0).toString());
        formData.append("clientCode", (data.clientCode || '').toString());
        formData.append("email", (data.email || '').toString());
        formData.append("escalationReportEmail", (data.escalationReportEmail || '').toString());
        formData.append("name", data.name);
        formData.append("addressLine1", (data.addressLine1 || '').toString());
        formData.append("addressLine2", (data.addressLine2 || '').toString());
        formData.append("town", (data.town || '').toString());
        formData.append("county", (data.county || '').toString());
        formData.append("eircode", (data.eircode || '').toString());
        formData.append("contactNumber", (data.contactNumber || '').toString());
        formData.append("imageUrl", (data.imageUrl || '').toString());
        formData.append("licenseExpiryDate", data.licenseExpiryDate);
        formData.append("noOfEmployeesLicensed", (data.noOfEmployeesLicensed || '').toString());
        formData.append("gracePeriod", (data.gracePeriod || '').toString());
        formData.append("isVulnerableAllowed", (isVulnerableAllowed).toString());

        return clientService
            .submit(formData)
            .then(
                () => {
                    toastr.success("Saved successfully");
                    params.emitOnClose();
                    // if (params.clientId == 0) setData(getResetData);
                },
                (e) => {
                    toastr.error("Error saving client details");
                }
            )
            .finally(() => setLoading(false));
    }

    function disable() {
        setLoading(true);
        return clientService
            .disable(params.clientId)
            .then(
                () => {
                    toastr.success("Disabled successfully");
                    params.emitOnClose();
                },
                (e) => {
                    toastr.error("Error disabling client");
                }
            )
            .finally(() => setLoading(false));
    }

    function handleChange(event: any) {
        var _data = data;
        _data[event.target.name] = event.target.value;
        setData({ ..._data });
    };

    function onError(event: any) {
        toastr.error("Please verify that all fields have correct input");
    }

    return (
        <Drawer
            anchor={"right"}
            open={isOpen}
            variant="temporary"
            className={drawerClasses.root}
        >
            <ValidatorForm onError={onError} onSubmit={save} noValidate>

                <SectionHeadingDrawer heading={"Client"} emitOnClose={params.emitOnClose} />

                <Grid container spacing={1}>
                    {data.isUpdateApprovalRequired && <>
                        <Grid item xs={12}><Alert severity="error">Client has requested an update</Alert></Grid>
                    </>}
                    <Grid item xs={12} className={drawerClasses.drawerHeading}>Client Details</Grid>
                    <Grid item xs={accountService.isAdmin ? 6 : 12}>
                        <TextValidator
                            label="Client Name"
                            value={data.name}
                            name="name"
                            fullWidth
                            onChange={handleChange}
                            variant="filled"
                            required
                            validators={['required']}
                            errorMessages={['This field is required']}
                        />
                    </Grid>
                    {accountService.isAdmin && <>
                        <Grid item xs={6}>
                            <TextValidator
                                label="Client Code"
                                value={data.clientCode}
                                name="clientCode"
                                fullWidth
                                onChange={handleChange}
                                variant="filled"
                                required
                                validators={['required']}
                                errorMessages={['This field is required']}
                            />
                        </Grid>
                    </>
                    }
                    <Grid item xs={6}>
                        <TextValidator
                            label="Contact Number"
                            value={data.contactNumber}
                            name="contactNumber"
                            onChange={handleChange}
                            variant="filled"
                            fullWidth
                            required
                            validators={['required']}
                            errorMessages={['This field is required']}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextValidator
                            label="Email"
                            value={data.email}
                            name="email"
                            fullWidth
                            onChange={handleChange}
                            variant="filled"
                            required
                            validators={['isEmail', 'required']}
                            errorMessages={['Email invalid', 'This field is required']}
                        />
                    </Grid>
                    {/* <Grid item xs={12}>
                        <TextValidator
                            label="Escalation Report Email"
                            value={data.escalationReportEmail}
                            name="escalationReportEmail"
                            fullWidth
                            onChange={handleChange}
                            variant="filled"
                            required
                            validators={['isEmail', 'required']}
                            errorMessages={['Email invalid', 'This field is required']}
                        />
                    </Grid> */}
                    <Grid item xs={12} className={drawerClasses.drawerHeading}>Address Details</Grid>
                    <Grid item xs={6}>
                        <TextValidator
                            label="Address Line 1"
                            value={data.addressLine1}
                            name="addressLine1"
                            onChange={handleChange}
                            variant="filled"
                            fullWidth
                            required
                            validators={['required']}
                            errorMessages={['This field is required']}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextValidator
                            label="Address Line 2"
                            value={data.addressLine2}
                            name="addressLine2"
                            onChange={handleChange}
                            variant="filled"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextValidator
                            label="Town"
                            value={data.town}
                            name="town"
                            onChange={handleChange}
                            variant="filled"
                            fullWidth
                            required
                            validators={['required']}
                            errorMessages={['This field is required']}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextValidator
                            label="County"
                            value={data.county}
                            name="county"
                            onChange={handleChange}
                            variant="filled"
                            fullWidth
                            required
                            validators={['required']}
                            errorMessages={['This field is required']}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextValidator
                            label="Eircode"
                            value={data.eircode}
                            name="eircode"
                            onChange={handleChange}
                            variant="filled"
                            fullWidth
                            required
                            validators={['required']}
                            errorMessages={['This field is required']}
                        />
                    </Grid>

                    {accountService.isAdmin && <>
                        <Grid item xs={12} className={drawerClasses.drawerHeading}>License Details</Grid>
                        <Grid item xs={4}>
                            <TextValidator
                                variant="filled"
                                margin="normal"
                                label="License Expiry Date"
                                autoComplete="off"
                                onChange={handleChange}
                                type="date"
                                name="licenseExpiryDate"
                                value={data.licenseExpiryDate}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                label="No. Of Employees Licensed"
                                value={data.noOfEmployeesLicensed}
                                name="noOfEmployeesLicensed"
                                onChange={handleChange}
                                variant="filled"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        {data.id > 0 &&
                            <Grid item xs={4}>
                                <TextValidator
                                    label="No. Of Employees Actual"
                                    value={data.noOfEmployeesActual}
                                    name="noOfEmployeesActual"
                                    onChange={handleChange}
                                    variant="filled"
                                    fullWidth
                                    disabled
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>}
                        <Grid item xs={6}>
                            <TextValidator
                                label="Escalation Grace Period Seconds"
                                value={data.gracePeriod}
                                name="gracePeriod"
                                onChange={handleChange}
                                variant="filled"
                                fullWidth
                                required
                                validators={['required', 'minNumber:1']}
                                errorMessages={['This field is required', 'Grace period must be greater than zero']}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} className={drawerClasses.centreSwitch}>
                            <FormControlLabel
                                control={<Switch checked={isVulnerableAllowed} onChange={e => setIsVulnerableAllowed(!isVulnerableAllowed)} color="primary" />}
                                label="Are Vulnerable Users Allowed"
                            />

                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Client Logo"
                                type="file"
                                id="clientLogo"
                                variant="filled"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                            />
                        </Grid>
                    </>
                    }
                    {(data.imageUrl) &&
                        <img src={data.imageUrl} width="100%" />
                    }

                    <Grid item xs={data.isActive === false || data.id == 0 ? 12 : 6}>
                        <PrimaryButton
                            type="submit"
                            variant="primary"
                            disabled={isLoading || (data.isUpdateApprovalRequired && accountService.isClient)}
                        >
                            <Icon>check</Icon> {data.isActive === false ? <>Save and Reactivate</> : <>Save</>}
                        </PrimaryButton>
                    </Grid>
                    {(data.id > 0 && data.isActive === true) &&
                        <Grid item xs={6}>
                            <PrimaryButton
                                type="button"
                                variant="danger"
                                disabled={isLoading || accountService.isClient}
                                onClick={disable}
                            >
                                <Icon>remove</Icon> Disable
                            </PrimaryButton>
                        </Grid>
                    }
                </Grid>
            </ValidatorForm>
        </Drawer >
    );
}