import { IStandardAutocomplete } from '../../_common/interface/autocomplete/standardAutocomplete.interface';
import { dropdownService } from '../../_services/dropdown.service';
import { StandardAutoComplete } from './standard.autocomplete';

interface IZoneAutoComplete extends IStandardAutocomplete {

}

export const ZoneAutoComplete = (params: IZoneAutoComplete) => {

    return (
        <StandardAutoComplete
            {...params}
            itemsLoader={dropdownService.getZones}
            label={"Zone"} />
    );
}