import axios from 'axios';
import { getBody, getHeader, getQuery, getUploadHeader } from '../_helpers';
import { handleResponse } from '../_helpers/handle-response';
import { API_URL } from '../_common/const';
import { IBuildingManage } from '../_common/models/building/buildingManage.model';

const API_NAME = "building";

export const buildingService = {
    get,
    getAll,
    post,
    put,
    disable,
    submit(model: IBuildingManage) { return model.id ? put(model) : post(model) },
    getBuildingsForClient
};


function get(id: number) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/get/?id=${id}`, requestOptions).then(handleResponse);
}
function getAll(query?: any, clientId?: number) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/getAll/?clientId=${clientId}&${getQuery(query)}`, requestOptions).then(handleResponse);
}

function post(model?: IBuildingManage) {
    console.log('post: ', model)
    const requestOptions = { method: 'POST', headers: getHeader(), body: getBody(model) };
    return fetch(`${API_URL}/${API_NAME}/`, requestOptions).then(handleResponse);
}

function put(model?: IBuildingManage) {
    console.log('put: ', model)
    const requestOptions = { method: 'PUT', headers: getHeader(), body: getBody(model) };
    return fetch(`${API_URL}/${API_NAME}`, requestOptions).then(handleResponse);
}

function disable(id: number) {
    const requestOptions = { method: 'DELETE', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/${id}`, requestOptions).then(handleResponse);
}

function getBuildingsForClient(id: number) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/getBuildingsForClient/?clientId=${id}`, requestOptions).then(handleResponse);
}