import { API_URL } from "../_common/const";
import { getHeader, handleResponse } from "../_helpers";

const API_NAME = "notification";

export const notificationService = {
    getAllActiveClientUpdateNotifications
};

function getAllActiveClientUpdateNotifications() {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/getAllActiveClientUpdateNotifications`, requestOptions).then(handleResponse);
}