

import { API_URL } from '../_common/const';
import { getHeader } from '../_helpers';
import { handleResponse } from '../_helpers/handle-response';

const API_NAME = "dropdown";

export const dropdownService = {
    getClients,
    getBuildings,
    getZones,
    getClientEmployees
};

function getClients() {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/Clients/`, requestOptions).then(handleResponse);
}

function getBuildings(clientId?: number) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/Buildings/?clientId=${clientId}`, requestOptions).then(handleResponse);
}

function getZones(buildingId?: number) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/Zones/?buildingId=${buildingId}`, requestOptions).then(handleResponse);
}

function getClientEmployees(clientId: number) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/ClientEmployees/?clientId=${clientId}`, requestOptions).then(handleResponse);
}

