import MaterialTable from 'material-table';
import { useEffect, useState } from 'react';
import { BuildingZoneUpdateManageDrawer } from '../zoneUpdate/components/zoneUpdate-manageDrawer';
import { WARNING_COLOR } from '../_common/const';
import { IFilteringViewModel } from '../_common/interface';
import { IZoneManage } from '../_common/models/zone/zoneManage.model';
import { toastr } from '../_components/toastr/toastr';
import { accountService } from '../_services';
import { zoneService } from '../_services/zone.service';
import { ZoneEdit } from './components/zone-edit';

export function ZoneIndex(params: { buildingId: number, buildingName: string, clientName: string, emitOnSave: () => void, emitOnClose: () => void }) {

    const [data, setData] = useState<IZoneManage[]>([]);
    const [isLoading, setLoading] = useState(false);
    const [filter, setFilter] = useState<IFilteringViewModel>({ search: '', limit: 100, page: 0, order: 'desc', orderBy: '' });
    const [isEditing, setIsEditing] = useState(false);
    const [selectedClientId, setSelectedClientId] = useState(0);
    const [selectedBuildingId, setSelectedBuildingId] = useState(0);
    const [selectedClientName, setSelectedClientName] = useState('');
    const [selectedBuildingName, setSelectedBuildingName] = useState('');
    const [selectedZoneId, setSelectedZoneId] = useState(0);
    const [isUpdateApprovalRequired, setIsUpdateApprovalRequired] = useState(false);
    const [selectedUpdateId, setSelectedUpdateId] = useState(0);

    const columns = [
        { title: 'Zone Name', field: 'name', width: "60%" },
        {
            title: 'Response Minutes', field: 'responseMinutes', width: "20%",
            render: (rowData: any) => <>{rowData.responseMinutes !== null && rowData.responseMinutes !== undefined ? rowData.responseMinutes : ""}</>
        },
        { title: 'Status', field: 'isActive', width: "20%", render: (rowData: any) => <>{rowData.isActive ? "Active" : "Inactive"}</> },
        { title: 'Is Zone Monitored', field: 'isZoneMonitored', width: "20%", render: (rowData: any) => <>{rowData.isZoneMonitored ? "Yes" : "No"}</> },
        { title: 'Can Extend Time', field: 'isTimeExtendable', render: (rowData: any) => <>{rowData.isTimeExtendable ? "Yes" : "No"}</> },
        { title: 'Update Requested', field: 'isUpdateApprovalRequired', render: (rowData: any) => <>{rowData.isUpdateApprovalRequired ? "Yes" : "No"}</> }
    ];

    useEffect(() => {
        fetchData();
    }, [filter]);

    function fetchData() {
        setLoading(true);
        return zoneService
            .getAllZonesForBuilding(params.buildingId)
            .then(
                (json) => {
                    console.log('zones: ', json);
                    setData(json.items);
                    setLoading(false);
                },
                (err) => { console.log(err); setLoading(false); toastr.error("Something went wrong. Please try again later") }
            );
    }

    function create() {
        setSelectedBuildingId(params.buildingId);
        setSelectedBuildingName(params.buildingName);
        setSelectedClientName(params.clientName);
        setIsEditing(true);
    }

    function editZone(e: any, rowData: any) {
        setSelectedZoneId(rowData.id);
        setSelectedBuildingName(rowData.buildingName);
        setSelectedClientName(rowData.clientName);

        if(rowData.isUpdateApprovalRequired && accountService.isAdmin) {
            setIsUpdateApprovalRequired(true);
            setSelectedUpdateId(rowData.updateApprovalId);
        } else {
            setIsEditing(true);
        }
    }
    function save() {
        fetchData();
    }

    function closeModal() {
        setIsEditing(false);
        setSelectedZoneId(0);
        setSelectedClientId(0);
        setSelectedBuildingId(0);
        setIsUpdateApprovalRequired(false);
        setSelectedUpdateId(0);
        fetchData();
    }

    return (
        <>
            {(isEditing === false) && <MaterialTable
                columns={columns}
                data={data}
                isLoading={isLoading}
                title={"Zones"}
                onRowClick={editZone}
                actions={[
                    {
                        icon: 'add',
                        tooltip: 'Add New Zone',
                        isFreeAction: true,
                        onClick: (event: any) => create()
                    },
                    {
                        icon: 'edit',
                        tooltip: 'Edit Zone',
                        onClick: editZone
                    }
                ]}
                options={{
                    search: false,
                    paging: false,
                    toolbar: true,
                    pageSize: filter.limit,
                    pageSizeOptions: [5, 10, 20, 50, 100],
                    initialPage: 0,
                    showEmptyDataSourceMessage: true,
                    sorting: true,
                    showTitle: true,
                    loadingType: 'linear',
                    debounceInterval: 200,
                    emptyRowsWhenPaging: false,
                    rowStyle: (rowData: any) => {
                        if (rowData.isUpdateApprovalRequired) {
                            return {
                                backgroundColor: WARNING_COLOR[3],
                                borderBottom: '2px solid white',
                            };
                        }
                    },
                }}
            />
            }

            {(isEditing === true) &&
                <ZoneEdit
                    zoneId={selectedZoneId}
                    clientId={selectedClientId}
                    clientName={selectedClientName}
                    buildingId={selectedBuildingId}
                    buildingName={selectedBuildingName}
                    emitOnSave={save} emitOnClose={closeModal}></ZoneEdit>
            }

            {(isEditing === false && isUpdateApprovalRequired) &&
                <BuildingZoneUpdateManageDrawer
                    buildingZoneUpdateId={selectedUpdateId}
                    isOpen={isUpdateApprovalRequired}
                    emitOnClose={closeModal} />
            }
        </>
    );
}