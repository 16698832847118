import { Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { EscalationPolicyUpdateEdit } from "../escalationPolicyUpdate/components/escalationPolicyUpdate-edit";
import { EscalationPolicyUpdateManageDrawer } from "../escalationPolicyUpdate/components/escalationPolicyUpdate-manageDrawer";
import { IEscalationPolicyManage } from "../_common/models/escalationPolicy/escalationPolicyManage.model";
import { toastr } from "../_components/toastr/toastr";
import { accountService } from "../_services";
import { escalationService } from "../_services/escalation.service";
import { EscalationPolicyEdit } from "./components/escalationPolicy-edit";
import { Alert } from "@material-ui/lab";

export function EscalationPolicyIndex(params: { buildingId: number, emitOnSave: () => void, emitOnClose: () => void }) {
    const [policy, setPolicy] = useState('');
    const [id, setId] = useState(0);
    const [updateId, setUpdateId] = useState(0);
    const [isUpdateApprovalRequired, setIsUpdateApprovalRequired] = useState(false);
    const [isActive, setIsActive] = useState(true);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        fetchData();
    }, [params.buildingId]);

    function fetchData() {
        setLoading(true);
        return escalationService
            .getEscalationPolicyForBuilding(params.buildingId)
            .then(
                (json: any) => {
                    setPolicy(json.escalationPolicy);
                    setId(json.id);
                    setIsUpdateApprovalRequired(json.isUpdateApprovalRequired);
                    setUpdateId(json.updateId);
                    setIsActive(json.isActive);
                },
                (e) => {
                    toastr.error("Error getting escalation data");
                }
            )
            .finally(() => setLoading(false));
    }

    function closeModal() {
        fetchData();
    }

    return (<>
        {isUpdateApprovalRequired && <>
            <Grid container>
                <Grid item xs={12}><Alert severity="error">Client has requested an update</Alert></Grid>
            </Grid>
        </>}
        {(accountService.isClient || !isUpdateApprovalRequired) &&
            <EscalationPolicyEdit
                policyId={id}
                buildingId={params.buildingId}
                policy={policy}
                isActive={isActive}
                isUpdateApprovalRequired={isUpdateApprovalRequired}
                emitOnSave={params.emitOnSave}
                emitOnClose={params.emitOnClose} />
        }

        {(isUpdateApprovalRequired && updateId > 0 && accountService.isAdmin) &&
            <EscalationPolicyUpdateEdit
                escalationPolicyUpdateId={updateId}
                emitOnSave={closeModal}
                emitOnClose={closeModal}
            />}
    </>);
}