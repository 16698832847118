import { getBody, getHeader, getQuery, getUploadHeader } from '../_helpers';
import { handleResponse } from '../_helpers/handle-response';
import { API_URL } from '../_common/const';
import { IBuildingManage } from '../_common/models/building/buildingManage.model';

const API_NAME = "dashboard";

export const dashboardService = {
    get,
    getAll,
    post,
    put
};


function get(id: number) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/get/?id=${id}`, requestOptions).then(handleResponse);
}
function getAll(query?: any) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/getAll/?${getQuery(query)}`, requestOptions).then(handleResponse);
}

function post(model?: IBuildingManage) {
    const requestOptions = { method: 'POST', headers: getHeader(), body: getBody(model) };
    return fetch(`${API_URL}/${API_NAME}/`, requestOptions).then(handleResponse);
}

function put(model?: IBuildingManage) {
    const requestOptions = { method: 'PUT', headers: getHeader(), body: getBody(model) };
    return fetch(`${API_URL}/${API_NAME}`, requestOptions).then(handleResponse);
}
