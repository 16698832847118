import { FormControlLabel, Grid, Icon, IconButton, Switch, Tooltip } from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useEditFormStyles } from '../../_styles/editForm-styles';
import { toastr } from '../../_components/toastr/toastr';
import { IEscalationRuleManage } from "../../_common/models/escalationRule/escalationRuleManage.model";
import { useEffect, useState } from "react";
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import { PrimaryButton } from "../../_components/buttons/primary.button";
import { escalationService } from "../../_services/escalation.service";
import { ArrowBack } from "@material-ui/icons";
import { accountService } from "../../_services";
import { Alert } from "@material-ui/lab";




export function EscalationRuleEdit(params: { escalationRuleId: number, escalationStep: number, buildingId: number, emitOnSave: () => void, emitOnClose: () => void }) {
    const [data, setData] = useState<IEscalationRuleManage>(getResetData());
    const [repeatStep, setRepeatStep] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const classes = useEditFormStyles();

    useEffect(() => {
        if (params.escalationRuleId > 0) {
            fetchData();
        } else {
            setData(getResetData);
        }
    }, [params.escalationRuleId]);

    function handleChange(event: any) {
        var _data = data;
        _data[event.target.name] = event.target.value;
        setData({ ..._data });
    }

    function getResetData(): IEscalationRuleManage {
        return {
            id: 0 || params.escalationRuleId,
            clientBuildingId: params.buildingId,
            escalationRuleId: params.escalationRuleId || 0,
            escalationStep: params.escalationStep,
            escalationRule: '',
            repeatStep: false,
            repeatStepminutes: null,
            isActive: true,
            isUpdateApprovalRequired: false
        };
    }

    function fetchData() {
        setLoading(true);
        return escalationService
            .getEscalationRule(params.escalationRuleId)
            .then(
                (json: any) => {
                    setRepeatStep(json.repeatStep);
                    setData(json);
                },
                (e: any) => {
                    toastr.error("Error getting escalation rule");
                }
            )
            .finally(() => setLoading(false));
    }

    function onError(event: any) {
        toastr.error("Please verify that all fields have correct input");
    }

    function submit(e: any) {
        var _data = data;
        _data.buildingId = params.buildingId;
        _data.repeatStep = repeatStep;

        return escalationService
            .saveEscalationRule(_data)
            .then(
                (json: any) => {
                    toastr.success("Saved successfully");
                    params.emitOnSave();
                    closeModal();
                }, (e) => { toastr.error("Error saving escalation rule"); }
            )
            .finally(() => setLoading(false));
    }

    function disable() {
        return escalationService
            .disableEscalationRule(data.id)
            .then(
                (json: any) => {
                    toastr.success("Saved successfully");
                    params.emitOnSave();
                    closeModal();
                }, (e) => { toastr.error("Error saving escalation rule"); }
            )
            .finally(() => setLoading(false));
    }

    function closeModal() {
        params.emitOnClose();
    }

    return (
        <ValidatorForm onError={onError} onSubmit={submit} noValidate>
            <Grid container>
                {data.isUpdateApprovalRequired && <>
                    <Grid item xs={12}><Alert severity="error">Client has requested an update</Alert></Grid>
                </>}
                <Grid item xs={12}>
                    <Tooltip title="Go back">
                        <IconButton color="primary" aria-label="">
                            <Icon onClick={closeModal} >arrow_back</Icon>
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        label="Escalation Step Number"
                        required
                        value={data.escalationStep}
                        name="escalationStep"
                        onChange={handleChange}
                        fullWidth
                        variant="filled"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        label="Escalation Rule"
                        value={data.escalationRule}
                        name="escalationRule"
                        fullWidth
                        onChange={handleChange}
                        variant="filled"
                        required
                        validators={['required']}
                        errorMessages={['This field is required']}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={<Switch checked={repeatStep} onChange={e => setRepeatStep(!repeatStep)} color="primary" />}
                        label="Repeat Step"

                    />
                </Grid>
                {repeatStep && <Grid item xs={12}>
                    <TextValidator
                        label="Repeat Step After How Many Minutes"
                        value={data.repeatStepMinutes}
                        name="repeatStepMinutes"
                        fullWidth
                        required
                        validators={['required']}
                        errorMessages={['This field is required']}
                        variant="filled"
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={handleChange}
                    />
                </Grid>}
                <Grid item xs={data.isActive == false || data.id == 0 ? 12 : 6}>
                    <PrimaryButton
                        type="submit"
                        variant="primary"
                        disabled={isLoading}
                    >
                        <Icon>check</Icon> {data.isActive == false ? <>Save and Reactivate</> : <>Save</>}
                    </PrimaryButton>
                </Grid>
                {(data.id > 0 && data.isActive == true) &&
                    <Grid item xs={6}>
                        <PrimaryButton
                            type="button"
                            variant="danger"
                            disabled={isLoading}
                            onClick={disable}
                        >
                            <Icon>remove</Icon> Disable
                        </PrimaryButton>
                    </Grid>
                }
            </Grid>

        </ValidatorForm>

    );
}

