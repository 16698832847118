import { Drawer, Grid, Tab, Tabs } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { EscalationPolicyEdit } from '../../escalationPolicy/components/escalationPolicy-edit';
import { EscalationPolicyIndex } from '../../escalationPolicy/escalationPolicy-index';
import { EscalationRuleIndex } from '../../escalationRule/escalationRule-index';
import { ZoneIndex } from '../../zone/zone-index';
import { SectionHeadingDrawer } from '../../_components/drawer/sectionHeading.drawer';
import { useDrawerStyles } from '../../_styles/drawer.styles';
import { useWideDrawerStyles } from '../../_styles/wideDrawer.styles';
import { BuildingEdit } from './building-edit';
import { Alert } from '@material-ui/lab';

export function BuildingManageDrawer(params: {
    buildingId: number,
    buildingName: string,
    isBuildingOnTest: boolean,
    isUpdateApprovalRequired: boolean,
    clientId: number,
    clientName: string,
    emitOnSave: () => void,
    emitOnClose: () => void
}) {
    const [activeTab, setActiveTab] = React.useState(0);
    const drawerClasses = useDrawerStyles();
    const wideDrawerClasses = useWideDrawerStyles();

    const handleChange = (event: any, newValue: number) => {
        setActiveTab(newValue);
    };

    function closeDrawer() {
        params.emitOnClose();
    }

    return (
        <>
            <Drawer anchor={"right"} open={true} className={wideDrawerClasses.root} disableEnforceFocus>
                <SectionHeadingDrawer heading={params.clientName} emitOnClose={closeDrawer} />

                <Grid container>
                    <Grid item>
                        <Tabs value={activeTab} onChange={handleChange} aria-label="Building" className={drawerClasses.tabBorderBottom}>
                            <Tab label="Building" />
                            {params.buildingId > 0 && <Tab label="Zones" />}
                            {params.buildingId > 0 && <Tab label="Escalation Policy" />}
                            {params.buildingId > 0 && <Tab label="Escalation Rules" />}
                        </Tabs>
                    </Grid>
                    {params.isBuildingOnTest && <>
                        <Grid item xs={12}><Alert severity="error">Building is currently on test mode</Alert></Grid>
                    </>}
                    {activeTab === 0 && <Grid item xs={12}>
                        <BuildingEdit buildingId={params.buildingId} clientId={params.clientId} clientName={params.clientName} emitOnSave={params.emitOnSave} emitOnClose={params.emitOnClose}></BuildingEdit>
                    </Grid>}
                    {activeTab === 1 && <Grid item xs={12}>
                        <ZoneIndex buildingId={params.buildingId} buildingName={params.buildingName} clientName={params.clientName} emitOnSave={params.emitOnSave} emitOnClose={params.emitOnClose}></ZoneIndex>
                    </Grid>}
                    {activeTab === 2 && <Grid item xs={12}>
                        {/* <EscalationPolicyEdit buildingId={params.buildingId} emitOnSave={params.emitOnSave} emitOnClose={params.emitOnClose}></EscalationPolicyEdit> */}
                        <EscalationPolicyIndex buildingId={params.buildingId} emitOnSave={params.emitOnSave} emitOnClose={params.emitOnClose}></EscalationPolicyIndex>
                    </Grid>}
                    {activeTab === 3 && <Grid item xs={12}>
                        <EscalationRuleIndex buildingName={params.buildingName} buildingId={params.buildingId} emitOnSave={params.emitOnSave} emitOnClose={params.emitOnClose}></EscalationRuleIndex>

                    </Grid>}

                </Grid>
            </Drawer >
        </>
    );
}