import { Drawer, FormControlLabel, Grid, Icon, IconButton, Switch, Tooltip } from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useEditFormStyles } from '../../_styles/editForm-styles';
import { toastr } from '../../_components/toastr/toastr';
import { IEscalationRuleManage } from "../../_common/models/escalationRule/escalationRuleManage.model";
import { useEffect, useState } from "react";
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import { PrimaryButton } from "../../_components/buttons/primary.button";
import { escalationService } from "../../_services/escalation.service";
import { ArrowBack } from "@material-ui/icons";
import { useDrawerStyles } from "../../_styles";
import { IEscalationRuleUpdateManage } from "../../_common/models/escalationRuleUpdate/escalationRuleManage.model";
import { escalationRuleUpdateService } from "../../_services/escalationRuleUpdate-service";
import { SectionHeadingDrawer } from "../../_components/drawer/sectionHeading.drawer";
import { EscalationRuleUpdateEdit } from "./escalationRuleUpdate-edit";




export function EscalationRuleUpdateManageDrawer(params: { 
    escalationRuleUpdateId: number, 
    isOpen: boolean,
    emitOnClose: () => void }) {
        
    //const [data, setData] = useState<IEscalationRuleUpdateManage>(getResetData());
    // const [repeatStep, setRepeatStep] = useState(false);
    // const [previousRepeatStep, setPreviousRepeatStep] = useState(false);
    //const [isLoading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(params.isOpen);
    //const [title, setTitle] = useState('');
    const drawerClasses = useDrawerStyles();

    //const editFormClasses = useEditFormStyles();

    useEffect(() => {
        if (params.isOpen !== isOpen) {
            setIsOpen(params.isOpen);
        }

        // if (params.escalationRuleUpdateId > 0) {
        //     fetchData();
        // }
    }, [params.isOpen]);

    // function handleChange(event: any) {
    //     var _data = data;
    //     _data[event.target.name] = event.target.value;
    //     setData({ ..._data });
    // }

//     function getResetData(): IEscalationRuleUpdateManage {
//         return {
//             id: 0 || params.escalationRuleUpdateId,
//             buildingName: '',
//             escalationStep: 0,
//             escalationRule: '',
//             repeatStep: false,
//             repeatStepminutes: 0
// ,        
//             previousEscalationStep: 0,
//             previousEscalationRule: '',
//             previousRepeatStep: false,
//             previousRepeatStepminutes: 0,
            
//             isApproved: false
//         };
//     }

    // function fetchData() {
    //     setLoading(true);
    //     return escalationRuleUpdateService
    //         .get(params.escalationRuleUpdateId)
    //         .then(
    //             (json: any) => {
    //                 setRepeatStep(json.repeatStep);
    //                 setPreviousRepeatStep(json.previousRepeatStep);
    //                 setTitle(`Rule Update For Building ${json.buildingName}`)
    //                 setData(json);
    //             },
    //             (e: any) => {
    //                 toastr.error("Error getting escalation rule");
    //             }
    //         )
    //         .finally(() => setLoading(false));
    // }

    // function onError(event: any) {
    //     toastr.error("Please verify that all fields have correct input");
    // }

    // function approve(e: any) {
    //     e.preventDefault();
    //     setLoading(true);

    //     return escalationRuleUpdateService
    //         .approve(data)
    //         .then(
    //             () => {
    //                 toastr.success("Approved successfully");
    //                 params.emitOnClose();
    //             },
    //             (e) => {
    //                 toastr.error("Error saving escalation rule update details");
    //             }
    //         )
    //         .finally(() => setLoading(false));
    // }

    function closeModal() {
        params.emitOnClose();
    }

    return (
        <Drawer
        anchor={"right"}
        open={isOpen}
        className={drawerClasses.root}
    >
        <SectionHeadingDrawer heading={`Escalation Rule Update`} emitOnClose={closeModal} />
        {/* <ValidatorForm onError={onError} onSubmit={approve} noValidate>
        
            <Grid container>

                <Grid item xs={12}>
                    <TextValidator
                        label="Escalation Step Number"
                        required
                        value={data.escalationStep}
                        name="escalationStep"
                        onChange={() => { }}
                        fullWidth
                        variant="filled"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={6} className={[editFormClasses.paddingRight, editFormClasses.boldText].join(' ')}>Previous Value</Grid>
                <Grid item xs={6} className={[editFormClasses.paddingRight, editFormClasses.boldText].join(' ')}>Updated Value</Grid>
  
                <Grid item xs={6} className={editFormClasses.paddingRight}>
                    <TextValidator
                        label="Escalation Rule"
                        value={data.previousEscalationRule}
                        name="previousEscalationRule"
                        fullWidth
                        variant="filled"
                        onChange={() => { }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextValidator
                        label="Escalation Rule"
                        value={data.escalationRule}
                        name="escalationRule"
                        fullWidth
                        onChange={() => { }}
                        variant="filled"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={6} className={drawerClasses.centreSwitch}>
                        <FormControlLabel
                            control={<Switch checked={previousRepeatStep} color="primary" />}
                            label="Repeat Step"
                        />
                    </Grid>
                    <Grid item xs={6} className={drawerClasses.centreSwitch}>
                        <FormControlLabel
                            control={<Switch checked={repeatStep} color="primary" />}
                            label="Repeat Step"
                        />
                    </Grid>

                    {repeatStep && <Grid item xs={6} className={editFormClasses.paddingRight}>
                    <TextValidator
                        label="Repeat Step After How Many Minutes"
                        value={data.previousRepeatStepMinutes}
                        name="previousepeatStepMinutes"
                        fullWidth
                        disabled
                        required
                        validators={['required']}
                        errorMessages={['This field is required']}
                        variant="filled"
                        type="number"
                        InputLabelProps={{ shrink: true}}
                        onChange={handleChange}
                    />
                </Grid>}
                {repeatStep && <Grid item xs={6}>
                    <TextValidator
                        label="Repeat Step After How Many Minutes"
                        value={data.repeatStepMinutes}
                        name="repeatStepMinutes"
                        fullWidth
                        required
                        validators={['required']}
                        errorMessages={['This field is required']}
                        variant="filled"
                        type="number"
                        InputLabelProps={{ shrink: true}}
                        onChange={handleChange}
                    />
                </Grid>}
                <Grid item xs={6}>
                        <PrimaryButton
                            type="submit"
                            variant="primary"
                            disabled={isLoading}
                        >
                            <Icon>check</Icon> <>Approve Changes</>
                        </PrimaryButton>
                    </Grid>
                    <Grid item xs={6}>
                        <PrimaryButton
                            type="button"
                            variant="danger"
                            disabled={isLoading}
                            onClick={closeModal}
                        >
                            <Icon>remove</Icon> Cancel
                        </PrimaryButton>
                    </Grid>
            </Grid>

        </ValidatorForm> */}
        <EscalationRuleUpdateEdit
            escalationRuleUpdateId={params.escalationRuleUpdateId}
            emitOnClose={closeModal}
            emitOnSave={closeModal}
            
        />
        </Drawer>
    );
}

