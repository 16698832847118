import { makeStyles, Theme } from '@material-ui/core/styles';

import { Grid } from '@material-ui/core';
import { Container, Typography } from '@material-ui/core';
import { useEffect, useMemo, useRef, useState } from 'react';
import { EscalationPanel } from '../../escalation/escalation-panel';
import { IEscalationAlertMinimized } from '../../_common/models/escalationAlert/escalationAlertMinimized.model';
import { toastr } from '../../_components/toastr/toastr';
import { escalationAlertService } from '../../_services/escalationAlert.service';
import mp3 from "../../_assets/mp3/alarm.mp3";

export const useStyles = makeStyles<Theme, { length: number }>({
    container: {
        overflowX: "auto",
        height: "calc(100vh - 125px)"
    },
    grid: {
        width: 1300,
        // width: params => params.length * 460 + "px !important",

    }
});

export function EscalationAlertList() {
    const alarm = useRef(new Audio(mp3));

    const [escalationDataList, setEscalationDataList] = useState<Array<IEscalationAlertMinimized>>([]);
    const [isLoading, setLoading] = useState(false);

    const classes = useStyles({ length: escalationDataList?.length || 1 });

    const escalationPanels = useMemo(() => {
        return <Grid container className={classes.grid}>
            {escalationDataList.map(item =>
                <EscalationPanel
                    emitCompleted={() => completeAlert(item.id)}
                    key={"emergency_" + item.id}
                    alertId={item.id}
                    buildingId={item.buildingId} />)}
        </Grid>
    }, [escalationDataList]);

    useEffect(() => {
        fetchEscalationData();

        var _timeout = setInterval(fetchEscalationData, 10000);
        return () => {
            clearInterval(_timeout);
            alarm.current.pause();
        };
    }, []);

    useEffect(() => {
        if (escalationDataList && escalationDataList.length > 0) {
            alarm.current.loop = true;
            alarm.current.play();

            if ("vibrate" in navigator) {
                // vibration API supported
                navigator.vibrate([2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000]);
            }

        }
        else {
            alarm.current.pause();
        }
    }, [escalationDataList]);

    const completeAlert = (alertId: number) => {
        setEscalationDataList(_escalationDataList => {
            let __escalationDataList = _escalationDataList.filter(m => m.id !== alertId);
            return [...__escalationDataList];
        });

        completeEscalationAlert(alertId);
    }

    function completeEscalationAlert(alertId: number) {
        // Complete alert
        setLoading(true);
        return escalationAlertService.completeEscalationAlert(alertId)
            .then(
                (json) => {
                    toastr.success("Escalation alert completed");
                },
                (e) => {
                    toastr.error("Error completing escalation alert");
                }
            )
            .finally(() => {
                setLoading(false);
            });
    }

    function fetchEscalationData() {
        setLoading(true);
        return escalationAlertService.getAlerts()
            .then(
                (json: Array<IEscalationAlertMinimized>) => {
                    setEscalationDataList(_escalationData => {
                        let __alertsToBeAdded = [];
                        if (json && json.length) {
                            __alertsToBeAdded = json.filter(m => !_escalationData.some(e => e.id == m.id));
                            if (__alertsToBeAdded && __alertsToBeAdded.length) {
                                let __escalationData = [..._escalationData];
                                __alertsToBeAdded.forEach(m => __escalationData.push(m));
                                return __escalationData;
                            }
                        }
                        return _escalationData;
                    });
                },
                (e) => {
                    toastr.error("Error refreshing data for escalation alerts");
                }
            )
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <Container className={classes.container}>
            <Typography variant="h3" component="h2" align='center'>
                Alerts
            </Typography>

            {(escalationDataList.length < 1) &&
                <Typography variant="h6" component="h6" align='center'>
                    Currently no Alerts
                </Typography>
            }

            {escalationPanels}
        </Container>
    );
}
