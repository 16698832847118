import { makeStyles } from '@material-ui/core/styles';

export const useWideDrawerStyles = makeStyles(theme => ({
    root: {
        "& .MuiDrawer-paper": {
            width: "100%",
            maxWidth: "800px",
            overflowX: "hidden"
        },
        "&>.MuiPaper-root>div": {
            height: "64px"
        }
    }
}));