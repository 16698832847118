
import { FormControlLabel, Grid, Icon, IconButton, Switch, Tooltip } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { IZoneManage } from '../../_common/models/zone/zoneManage.model';
import { PrimaryButton } from '../../_components/buttons/primary.button';
import { toastr } from '../../_components/toastr/toastr';
import { accountService } from '../../_services';
import { zoneService } from '../../_services/zone.service';
import { useEditFormStyles } from '../../_styles/editForm-styles';
import { Alert } from '@material-ui/lab';
const logoImg = require('../../_assets/images/logo.png');

var QRCode = require('qrcode')

export function ZoneEdit(params: { zoneId: number, clientId: number, clientName: string, buildingId: number, buildingName: string, emitOnSave: () => void, emitOnClose: () => void }) {

    const [data, setData] = useState<IZoneManage>(getResetData);
    const [isZoneMonitored, setIsZoneMonitored] = useState(true);
    const [isTimeExtendable, setIsTimeExtendable] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [isSaving, setSaving] = useState(false);
    const classes = useEditFormStyles();

    // QRCode.toDataURL('I am a pony!', function (err, url) {
    //     console.log(url)
    // })

    useEffect(() => {
        if (params.zoneId > 0) {
            fetchData();
        } else {
            setData(getResetData);
        }
    }, [params.zoneId]);

    function getResetData(): IZoneManage {
        return {
            id: 0 || params.zoneId,
            clientId: params.clientId,
            clientName: "",
            buildingId: params.buildingId,
            buildingName: "",
            name: "",
            responseMinutes: null,
            isZoneMonitored: true,
            isActive: true,
            isVulnerable: false,
            isTimeExtandable: true,
            isUpdateApprovalRequired: false
        };
    }

    function fetchData() {
        setLoading(true);
        return zoneService
            .get(params.zoneId)
            .then(
                (json: any) => {
                    setIsZoneMonitored(json.isZoneMonitored);
                    setIsTimeExtendable(json.isTimeExtendable);

                    console.log('zone ', json)
                    setData(json);
                },
                (e) => {
                    toastr.error("Error getting zone data");
                }
            )
            .finally(() => setLoading(false));
    }


    function handleChange(event: any) {
        var _data = data;
        _data[event.target.name] = event.target.value;
        setData({ ..._data });
    };

    function closeModal() {
        params.emitOnClose();
    }

    function save(e: any) {
        e.preventDefault();
        setSaving(true);
        let _data = { ...data };
        _data.isZoneMonitored = isZoneMonitored;
        _data.isTimeExtendable = isTimeExtendable;

        return zoneService
            .submit(_data)
            .then(
                () => {
                    toastr.success("Saved successfully");
                    params.emitOnSave();
                    if (params.zoneId == 0) setData(getResetData);
                    closeModal();
                },
                (e) => {
                    toastr.error("Error saving zone details");
                }
            )
            .finally(() => setSaving(false));
    }

    function disable() {
        setSaving(true);
        return zoneService
            .disable(params.zoneId)
            .then(
                () => {
                    toastr.success("Disabled successfully");
                    params.emitOnSave();
                    closeModal();
                },
                (e) => {
                    toastr.error("Error disabling zone data");
                }
            )
            .finally(() => setSaving(false));
    }

    function onError(event: any) {
        toastr.error("Please verify that all fields have correct input");
    }


    useEffect(() => {
        if (data.id > 0) {
            QRCode.toCanvas(document.getElementById('canvas'), '0;0;' + data.id, (error: any) => {
                if (error) console.error(error)
                console.log('success!');
            })
        }
    }, [data.id]);

    function printQR() {
        var dataUrl = (document.getElementById('canvas') as any).toDataURL(); //attempt to save base64 string to server using this var  

        let windowContent = '<!DOCTYPE html>';
        windowContent += '<html>';
        windowContent += '<head><title>Print canvas</title>';
        windowContent += '<style>.textStyle { font-family: Arial, Helvetica, sans-serif;text-align: center;}</style>';
        windowContent += '<style>.center { display: block; margin-left: auto; margin-right: auto;}</style>';
        windowContent += '<style>h1 {font-size: 54px;}</style>';
        windowContent += '<style>h2 {font-size: 40px;}</style>';
        windowContent += '</head>';
        windowContent += '<body>';
        windowContent += '<img width="200px" height="200px" id="sidebar_logo" class="center" src=' + logoImg + ' alt={"test"}></img>';
        windowContent += '<div class="textStyle"><H1>' + data.name + '</H1></div>';
        windowContent += '<div class="textStyle"><H2>' + data.buildingName + '</H2></div>';
        windowContent += '<img width="500px" height="500px" src="' + dataUrl + '" class="center">';


        windowContent += '</body>';
        windowContent += '</html>';

        const printWin = window.open('', '', 'width=' + screen.availWidth + ',height=' + screen.availHeight);
        printWin.document.open();
        printWin.document.write(windowContent);

        printWin.document.addEventListener('load', function () {
            printWin.focus();
            printWin.print();
            printWin.document.close();
            printWin.close();
        }, true);
    }

    return (
        <ValidatorForm onError={onError} onSubmit={save} noValidate>
            <Grid container>
                {data.isUpdateApprovalRequired && <>
                    <Grid item xs={12}><Alert severity="error">Client has requested an update</Alert></Grid>
                </>}
                <Grid item xs={12}>
                    <Tooltip title="Go back">
                        <IconButton color="primary" aria-label="">
                            <Icon onClick={closeModal} >arrow_back</Icon>
                        </IconButton>
                    </Tooltip>
                </Grid>

                <Grid item xs={12}>
                    <TextValidator
                        label="Client Name"
                        value={params.clientName}
                        name="clientName"
                        fullWidth
                        variant="filled"
                        disabled
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        label="Building Name"
                        value={params.buildingName}
                        name="buildingName"
                        fullWidth
                        variant="filled"
                        disabled
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextValidator
                        label="Zone Name"
                        value={data.name}
                        name="name"
                        fullWidth
                        onChange={handleChange}
                        variant="filled"
                        disabled={data.isVulnerable}
                        required
                        validators={['required']}
                        errorMessages={['This field is required']}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={6} className={classes.paddingLeft}>
                    <TextValidator
                        label="Response Minutes"
                        value={data.responseMinutes}
                        name="responseMinutes"
                        onChange={handleChange}
                        variant="filled"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                {!data.isVulnerable && <Grid item xs={6} className={classes.paddingLeft}>
                    <FormControlLabel
                        control={<Switch checked={isZoneMonitored} onChange={e => setIsZoneMonitored(!isZoneMonitored)} color="primary" />}
                        label="Monitor Zone"
                    />
                </Grid>
                }

                <Grid item xs={6} className={classes.paddingLeft}>
                    <FormControlLabel
                        control={<Switch checked={isTimeExtendable} onChange={e => setIsTimeExtendable(!isTimeExtendable)} color="primary" />}
                        label="Can Extend Time"
                    />
                </Grid>

                {(data.id > 0) &&
                    <Grid container>
                        <Grid item xs={12}>
                            <canvas id="canvas" style={{ display: "block", margin: "auto" }}></canvas>
                        </Grid>
                        <Grid item xs={12}>

                            <PrimaryButton
                                type="button"
                                variant="success"
                                disabled={isLoading}
                                onClick={printQR}
                            >
                                <Icon>print</Icon> Print QR Code
                            </PrimaryButton>

                        </Grid>
                    </Grid>
                }
                <Grid item xs={data.isActive === false || data.id == 0 || data.isVulnerable ? 12 : 6}>
                    <PrimaryButton
                        type="submit"
                        variant="primary"
                        disabled={isLoading || (data.isUpdateApprovalRequired && accountService.isClient)}
                    >
                        <Icon>check</Icon> {data.isActive === false ? <>Save and Reactivate</> : <>Save</>}
                    </PrimaryButton>
                </Grid>

                {(data.id > 0 && data.isActive === true && !data.isVulnerable) &&
                    <Grid item xs={6}>
                        <PrimaryButton
                            type="button"
                            variant="danger"
                            disabled={isLoading}
                            onClick={disable}
                        >
                            <Icon>remove</Icon> Disable
                        </PrimaryButton>
                    </Grid>
                }
            </Grid>
        </ValidatorForm>
    );
}