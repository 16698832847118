

import { getBody, getHeader, getQuery, getUploadHeader } from '../_helpers';
import { handleResponse } from '../_helpers/handle-response';
import { API_URL } from '../_common/const';
import { IEscalationPolicyUpdateManage } from '../_common/models/escalationPolicyUpdate/escalationPolicyUpdate.model';

const API_NAME = "escalationPolicyUpdate";

export const escalationPolicyUpdateService = {
    get,
    getAll,
    approve
};

function get(id: number) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/get/?id=${id}`, requestOptions).then(handleResponse);
}

function getAll(query?: any, clientId?: number) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/getAll/?clientId=${clientId}&${getQuery(query)}`, requestOptions).then(handleResponse);
}

function approve(model?: IEscalationPolicyUpdateManage) {
    const requestOptions = { method: 'PUT', headers: getHeader(), body: getBody(model) };
    return fetch(`${API_URL}/${API_NAME}`, requestOptions).then(handleResponse);
}