import MaterialTable from 'material-table';
import { useEffect, useState } from 'react';
import { EscalationPolicyUpdateEdit } from '../escalationPolicyUpdate/components/escalationPolicyUpdate-edit';
import { EscalationRuleUpdateEdit } from '../escalationRuleUpdate/components/escalationRuleUpdate-edit';
import { WARNING_COLOR } from '../_common/const';
import { IFilteringViewModel } from '../_common/interface';
import { IEscalationRuleManage } from '../_common/models/escalationRule/escalationRuleManage.model';
import { CommonTable } from '../_components/table/commonTable';
import { toastr } from '../_components/toastr/toastr';
import { accountService } from '../_services';
import { escalationService } from '../_services/escalation.service';
import { useEditFormStyles } from '../_styles/editForm-styles';
import { EscalationRuleEdit } from './components/escalationRule-edit';

export function EscalationRuleIndex(params: { buildingName: string, buildingId: number, emitOnSave: () => void, emitOnClose: () => void }) {

    const [data, setData] = useState<IEscalationRuleManage[]>([]);
    const [isLoading, setLoading] = useState(false);
    const [filter, setFilter] = useState<IFilteringViewModel>({ search: '', limit: 100, page: 0, order: 'desc', orderBy: '' });
    const [isEditing, setIsEditing] = useState(false);
    const [selectedBuildingId, setSelectedBuildingId] = useState(0);
    const [selectedEscalationRuleId, setSelectedEscalationRuleId] = useState(0);
    const [selectedEscalationStep, setSelectedEscalationStep] = useState(0);
    const [updateId, setUpdateId] = useState(0);
    const [isUpdateApprovalRequired, setIsUpdateApprovalRequired] = useState(false);

    const classes = useEditFormStyles();

    useEffect(() => {
        fetchData();
    }, [filter]);

    const columns = [
        { title: '#', field: 'escalationStep', width: "10%" },
        { title: 'Escalation Rule', field: 'escalationRule', width: "80%" },
        { title: 'Repeat Step Minutes', field: 'repeatStepMinutes', width: "10%", render: (rowData: any) => <>{rowData.repeatStep && rowData.repeatStepMinutes > 0 ? rowData.repeatStepMinutes : ""}</> },
        { title: 'Update Requested', field: 'isUpdateApprovalRequired', render: (rowData: any) => <>{rowData.isUpdateApprovalRequired ? "Yes" : "No"}</> },
    ];

    function fetchData() {
        setLoading(true);
        return escalationService
            .getEscalationRulesForBuilding(params.buildingId)
            .then(
                (json) => {
                    setData(json.items);
                    console.log('data: ', data);
                    setLoading(false);
                },
                (err) => { console.log(err); setLoading(false); toastr.error("Something went wrong. Please try again later") }
            );
    }

    function create() {
        setSelectedBuildingId(params.buildingId);
        setSelectedEscalationStep(data.length + 1);
        setIsEditing(true);
    }

    function editEscalationRule(e: any, rowData: any) {
        setSelectedEscalationRuleId(rowData.id);
        setIsEditing(true);

        if(accountService.isAdmin) {
            setIsUpdateApprovalRequired(rowData.isUpdateApprovalRequired);
            setUpdateId(rowData.updateId);
        }
    }
    
    function refreshAfterSave() {
        fetchData();
        closeModal();
    }
    function closeModal() {
        setIsEditing(false);
        setSelectedEscalationRuleId(0);
        setSelectedBuildingId(0);
        setIsUpdateApprovalRequired(false);
        setUpdateId(0);
    }

    return (
        <>
            {(isEditing === false) && <MaterialTable
                columns={columns}
                data={data}
                isLoading={isLoading}
                title={"Escalation Rules"}
                onRowClick={editEscalationRule}
                actions={[
                    {
                        icon: 'add',
                        tooltip: 'Add New Escalation Rule',
                        isFreeAction: true,
                        onClick: (event: any) => create()
                    },
                    {
                        icon: 'edit',
                        tooltip: 'Edit Escalation Rule',
                        onClick: editEscalationRule
                    }
                ]}
                options={{
                    search: false,
                    paging: false,
                    rowStyle: (rowData: any) => {
                        if (rowData.isUpdateApprovalRequired) {
                            return {
                                backgroundColor: WARNING_COLOR[3],
                                borderBottom: '2px solid white',
                            };
                        }
                    },
                }}
            />
            }
            {(isEditing === true && isUpdateApprovalRequired === false) &&
                <EscalationRuleEdit
                    escalationRuleId={selectedEscalationRuleId}
                    escalationStep={selectedEscalationStep}
                    buildingId={selectedBuildingId}
                    emitOnSave={refreshAfterSave} emitOnClose={closeModal}></EscalationRuleEdit>
            }

            {(isEditing === true && isUpdateApprovalRequired === true) &&
                <EscalationRuleUpdateEdit
                    escalationRuleUpdateId={updateId}
                    emitOnSave={refreshAfterSave} 
                    emitOnClose={closeModal}/>
            }
        </>
    );
}