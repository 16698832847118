
import React from 'react';
import { DashboardIndex } from '../../dashboard/dashboard-index';


export function HomeIndex() {
    return (
        <div >
            Welcome
        </div >
    );
}