import { IStandardAutocomplete } from '../../_common/interface/autocomplete/standardAutocomplete.interface';
import { dropdownService } from '../../_services/dropdown.service';
import { StandardAutoComplete } from './standard.autocomplete';

interface IBuildingAutoComplete extends IStandardAutocomplete {

}

export const BuildingAutoComplete = (params: IBuildingAutoComplete) => {

    return (
        <StandardAutoComplete
            {...params}
            itemsLoader={dropdownService.getBuildings}
            label={"Building"} />
    );
}