import { Paper } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { Route } from "react-router-dom";
import { PRIMARY_COLOR, THEME } from '../_common/const';
import { LoginForgotPassword } from './login/login-forgotPassword';
import { LoginIndex } from './login/login-index';
import { LoginResetPassword } from './login/login-resetPassword';
import { AuthFooter } from './_components/authFooter.screen';

//import * as logo from '../_assets/images/logo.jpg';
const backgroundImg = require('../_assets/images/dark-tire.png');
const logoImg = require('../_assets/images/logo.png');

const useStyles = makeStyles(theme => ({

    appScreen: {
        backgroundColor: PRIMARY_COLOR[1],
        backgroundImage: "url(" + backgroundImg + ")",
    },

    appLogo: {
        width: "calc(100% - 250px)",
        margin: "10px auto",
        display: "block",
    },
    logoBox: {
        marginBottom: "30px",
    },
    loginBox: {
    },
    mainBackground: {
        paddingTop: "50px",
    },
    ieBox: {
        maxWidth: "650px",
        margin: "0px auto"
    }
}));


export const AuthScreen = () => {
    console.log("re 2");
    const classes = useStyles();

    return (
        <div className={classes.appScreen} id="app-screen">
            <Container component="main" maxWidth="sm" className={classes.mainBackground}>
                <div className={classes.ieBox}>
                    <Paper elevation={3} className={classes.logoBox}>
                        <img className={classes.appLogo} src={logoImg}></img>
                    </Paper>

                    <Paper elevation={3} className={classes.loginBox}>

                        <CssBaseline />

                        <Route exact path="/login" component={LoginIndex} />
                        <Route exact path="/login/forgotpassword" component={LoginForgotPassword} />
                        <Route path="/login/resetpassword/:code" component={LoginResetPassword} />

                        <Box mt={8}>
                            <AuthFooter />

                        </Box>
                    </Paper>
                </div>
            </Container>
        </div>
    );
}