import { BehaviorSubject } from 'rxjs';
import { AUTH_URL } from '../_common/const/config.const';
import { RoleEnum } from '../_common/enum';
import { getBody } from '../_helpers/body-helper';
import { handleResponse } from '../_helpers/handle-response';
import { getHeader } from '../_helpers/header.helper';

const currentUserSubject = new BehaviorSubject(localStorage.getItem('currentUser') && localStorage.getItem('currentUser') !== "undefined" ? (JSON.parse(localStorage.getItem('currentUser') ?? "")) : null);

export const accountService = {
    login,
    logout,
    forgotPassword,
    resetPassword,
    post,
    put,
    disable,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() { return currentUserSubject.value },
    get isAdmin() { return (currentUserSubject.value && currentUserSubject.value.roles.length && currentUserSubject.value.roles.some((a: any) => a === RoleEnum.Admin)) },
    get isClient() { return (currentUserSubject.value && currentUserSubject.value.roles.length && currentUserSubject.value.roles.some((a: any) => a === RoleEnum.Client)) },
    get isEngineer() { return (currentUserSubject.value && currentUserSubject.value.roles.length && currentUserSubject.value.roles.some((a: any) => a === RoleEnum.Engineer)) },
    get isEmployee() { return (currentUserSubject.value && currentUserSubject.value.roles.length && currentUserSubject.value.roles.some((a: any) => a === RoleEnum.Employee)) },
    isInRole(role: RoleEnum) {
        return (currentUserSubject.value && currentUserSubject.value.roles.length && currentUserSubject.value.roles.some((a: any) => a.toLowerCase() === role.toString().toLowerCase()))
    },
    getClientId() {
        return currentUserSubject.value.clientId;
    }
};

function login(email: string, password: string, rememberMe: boolean) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email, password: password, rememberMe: rememberMe })
    };

    return fetch(`${AUTH_URL}/account/authenticate`, requestOptions)
        .then(handleResponse, function (err) { console.log(err); })
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            currentUserSubject.next(user);
            return user;
        });
}

function post(model: any) {
    const requestOptions = { method: 'POST', headers: getHeader(), body: getBody(model) };
    return fetch(`${AUTH_URL}/account/create`, requestOptions).then(handleResponse);
}

function put(accountId: any, model: any) {
    const requestOptions = { method: 'PUT', headers: getHeader(), body: getBody(model) };
    return fetch(`${AUTH_URL}/account/update/?userId=${accountId}`, requestOptions).then(handleResponse);
}


function disable(accountId: any) {
    const requestOptions = { method: 'DELETE', headers: getHeader() };
    return fetch(`${AUTH_URL}/account/disable/?userId=${accountId}`, requestOptions).then(handleResponse);
}

function logout() {

    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
    window.location.href = "login";
}

function forgotPassword(email: string) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email, app: 1 })
    };

    return fetch(`${AUTH_URL}/account/forgotpassword`, requestOptions)
        .then(handleResponse);
}

function resetPassword(email: string, password: string, confirmPassword: string, code: string) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email, password: password, confirmPassword: confirmPassword, code: code })
    };

    return fetch(`${AUTH_URL}/account/resetpassword`, requestOptions)
        .then(handleResponse);
}
