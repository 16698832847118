
import { IStandardAutocomplete } from '../../_common/interface/iStandardAutocomplete.interface';
import { dropdownService } from '../../_services/dropdown.service';
import { StandardAutoComplete } from './standard.autocomplete';


interface IZoneAutoComplete extends IStandardAutocomplete {

}

export const ZoneAutoComplete = (params: IZoneAutoComplete) => {

    function fetchItems() {
        return dropdownService
            .getZones(params.parentId);
    }

    return (
        <StandardAutoComplete
            {...params}
            itemsLoader={fetchItems}
            label={"Select Zone"} />
    );
}