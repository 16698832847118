import axios from 'axios';
import { getBody, getHeader, getQuery, getUploadHeader } from '../_helpers';
import { handleResponse } from '../_helpers/handle-response';
import { API_URL } from '../_common/const';
import { IEscalationRuleManage } from '../_common/models/escalationRule/escalationRuleManage.model';
import { IEscalationPolicyManage } from '../_common/models/escalationPolicy/escalationPolicyManage.model';

const API_NAME = "escalation";

export const escalationService = {
    get,
    getAll,
    getEscalationRulesForBuilding,
    getEscalationRule,
    getEscalationDetailsForBuilding,
    getEscalationPolicyForBuilding,
    post,
    saveEscalationPolicy,
    put,
    disableEscalationRule,
    disableEscalationPolicy,
    saveEscalationRule(model: IEscalationRuleManage) { return model.id ? updateEscalationRule(model) : createEscalationRule(model) },
};


function get(id: number) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/get/?id=${id}`, requestOptions).then(handleResponse);
}
function getAll(query?: any) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/getAll/?${getQuery(query)}`, requestOptions).then(handleResponse);
}

function getEscalationRulesForBuilding(buildingId: number) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/getEscalationRulesForBuilding/?buildingId=${buildingId}`, requestOptions).then(handleResponse);
}

function getEscalationRule(id: number) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/getEscalationRule/?id=${id}`, requestOptions).then(handleResponse);
}

function getEscalationDetailsForBuilding(buildingId: number) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/getEscalationDetailsForBuilding/?buildingId=${buildingId}`, requestOptions).then(handleResponse);
}

function post(model?: IEscalationRuleManage) {
    const requestOptions = { method: 'POST', headers: getHeader(), body: getBody(model) };
    return fetch(`${API_URL}/${API_NAME}/`, requestOptions).then(handleResponse);
}

function put(model?: IEscalationRuleManage) {
    const requestOptions = { method: 'PUT', headers: getHeader(), body: getBody(model) };
    return fetch(`${API_URL}/${API_NAME}`, requestOptions).then(handleResponse);
}

function disableEscalationRule(id: number) {
    const requestOptions = { method: 'DELETE', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/disableEscalationRule/?escalationRuleId=${id}`, requestOptions).then(handleResponse);
}

function disableEscalationPolicy(escalationPolicyId: number) {
    const requestOptions = { method: 'DELETE', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/disableEscalationPolicy/?escalationPolicyId=${escalationPolicyId}`, requestOptions).then(handleResponse);
}

function getEscalationPolicyForBuilding(buildingId: number) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/getEscalationPolicyForBuilding/?buildingId=${buildingId}`, requestOptions).then(handleResponse);
}

function createEscalationPolicy(model?: IEscalationPolicyManage) {
    const requestOptions = { method: 'POST', headers: getHeader(), body: getBody(model) };
    return fetch(`${API_URL}/${API_NAME}/createEscalationPolicy/`, requestOptions).then(handleResponse);
}

function updateEscalationPolicy(model?: IEscalationPolicyManage) {
    const requestOptions = { method: 'PUT', headers: getHeader(), body: getBody(model) };
    return fetch(`${API_URL}/${API_NAME}/updateEscalationPolicy/`, requestOptions).then(handleResponse);
}

function saveEscalationPolicy(model?: IEscalationPolicyManage) {
    const requestOptions = { method: 'PUT', headers: getHeader(), body: getBody(model) };
    return fetch(`${API_URL}/${API_NAME}/saveEscalationPolicy/`, requestOptions).then(handleResponse);
}

function createEscalationRule(model?: IEscalationRuleManage) {
    const requestOptions = { method: 'POST', headers: getHeader(), body: getBody(model) };
    return fetch(`${API_URL}/${API_NAME}/createEscalationRule/`, requestOptions).then(handleResponse);
}

function updateEscalationRule(model?: IEscalationRuleManage) {
    const requestOptions = { method: 'PUT', headers: getHeader(), body: getBody(model) };
    return fetch(`${API_URL}/${API_NAME}/updateEscalationRule/`, requestOptions).then(handleResponse);
}