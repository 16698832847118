import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { accountService } from '../../_services';


export const AuthRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {
        const currentUser = accountService.currentUserValue;
        if (props.location.pathname === '/login') {
            // return no view if not logged in
            return null;
        }
        else if (!currentUser) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        }
        // authorised so return component
        return <Component {...props} {...rest} {...(rest.properties)} />
    }} />
)