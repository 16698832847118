import { FormControlLabel, Grid, Icon, MenuItem, Switch } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { IDropdownData } from '../../_common/interface';
import { IBuildingManage } from '../../_common/models/building/buildingManage.model';
import { PrimaryButton } from '../../_components/buttons/primary.button';
import { toastr } from '../../_components/toastr/toastr';
import { accountService } from '../../_services';
import { buildingService } from '../../_services/building.service';
import { dropdownService } from '../../_services/dropdown.service';
import { useEditFormStyles } from '../../_styles/editForm-styles';
import { Alert } from '@material-ui/lab';

export function BuildingEdit(params: {
    buildingId: number,
    clientId: number,
    clientName: string,
    emitOnSave: () => void,
    emitOnClose: () => void
}) {
    const [data, setData] = useState<IBuildingManage>(getResetData);
    const [isLoading, setLoading] = useState(false);
    const [isSaving, setSaving] = useState(false);
    const [isBuildingOnTest, setIsBuildingOnTest] = useState(false);
    const [clientData, setClientData] = useState<Array<IDropdownData>>([]);
    const [clientName, setClientName] = useState('');
    const classes = useEditFormStyles();

    useEffect(() => {
        if (params.buildingId)
            fetchData();
        else
            setData(getResetData);

        fetchClientData();
    }, [params.buildingId]);

    function getResetData(): IBuildingManage {
        return {
            id: 0 || params.buildingId,
            clientId: params.clientId,
            clientName: params.clientName,
            name: "",
            address: "",
            city: "",
            eircode: "",
            phoneNumber: "",
            numberOfZones: 0,
            responseMinutes: null,
            buildingRadius: null,
            isActive: true,
            isBuildingOnTest: false,
            isVulnerable: false,
            isUpdateApprovalRequired: false
        };
    }

    function fetchData() {
        setLoading(true);
        return buildingService
            .get(params.buildingId)
            .then(
                (json: any) => {
                    console.log('building data: ', json)
                    setIsBuildingOnTest(json.isBuildingOnTest);
                    setData(json);
                },
                (e) => {
                    toastr.error("Error getting building data");
                }
            )
            .finally(() => setLoading(false));
    }

    function fetchClientData() {
        setLoading(true);
        return dropdownService
            .getClients()
            .then(
                (json) => {
                    setClientData(json);
                    console.log('client data: ', json);
                },
                (e) => {
                    toastr.error("Error getting client data");
                }
            )
            .finally(() => setLoading(false));
    }

    function handleChange(event: any) {
        var _data = data;
        if (event.target.name === "clientName") {
            const client = clientData.filter((x: IDropdownData) => x.name === event.target.value)[0] as IDropdownData;
            _data.clientName = client.name;
            _data.clientId = client.id;
        } else {
            _data[event.target.name] = event.target.value;
        }
        setData({ ..._data });
    };

    function closeModal() {
        params.emitOnClose();
    }

    function save(e: any) {
        e.preventDefault();
        setSaving(true);
        let _data = { ...data };
        _data.isBuildingOnTest = isBuildingOnTest;

        return buildingService
            .submit(_data)
            .then(
                () => {
                    toastr.success("Saved successfully");
                    params.emitOnSave();
                    if (params.buildingId == 0) setData(getResetData);
                    closeModal();
                },
                (e) => {
                    toastr.error("Error saving building details");
                }
            )
            .finally(() => setSaving(false));
    }

    function disable() {
        setSaving(true);
        return buildingService
            .disable(params.buildingId)
            .then(
                () => {
                    toastr.success("Disabled successfully");
                    params.emitOnSave();
                    closeModal();
                },
                (e) => {
                    toastr.error("Error disabling building");
                }
            )
            .finally(() => setSaving(false));
    }

    function onError(event: any) {
        toastr.error("Please verify that all fields have correct input");
    }
    return (
        <ValidatorForm onError={onError} onSubmit={save} noValidate>
            <Grid container>
                {data.isUpdateApprovalRequired && <>
                    <Grid item xs={12}><Alert severity="error">Client has requested an update</Alert></Grid>
                </>}
                <Grid item xs={12}>
                    <TextValidator
                        label="Building Name"
                        value={data.name}
                        name="name"
                        fullWidth
                        onChange={handleChange}
                        variant="filled"
                        required
                        validators={['required']}
                        errorMessages={['This field is required']}
                    />
                </Grid>
                {accountService.isAdmin &&
                    <Grid item xs={12}>
                        <SelectValidator
                            value={data.clientName}
                            name="clientName"
                            onChange={handleChange}
                            label="Client Name"
                            fullWidth
                            required
                            validators={['required']}
                            errorMessages={['This field is required']}
                            variant="filled"
                        >
                            {(clientData && clientData.length > 0) && clientData.map((client: IDropdownData) => (
                                <MenuItem key={client.id} value={client.name}>{client.name}</MenuItem>
                            ))}
                        </SelectValidator>
                    </Grid>
                }
                <Grid item xs={12}>
                    <TextValidator
                        label="Address"
                        value={data.address}
                        name="address"
                        fullWidth
                        onChange={handleChange}
                        variant="filled"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        label="City"
                        value={data.city}
                        name="city"
                        onChange={handleChange}
                        variant="filled"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        label="Eircode"
                        value={data.eircode}
                        name="eircode"
                        onChange={handleChange}
                        variant="filled"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        label="Phone Number"
                        value={data.phoneNumber}
                        name="phoneNumber"
                        onChange={handleChange}
                        variant="filled"
                        fullWidth
                        InputLabelProps={{ shrink: true, }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextValidator
                        label="Response Minutes"
                        value={data.responseMinutes}
                        name="responseMinutes"
                        onChange={handleChange}
                        variant="filled"
                        fullWidth
                        required
                        type="number"
                        validators={['required', 'minNumber:0', 'maxNumber:120']}
                        errorMessages={['This field is required', "Value cannot be less than 1", "Value cannot be greater than 120"]}
                        InputLabelProps={{ shrink: true, }}
                    />
                </Grid>

                <Grid item xs={4} className={classes.paddingLeft}>
                    <TextValidator
                        label="Building Radius"
                        value={data.buildingRadius}
                        name="buildingRadius"
                        onChange={handleChange}
                        variant="filled"
                        fullWidth
                        type="number"
                        InputLabelProps={{ shrink: true, }}
                    />
                </Grid>

                <Grid item xs={4} className={classes.paddingLeft}>
                    <TextValidator
                        label="No. Of Zones"
                        value={data.noOfZones}
                        name="noOfZones"
                        variant="filled"
                        fullWidth
                        readonly
                        disabled
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                </Grid>

                {((accountService.isAdmin || accountService.isEngineer) && !data.isVulnerable) &&
                    <Grid item xs={12} className={classes.paddingLeft}>
                        <FormControlLabel
                            control={<Switch checked={isBuildingOnTest} onChange={e => setIsBuildingOnTest(!isBuildingOnTest)} color="primary" />}
                            label="Set Building On Test"
                        />

                    </Grid>
                }
                <Grid item xs={data.isActive === false || data.id == 0 || data.isVulnerable ? 12 : 6}>
                    <PrimaryButton
                        type="submit"
                        variant="primary"
                        disabled={isLoading || (data.isUpdateApprovalRequired && accountService.isClient)}
                    >
                        <Icon>check</Icon> {data.isActive === false ? <>Save and Reactivate</> : <>Save</>}
                    </PrimaryButton>
                </Grid>
                {(data.id > 0 && data.isActive === true && !data.isVulnerable) &&
                    <Grid item xs={6}>
                        <PrimaryButton
                            type="button"
                            variant="danger"
                            disabled={isLoading}
                            onClick={disable}
                        >
                            <Icon>remove</Icon> Disable
                        </PrimaryButton>
                    </Grid>
                }
            </Grid>
        </ValidatorForm>
    );
}
