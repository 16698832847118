import { getBody, getHeader, getQuery, getUploadHeader } from '../_helpers';
import { handleResponse } from '../_helpers/handle-response';
import { API_URL } from '../_common/const';
import { IEscalationLogManage } from '../_common/models/escalationLog/escalationLogManage.model';
import { IEscalationLogCreate } from '../_common/models/escalationLog/escalationLogCreate.model';

const API_NAME = "escalationLog";

export const escalationLogService = {
    get,
    getAll,
    post,
    put,
    disable,
    submit(model: IEscalationLogCreate) { return post(model) },
};


function get(id: number) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/get/?id=${id}`, requestOptions).then(handleResponse);
}
function getAll(query?: any) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/getAll/?${getQuery(query)}`, requestOptions).then(handleResponse);
}

function post(model?: IEscalationLogCreate) {
    const requestOptions = { method: 'POST', headers: getHeader(), body: getBody(model) };
    return fetch(`${API_URL}/${API_NAME}/`, requestOptions).then(handleResponse);
}

function put(model?: IEscalationLogManage) {
    const requestOptions = { method: 'PUT', headers: getHeader(), body: getBody(model) };
    return fetch(`${API_URL}/${API_NAME}`, requestOptions).then(handleResponse);
}

function disable(id: number) {
    const requestOptions = { method: 'DELETE', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/${id}`, requestOptions).then(handleResponse);
}