import { useEffect, useState } from "react";
import { notificationService } from '../_services/updateNotification.service';
import { toastr } from '../_components/toastr/toastr';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";

export function ClientUpdateNotification() {
  const [showNotificationAlert, setShowNotificationAlert] = useState(false);

  useEffect(() => {
    let timer = setInterval(() => { checkForClientUpdates() }, 1800000); // 30 minutes
    return () => clearInterval(timer);
  }, []);

  function checkForClientUpdates() {
    return notificationService
      .getAllActiveClientUpdateNotifications()
      .then(
        (json: boolean) => {
          setShowNotificationAlert(json);
        },
        (e) => {
          toastr.error("Error getting client update notification data");
        }
      )
      .finally();
  }

  return (<>
    {showNotificationAlert &&
      <Dialog
        open={showNotificationAlert}
        onClose={() => setShowNotificationAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Client Update Request Received"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please Navigate To Updates on Sidebar
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowNotificationAlert(false)}>OK</Button>
        </DialogActions>
      </Dialog>
    }
  </>
  )
}