import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import React, { FormEvent, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useLocation } from 'react-router-dom';
import { RESET_PASSWORD_ERROR_MESSAGES, RESET_PASSWORD_HELPER_TEXT, RESET_PASSWORD_VALIDATORS } from '../../_common/const';
import { PaperHeader } from "../../_components/paperComponents/header.paperComponent";
import { toastr } from '../../_components/toastr/toastr';
import { historyHelper } from '../../_helpers/history';
import { accountService } from '../../_services';

const useStyles = makeStyles(theme => ({
    
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: theme.spacing(2),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        "&>div>div": {
            marginTop: "10px"
        }
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    header: {
        marginLeft: theme.spacing(-8),
    },
    appScreen: {
        backgroundColor: "#181c37",
        backgroundImage: "url('images/dark-tire.png')",
    },

    appLogo: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    appContainer: {
        paddingTop: theme.spacing(4),
    },
    backToLogin: {
        paddingTop: "20px",
        cursor: "pointer"
    }
}));

export function LoginResetPassword() {

    const [password, setPassword] = useState("");
    const [username, setUsername] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isLoading, setLoading] = useState(false);

    let { pathname } = useLocation();
    let code = pathname && pathname.indexOf("code=") ? pathname.split("code=")[1] : "";

    const classes = useStyles();

    function submit(e: FormEvent) {
        e.preventDefault();
        setLoading(true);

        accountService.resetPassword(username, password, confirmPassword, code)
            .then(
                (item => {
                    toastr.success("Password reset successfully");
                    historyHelper.push('/');
                }),
                ((err) => {
                    toastr.error(err.Message || err.title || err || "Something went wrong. Please try again later");
                }))
            .finally(() => { setLoading(false); });
    }

    function goTo(link: string) {
        historyHelper.push('/' + link);
    }

    if (accountService.currentUserValue) {
        historyHelper.push('/');
    }

    function generateGuid() {
        var result, i, j;
        result = '';
        for (j = 0; j < 32; j++) {
            if (j == 8 || j == 12 || j == 16 || j == 20)
                result = result + '-';
            i = Math.floor(Math.random() * 16).toString(16).toUpperCase();
            result = result + i;
        }
        return result;
    }

    return (
        <div className={classes.paper}>
            <div className={classes.header}>
                <PaperHeader title='Reset Password' icon='lock'></PaperHeader>
            </div>
            <ValidatorForm className={classes.form} onSubmit={submit} noValidate>

                <Grid container>
                    <Grid item xs={12}>

                        <TextValidator
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Email"
                            name="email"
                            value={username}
                            disabled={isLoading}
                            onChange={(e: any) => setUsername(e.target.value.toString())}
                            validators={['required', 'isEmail']}
                            errorMessages={['This field is required', 'Email is not valid']}
                            autoFocus
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextValidator
                            errorText={"test"}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            disabled={isLoading}
                            value={password}
                            withRequiredValidator
                            autoComplete={"current-password-" + generateGuid()}
                            onChange={(e: any) => setPassword(e.target.value.toString())}
                            validators={RESET_PASSWORD_VALIDATORS(true)}
                            errorMessages={RESET_PASSWORD_ERROR_MESSAGES(true)}
                            helperText={!password ? RESET_PASSWORD_HELPER_TEXT : "✓ Password is valid"} />
                    </Grid>

                    <Grid item xs={12}>
                        <TextValidator
                            error={confirmPassword && password !== confirmPassword}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="confirmpassword"
                            label="Confirm Password"
                            type="password"
                            id="confirmpassword"
                            disabled={isLoading}
                            value={confirmPassword}
                            withRequiredValidator
                            onChange={(e: any) => setConfirmPassword(e.target.value.toString())}
                            validators={[
                                `required`,
                                `minStringLength:6`,
                            ]}
                            errorMessages={['This field is required', 'This field must be at least 6 characters long']}
                            autoComplete={"current-password-confirm-" + generateGuid()}
                            helperText={password && confirmPassword && password === confirmPassword ? `✓ Passwords match` : confirmPassword ? "Passwords don't match" : ""}
                        />

                    </Grid>
                    <Grid item xs={12}>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={isLoading}
                            disableElevation
                            className={classes.submit}>
                            Reset Password
                        </Button>

                    </Grid>
                </Grid>
            </ValidatorForm>

            <Link onClick={() => goTo("login")} className={classes.backToLogin}>
                Back to Log In screen
            </Link>

        </div>
    );
}