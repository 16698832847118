import { makeStyles } from '@material-ui/core/styles';
import { Container, CssBaseline, Paper } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { IFilteringViewModel, ITableData } from '../../_common/interface/iTableFilter.interface';
import { PaperHeader } from '../../_components/paperComponents/header.paperComponent';
import { CommonTable } from '../../_components/table/commonTable';
import { getStringDateNormalized, getTableOptions } from '../../_helpers';
import { clientService } from '../../_services/client.service';
import { ReportManageDrawer } from './components';

const useStyles = makeStyles(theme => ({
    pageHeader: {
        borderBottom: "1px solid #eee"
    }
}));


export function ReportIndex() {

    const [data, setData] = useState<ITableData>({ items: [], count: 0 });
    const [filter, setFilter] = useState<IFilteringViewModel>({ search: '', limit: 100, page: 0, order: 'desc', orderBy: '' });
    const [isLoading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [clientId, setClientId] = useState(0);

    const classes = useStyles();

    useEffect(() => {
        fetchData();
    }, [filter]);

    function fetchData() {
        setLoading(true);
        return clientService
            .getAll(filter, clientId)
            .then(
                (json) => setData(json)
            )
            .finally(() => setLoading(false));
    }

    function view(event: any, rowData: any) {
        setIsOpen(true);
        setClientId(rowData.id);
    }

    function closeModal() {
        setIsOpen(false);
        setClientId(0);
    }

    function save() {
        if (clientId > 0) {
            closeModal();
        }

        fetchData();
    }

    const columns = [
        { title: '#', field: 'id' },
        { title: 'Name', field: 'name' },
        { title: 'No. Of Employees Licensed', field: 'noOfEmployeesLicensed' },
        { title: 'No. Of Employees Actual', field: 'noOfEmployeesActual' },
        { title: 'License Expiry Date', field: 'licenseExpiryDate', render: (data: any) => <>{getStringDateNormalized(data.licenseExpiryDate)}</> },
        { title: 'Status', field: 'isActive', render: (rowData: any) => <>{rowData.isActive ? "Active" : "Inactive"}</> },
    ];

    return (

        <Container component="main">
            <Paper>
                <CssBaseline />

                <div className={classes.pageHeader}>
                    <PaperHeader title='Client Reports' icon='business'></PaperHeader>
                </div>

                <CommonTable
                    title={null}
                    columns={columns}
                    data={data}
                    isLoading={isLoading}
                    setFilter={setFilter}
                    onRowClick={view}
                    options={getTableOptions(filter.limit)}
                />


                {
                    (clientId > 0) &&
                    <ReportManageDrawer clientId={clientId} emitOnSave={save} emitOnClose={closeModal} />
                }

            </Paper>
        </Container>
    );
}