import { Drawer, Grid, Icon } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { IDropdownData } from '../../_common/interface';
import { IBuildingUpdateManage } from '../../_common/models/buildingUpdate/buildingUpdateManage.model';
import { PrimaryButton } from '../../_components/buttons/primary.button';
import { SectionHeadingDrawer } from '../../_components/drawer/sectionHeading.drawer';
import { toastr } from '../../_components/toastr/toastr';
import { buildingUpdateService } from '../../_services/buildingUpdate.service';
import { useDrawerStyles } from '../../_styles';
import { useEditFormStyles } from '../../_styles/editForm-styles';

export function BuildingUpdateManageDrawer(params: { 
    buildingUpdateId: number, 
    isOpen: boolean, 
    emitOnClose: () => void }) {
    const [data, setData] = useState<IBuildingUpdateManage>(getResetData);
    const [isLoading, setLoading] = useState(false);
    const [isSaving, setSaving] = useState(false);
    const [isBuildingOnTest, setIsBuildingOnTest] = useState(false);
    const [clientData, setClientData] = useState<Array<IDropdownData>>([]);

    const [isOpen, setIsOpen] = useState(params.isOpen);
    const [title, setTitle] = useState('');
    const drawerClasses = useDrawerStyles();
    const editFormClasses = useEditFormStyles();

    useEffect(() => {
        if (params.isOpen !== isOpen) {
            setIsOpen(params.isOpen);
        }

        if(params.buildingUpdateId > 0) {
            fetchData();
        }
    }, [params.isOpen]);

    function getResetData(): IBuildingUpdateManage {
        return {
            id: 0 || params.buildingUpdateId,
            buildingId: 0,
            name: '',
            address: '',
            city: '',
            eircode: '',
            phoneNo: '',
            responseMinutes: 0,
            previousName: '',
            previousAddress: '',
            previousCity: '',
            previousEircode: '',
            previousPhoneNo: '',
            isApproved: false
        };
    }

    function fetchData() {
        setLoading(true);
        return buildingUpdateService
            .get(params.buildingUpdateId)
            .then(
                (json: any) => {
                    setTitle(`Update For Client Building ${json.previousName}`)
                    setData(json)
                },
                (e) => {
                    toastr.error("Error getting building update data");
                }
            )
            .finally(() => setLoading(false));
    }

    function closeModal() {
        params.emitOnClose();
    }

    function approve(e: any) {
        e.preventDefault();
        setLoading(true);

        return buildingUpdateService
            .approve(data)
            .then(
                () => {
                    toastr.success("Approved successfully");
                    params.emitOnClose();
                },
                (e) => {
                    toastr.error("Error saving client update details");
                }
            )
            .finally(() => setLoading(false));
    }

    function onError(event: any) {
        toastr.error("Please verify that all fields have correct input");
    }

    return (
        <Drawer
        anchor={"right"}
        open={isOpen}
        className={drawerClasses.root}
    >
        <ValidatorForm onError={onError} onSubmit={approve} noValidate>
        <SectionHeadingDrawer heading={title} emitOnClose={closeModal} />
        <Grid container>
        <Grid item xs={6} className={[editFormClasses.paddingRight, editFormClasses.boldText].join(' ')}>Previous Value</Grid>
        <Grid item xs={6} className={[editFormClasses.paddingRight, editFormClasses.boldText].join(' ')}>Updated Value</Grid>
                <Grid item xs={6} className={editFormClasses.paddingRight}>
                    <TextValidator
                        label="Building Name"
                        value={data.previousName}
                        name="previousName"
                        fullWidth
                        variant="filled"
                        onChange={() => { }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextValidator
                        label="Building Name"
                        value={data.name}
                        name="name"
                        fullWidth
                        onChange={() => { }}
                        variant="filled"
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>

                <Grid item xs={6} className={editFormClasses.paddingRight}>
                    <TextValidator
                        label="Address"
                        value={data.previousAddress}
                        name="previousAddress"
                        fullWidth
                        variant="filled"
                        onChange={() => { }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextValidator
                        label="Address"
                        value={data.address}
                        name="address"
                        fullWidth
                        onChange={() => { }}
                        variant="filled"
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>

                <Grid item xs={6} className={editFormClasses.paddingRight}>
                    <TextValidator
                        label="City"
                        value={data.previousCity}
                        name="previousCity"
                        onChange={() => { }}
                        variant="filled"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextValidator
                        label="City"
                        value={data.city}
                        name="city"
                        onChange={() => { }}
                        variant="filled"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>

                <Grid item xs={6} className={editFormClasses.paddingRight}>
                    <TextValidator
                        label="Eircode"
                        value={data.previousEircode}
                        name="previousEircode"
                        variant="filled"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextValidator
                        label="Eircode"
                        value={data.eircode}
                        name="eircode"
                        onChange={() => { }}
                        variant="filled"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>

                <Grid item xs={6} className={editFormClasses.paddingRight}>
                    <TextValidator
                        label="Phone Number"
                        value={data.previousPhoneNo}
                        name="previousPhoneNumber"
                        onChange={() => { }}
                        variant="filled"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextValidator
                        label="Phone Number"
                        value={data.phoneNo}
                        name="phoneNumber"
                        onChange={() => { }}
                        variant="filled"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>

                <Grid item xs={6} className={editFormClasses.paddingRight}>
                    <TextValidator
                        label="Response Minutes"
                        value={data.previousResponseMinutes}
                        name="previousResponseMinutes"
                        onChange={() => { }}
                        variant="filled"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextValidator
                        label="Response Minutes"
                        value={data.responseMinutes}
                        name="responseMinutes"
                        onChange={() => { }}
                        variant="filled"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>

                <Grid item xs={6} className={editFormClasses.paddingRight}>
                    <TextValidator
                        label="Building Radius"
                        value={data.previousBuildingRadius}
                        name="previousBuildingRadius"
                        onChange={() => { }}
                        variant="filled"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextValidator
                        label="Building Radius"
                        value={data.buildingRadius}
                        name="buildingRadius"
                        onChange={() => { }}
                        variant="filled"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>

                <Grid item xs={6}>
                            <PrimaryButton
                                type="submit"
                                variant="primary"
                                disabled={isLoading}
                            >
                                <Icon>check</Icon> <>Approve Changes</>
                            </PrimaryButton>
                        </Grid>
                        <Grid item xs={6}>
                            <PrimaryButton
                                type="button"
                                variant="danger"
                                disabled={isLoading}
                                onClick={closeModal}
                            >
                                <Icon>remove</Icon> Cancel
                            </PrimaryButton>
                        </Grid>
            </Grid>
        </ValidatorForm>
        </Drawer >
    );
}
