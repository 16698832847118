import { Drawer, FormControlLabel, Grid, Icon, MenuItem, Switch } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import SunEditor from 'suneditor-react';
import { IDropdownData } from '../../_common/interface';
import { IBuildingManage } from '../../_common/models/building/buildingManage.model';
import { IBuildingUpdateManage } from '../../_common/models/buildingUpdate/buildingUpdateManage.model';
import { IEscalationPolicyUpdateManage } from '../../_common/models/escalationPolicyUpdate/escalationPolicyUpdate.model';
import { IZoneUpdateManage } from '../../_common/models/zoneUpdate/zoneUpdateManage.model';
import { PrimaryButton } from '../../_components/buttons/primary.button';
import { SectionHeadingDrawer } from '../../_components/drawer/sectionHeading.drawer';
import { toastr } from '../../_components/toastr/toastr';
import { dropdownService } from '../../_services/dropdown.service';
import { escalationPolicyUpdateService } from '../../_services/escalationPolicyUpdate.service';
import { zoneUpdateService } from '../../_services/zoneUpdate.service';
import { useDrawerStyles } from '../../_styles';
import { useEditFormStyles } from '../../_styles/editForm-styles';
import { EscalationPolicyUpdateEdit } from './escalationPolicyUpdate-edit';

export function EscalationPolicyUpdateManageDrawer(params: {
    escalationPolicyUpdateId: number,
    isOpen: boolean,
    emitOnClose: () => void
}) {
    const [isLoading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(params.isOpen);
    const drawerClasses = useDrawerStyles();

    useEffect(() => {
        if (params.isOpen !== isOpen) {
            setIsOpen(params.isOpen);
        }
    }, [params.isOpen]);

    function closeModal() {
        params.emitOnClose();
    }

    function onError(event: any) {
        toastr.error("Please verify that all fields have correct input");
    }

    return (
        <Drawer
            anchor={"right"}
            open={isOpen}
            className={drawerClasses.root}
        >
            <SectionHeadingDrawer heading={`Escalation Policy Update`} emitOnClose={params.emitOnClose} />
            <EscalationPolicyUpdateEdit
            escalationPolicyUpdateId={params.escalationPolicyUpdateId}
            emitOnSave={closeModal}
            emitOnClose={closeModal}
    />
        </Drawer >
    );
}
