import { Grid } from '@material-ui/core';
import { EscalationAlertList } from './components/escalationAlertsList.dashboard';
import { UsersDashboardList } from './components/usersList.dashboard';


export function DashboardIndex() {


    return (<Grid container>

        <Grid item xs={4}>
            <UsersDashboardList />
        </Grid>


        <Grid item xs={8}>
            <EscalationAlertList />
        </Grid>

    </Grid>
    );
}