import * as React from 'react';
import { IMultipleAutocomplete } from '../../_common/interface/autocomplete/multipleAutoComplete.interface';
import { dropdownService } from '../../_services/dropdown.service';
import { StandardMultipleAutoComplete } from './standard.multipleAutoComplete';

interface IClientBuildingMultipleAutoComplete extends IMultipleAutocomplete {
}

export const ClientBuildingMultipleAutoComplete = (params: IClientBuildingMultipleAutoComplete) => {

    function fetchItems() {
        return dropdownService
            .getBuildings(params.parentId);
    }

    return (
        <StandardMultipleAutoComplete
            {...params}
            itemsLoader={fetchItems}
            label={"Buildings"} />
    );
}