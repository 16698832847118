import { getHeader } from '../_helpers';
import { handleResponse } from '../_helpers/handle-response';
import { API_URL } from '../_common/const';

const API_NAME = "userLogin";

export const userLoginService = {
    ForceLogoutUser
};

function ForceLogoutUser(userId: string) {
    const requestOptions = { method: 'PUT', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/ForceLogoutUser/?userId=${userId}`, requestOptions).then(handleResponse);
}
