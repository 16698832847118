export const formatedTimeStringFromSeconds = (seconds: number) => {
    let _totalMinutes = Math.floor(seconds / 60);
    let _hours = 0;
    let _minutes = 0;
    if(_totalMinutes > 59) {
        _hours = Math.floor(_totalMinutes / 60);
        _minutes = _totalMinutes % 60;
    } else {
        _minutes = _totalMinutes;
    }
    
    let _seconds = seconds % 60;
    let hoursString = _hours > 0 ? `${_hours.toString()}:` : "";
    let minuteString = _minutes < 0 ? "00" : _minutes < 10 ? `0${_minutes.toString()}` : _minutes.toString();
    let secondString = _seconds < 0 ? "00" : _seconds < 10 ? `0${_seconds.toString()}` : _seconds.toString();
    return hoursString + minuteString + ':' + secondString;
}


export function getCurrentTime(date?: Date): number {
    return Math.floor((date ? new Date(date) : new Date()).getTime() / 1000);
}