import { makeStyles } from '@material-ui/core/styles';
import { Container, CssBaseline, Paper } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { WARNING_COLOR } from '../../_common/const';
import { PaperHeader } from '../../_components/paperComponents/header.paperComponent';
import { AuthRoute } from '../../_components/routing/auth.routing';
import { CommonTable } from '../../_components/table/commonTable';
import { toastr } from '../../_components/toastr/toastr';
import { getTableOptions, historyHelper } from '../../_helpers';
import { userService } from '../../_services';
import { TOOLBAR_STORE } from '../../_stores/toolbar.store';
import { AccountEdit } from './account-edit';
import { AccountCreate } from './account-create';

interface ITableData {
    items: Array<any>;
    count: number;
}

interface IFilteringViewModel {
    search: string;
    limit: number;
    page: number;
    order?: 'asc' | 'desc';
    orderBy: string;
    [key: string]: any;
}

enum FilterEnum {
    search = 'search',
    limit = 'limit',
    page = 'page',
    order = 'order',
    orderBy = 'orderBy'
}

const useStyles = makeStyles(theme => ({
    pageHeader: {
        borderBottom: "1px solid #eee"
    }
}));

export const AccountIndex = observer(() => {

    const [data, setData] = useState<ITableData>({ items: [], count: 0 });
    const [refreshCount, increaseRefresh] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [filter, setFilter] = useState<IFilteringViewModel>({ search: '', limit: 100, page: 0, order: 'desc', orderBy: '' });
    const classes = useStyles();

    const columns = [
        { title: 'Email', field: 'email' },
        { title: 'Name', field: 'name' },
        { title: 'Created Date', field: 'createdDate' },
        { title: 'Status', field: 'isActive', render: (rowData: any) => <>{rowData.isActive ? "Active" : "Inactive"}</> },
        { title: 'Update Requested', field: 'isUpdateApprovalRequired', render: (rowData: any) => <>{rowData.isUpdateApprovalRequired ? "Yes" : "No"}</> },
    ];

    useEffect(() => {
        fetchData();

        TOOLBAR_STORE.setShowClientDropdown(true);

        return () => {
            TOOLBAR_STORE.setShowClientDropdown(false);
        };

    }, [filter, TOOLBAR_STORE.clientId]);

    function fetchData() {

        setLoading(true);
        return userService.getAll(filter, TOOLBAR_STORE.clientId)
            .then(data => {
                data.items = data.items || [];
                setData(data);
            },
                (e) => {
                    toastr.error("Error getting user data");
                })
            .finally(() => setLoading(false));
    }

    function setProp(propName: FilterEnum, propValue: any) {
        const query = filter;
        query[propName.toString()] = propValue;
        setFilter({ ...query });
        fetchData();
    }

    function handleRequestSort(property: string) {
        const query = filter;
        query.orderBy = property;
        let order: 'asc' | 'desc' = 'desc';

        if (filter.orderBy === property && filter.order === 'desc') {
            order = 'asc';
        }

        query.order = order;

        setFilter({ ...query });
        fetchData();
    }

    function createUser() {
        historyHelper.push("/Accounts/Create")
    }

    function updateUser(accountId: any) {
        historyHelper.push(`/Accounts/Edit/${accountId}`)
    }

    function createUserPostBack() {
        toastr.success("User created successfully");
        fetchData();
    }

    function updateUserPostBack() {
        toastr.success("User updated successfully");
        fetchData();
    }

    return (
        <>

            <Container component="main">
                <Paper>
                    <CssBaseline />


                    <div className={classes.pageHeader}>
                        <PaperHeader title='Users' icon='account_box'></PaperHeader>
                    </div>
                    <CommonTable
                        title={null}
                        columns={columns}
                        data={data}
                        isLoading={isLoading}
                        setFilter={setFilter}
                        onRowClick={(e: any, data: any) => updateUser(data.id)}
                        options={{
                            ...getTableOptions(filter.limit),
                            rowStyle: (rowData: any) => {
                                if (rowData.isUpdateApprovalRequired) {
                                    return {
                                        backgroundColor: WARNING_COLOR[3],
                                        borderBottom: '2px solid white',
                                    };
                                }
                            },
                        }}
                        actions={[
                            {
                                icon: 'add',
                                tooltip: 'Add User',
                                isFreeAction: true,
                                onClick: createUser
                            }
                        ]}
                    />

                </Paper>

                <AuthRoute exact path="/Accounts/Create" successCallback={createUserPostBack} component={AccountCreate} />
                <AuthRoute esxact path="/Accounts/Edit/:accountId" successCallback={updateUserPostBack} component={AccountEdit} />

            </Container>
        </>
    );
})